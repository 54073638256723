import { Component, Input } from '@angular/core';
import { EbButtonSize, EbButtonType } from 'libs/ui-buttons/src/lib/button/button.component';
import { UrlHelper } from '../../../helpers/url.helper';
import { SocialMediaBrand } from '../../../rest-api/models/artist-page.interface';

@Component({
    selector: 'eb-social-button',
    templateUrl: './social-button.component.html',
    styleUrls: ['./social-button.component.less'],
})
export class SocialButtonComponent {
    @Input({ required: true }) url!: string;
    @Input({ required: true }) brand: SocialMediaBrand = 1;
    @Input() ebSize: EbButtonSize = 'default';
    @Input() ebType: EbButtonType = 'secondary';

    readonly labels = {
        [SocialMediaBrand.FACEBOOK]: 'facebook',
        [SocialMediaBrand.INSTAGRAM]: 'instagram',
        [SocialMediaBrand.LINKEDIN]: 'linkedin',
        [SocialMediaBrand.OTHER]: 'link',
        [SocialMediaBrand.TIK_TOK]: 'tik tok',
        [SocialMediaBrand.SPOTIFY]: 'spotify',
        [SocialMediaBrand.TWITTER]: 'twitter',
        [SocialMediaBrand.YOUTUBE]: 'youtube',
        [SocialMediaBrand.WWW]: 'www',
    };

    protected openLink(): void {
        UrlHelper.openExternalUrl(this.url, '_blank');
    }
}
