import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TooltipElementComponent } from './tooltip-element/tooltip-element.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
    imports: [CommonModule, OverlayModule, SvgIconComponent],
    declarations: [TooltipDirective, TooltipElementComponent],
    exports: [TooltipDirective],
})
export class UiTooltipModule {}
