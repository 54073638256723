<div class="eb-double-cards-details__header flex align-items-center py-5" [ngClass]="{ 'eb-double-cards-dialog__header--border flex-row-reverse' : isDesktopDialog }">
  @if (isDesktopDialog) {
    <svg-icon class="cursor-pointer ml-auto text-4xl mr-6 close-icon" key="close" (click)="closeDialog()" />
  }
  <p class="ml-6 h3">{{ doubleCardsData[type].title | transloco }}</p>
</div>
<div class="eb-double-cards-details__content" [ngClass]="isDesktopDialog ? 'p-6' : 'px-6 py-2'">
  <div class="flex gap-3 justify-content-center align-items-center">
    <svg-icon class="type-icon" [key]="type" />
    <p class="mb-0" [ngClass]="{ 'eb-size--big' : isDesktopDialog }">{{ doubleCardsData[type].subtitle | transloco }}</p>
  </div>
  <div class="my-5 line-height-3" [ngClass]="{'text-sm' : !isDesktopDialog }">
    {{ doubleCardsData[type].description | transloco }}
  </div>
  <a [href]="doubleCardsData[type].link" eb-button ebType="text" class="fw-bold eb-double-cards__content-more cursor-pointer w-max "> {{ doubleCardsData[type].linkDescription | transloco }} <svg-icon key="chevron-right" size="md"/></a>
  <div class="mt-5 text-xs" [innerHTML]="'shared.delayedPaymentsDialog.termsAndConditions' | transloco"></div>
</div>
