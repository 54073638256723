import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateString } from 'apps/portal/src/app/rest-api/models/date-string.type';
import { differenceInMinutes } from 'date-fns';

@Component({
    selector: 'eb-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'inline-flex align-items-center gap-2' },
})
export class TimerComponent implements OnChanges {
    @Input({ required: true }) endDate!: DateString;
    hoursLeft?: number;
    minutesLeft?: number;
    daysLeft?: number;
    readonly minutesInHour = 60;
    readonly minutesInDay = this.minutesInHour * 24;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.endDate.currentValue) {
            const endDate = new Date(changes.endDate.currentValue);
            let diffInMinutes = differenceInMinutes(endDate, new Date());

            if (diffInMinutes <= 0) {
                this.daysLeft = 0;
                this.hoursLeft = 0;
                this.minutesLeft = 0;
                return;
            }

            this.daysLeft = Math.floor(diffInMinutes / this.minutesInDay);
            diffInMinutes = diffInMinutes % this.minutesInDay;

            this.hoursLeft = Math.floor(diffInMinutes / this.minutesInHour);
            this.minutesLeft = diffInMinutes % this.minutesInHour;
        }
    }
}
