import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ITitlePageGetRequest } from '../rest-api/models/title-page-get-request.model';
import { TitlePage } from '../rest-api/models/title-page.model';
import { TitlePageRestService } from '../rest-api/title-page-rest.service';
import { BaseStoreService } from '../stores/base-store.service';

@Injectable({
    providedIn: 'root',
})
export class TitlePageStoreService extends BaseStoreService<ITitlePageGetRequest, TitlePage> {
    private readonly _titlePageRestService = inject(TitlePageRestService);

    protected _shouldLoad(newRequest: ITitlePageGetRequest): boolean {
        if (this._loadingStream$.value) {
            return false;
        }

        if (!this._request) {
            return true;
        }

        return (
            this._request.category !== newRequest.category ||
            this._request.subcategory !== newRequest.subcategory ||
            this._request.title !== newRequest.title ||
            this._request.artist !== newRequest.artist ||
            this._request.city !== newRequest.city ||
            this._request.partner !== newRequest.partner ||
            this._request.eid !== newRequest.eid ||
            this._request.language !== newRequest.language
        );
    }

    protected _getRest(): (request: ITitlePageGetRequest | null) => Observable<Readonly<TitlePage>> {
        return (request: ITitlePageGetRequest | null) => {
            request = request as ITitlePageGetRequest;
            return this._titlePageRestService.get(request);
        };
    }

    get() {
        this.load(this._request as ITitlePageGetRequest);
        return super.get();
    }
}
