import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOUBLE_CARDS_DATA, DoubleCardsTypes, DoubleCardsWindowTypes } from '../double-cards.model';

@Component({
    selector: 'eb-double-cards-details',
    templateUrl: './double-cards-details.component.html',
    styleUrls: ['./double-cards-details.component.less'],
})
export class EbDoubleCardsDetailsComponent {
    isDesktopDialog = false;
    readonly doubleCardsData = DOUBLE_CARDS_DATA;
    readonly doubleCardsTypes = DoubleCardsTypes;
    private _type: DoubleCardsTypes | null = null;

    @Input({ required: true }) public set type(type: DoubleCardsTypes | null) {
        this._type = type;
    }

    public get type(): DoubleCardsTypes {
        return this._type ?? DoubleCardsTypes.PAYPO;
    }

    @Input({ required: true }) public set windowType(windowType: DoubleCardsWindowTypes | null) {
        this.isDesktopDialog = windowType === DoubleCardsWindowTypes.DIALOG;
    }

    @Output() readonly closeDialogEvent = new EventEmitter<void>();

    protected closeDialog(): void {
        if (this.closeDialogEvent.observed) {
            this.closeDialogEvent.emit();
        }
    }
}
