import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbProgressBarComponent } from './progress-bar/progress-bar.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EbProgressBarComponent],
    exports: [EbProgressBarComponent],
})
export class UiProgressBarModule {}
