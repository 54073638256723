import { Component, Input } from '@angular/core';

@Component({
    selector: 'eb-ticket-panel',
    templateUrl: './ticket-panel.component.html',
    styleUrls: ['./ticket-panel.component.less'],
})
export class TicketPanelComponent {
    @Input() noPriceInfo = false;
}
