<div class="flex">
  <svg *ngIf="!imageUrl" width="110" height="50" viewBox="0 0 91 38" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470.96 201.21">
    <g id="Warstwa_1-2">
      <path d="m455.62,187.72c4.86-.02,9.5-2.12,12.4-6.08,2.01-2.74,2.95-6.2,2.95-9.57s-.94-6.82-2.95-9.57c-2.9-3.96-7.54-6.06-12.4-6.08-4.86.01-9.5,2.11-12.4,6.08-2,2.74-2.95,6.2-2.95,9.57s.95,6.83,2.95,9.57c2.91,3.96,7.54,6.06,12.4,6.08m-7.49-19.24c1.12-3.36,3.79-6.05,7.49-6.07,3.69.02,6.37,2.71,7.49,6.07.36,1.07.6,2.38.6,3.59s-.24,2.52-.6,3.59c-1.13,3.36-3.8,6.05-7.49,6.07-3.7-.02-6.37-2.7-7.49-6.07-.36-1.07-.6-2.38-.6-3.59s.24-2.52.6-3.59" fill="#eb5d1e"/>
      <path d="m344.18,187.84h5.15c.49,0,.89-.4.89-.89v-42.33c0-.49-.4-.89-.89-.89h-5.15c-.49,0-.89.4-.89.89v42.33c0,.49.4.89.89.89" fill="#eb5d1e"/>
      <path d="m332.08,187.84h5.15c.48,0,.89-.4.89-.89v-42.33c0-.49-.4-.89-.89-.89h-5.15c-.49,0-.89.4-.89.89v42.33c0,.49.4.89.89.89" fill="#eb5d1e"/>
      <path d="m422.45,187.84h5.15c.5,0,.9-.4.9-.89v-17.28c0-5.39,3.58-7.97,9.4-6.77.7.15,1.23-.22,1.22-1.02v-3.62c0-.92-.09-1.29-.77-1.48h0c-.95-.25-3.01-.35-3.57-.34-3.75,0-7.11,1.51-9.42,3.8-1.69,1.69-3.79,4.35-3.79,8.97v17.74c0,.49.4.89.89.89" fill="#eb5d1e"/>
      <path d="m401.72,187.72c3.18-.01,6.27-.92,8.83-2.65v3.79c0,5.14-4.5,6.07-7.37,6.25-1.93.12-4.67-.14-5.91-.33-.78-.11-1.34.02-1.34.84v4.56c-.01.34.26.7.81.75,6.67.57,11.17.45,14.55-1.47,2.41-1.37,4.05-3.62,4.92-6.26.63-1.9.85-4.23.85-6.26v-14.85c0-3.41-.91-6.77-2.95-9.57-2.9-3.96-7.54-6.06-12.4-6.08-4.86.01-9.49,2.11-12.4,6.08-3.97,5.42-3.97,13.7,0,19.13,2.91,3.96,7.54,6.06,12.4,6.08m-7.49-19.23c1.12-3.36,3.79-6.07,7.49-6.07,8.28,0,8.56,8.45,8.56,9.66s-.28,9.66-8.56,9.66c-3.69,0-6.37-2.7-7.49-6.07-.36-1.07-.6-2.38-.6-3.59s.24-2.52.6-3.59" fill="#eb5d1e"/>
      <path d="m369.55,187.72c4.94.19,8.25-.67,10.72-2.1.49-.28.6-.62.6-1.51v-4c0-.82-.49-1.19-1.25-.7-2.67,1.7-5.88,2.74-9.97,2.42-4.12-.32-7.52-3.21-7.84-7.29h20.34c.45,0,.81-.34.83-.83.34-5.57-1.26-17.27-13.78-17.27-9.16,0-13.11,6.15-14.45,11.34-.35,1.38-.53,2.87-.53,4.29,0,3.36.94,6.82,2.95,9.57,2.9,3.96,7.55,5.89,12.4,6.08m-.37-25.29c3,0,6.52,1.72,6.52,6.6h-13.85c0-2.28,1.9-6.6,7.33-6.6" fill="#eb5d1e"/>
      <path d="m302.45,185.55c1.97,1.55,4.66,2.29,7.99,2.29h15.16c.49,0,.89-.41.89-.89v-8.77s-.01-9.19-.01-9.19c0-4.09-.91-7.23-2.87-9.33-2.04-2.18-5.92-3.21-9.57-3.21s-7.39.94-10.49,2.79c-.2.12-.32.34-.32.57v.04l.28,4.51c.05.61.76.81,1.31.46,4.49-2.79,9.88-3.31,12.61-1.94,1.92.97,2.43,3.01,2.43,4.32v.66h-7.51c-3.22,0-13.09,1.14-13.09,10.27v.15c0,3.12,1.07,5.63,3.19,7.28m10.3-12.2h7.1v8.91h-8.97c-1.18-.08-2.22-.38-3.18-1.11-1.02-.79-1.49-1.73-1.5-2.94v-.03c.03-1.62,1.12-4.83,6.54-4.83" fill="#eb5d1e"/>
      <path d="m66.18,93.64l23.12,2.17c-7.12,19.56-24.3,31.02-47.02,31.02C16.2,126.83,0,112.21,0,88.51c0-15.21,5.13-28.05,15.41-38.52,10.27-10.47,22.92-15.61,37.93-15.61,24.69,0,39.12,14.81,39.12,38.52,0,5.33-.4,9.29-1.78,13.83H27.26c-.2,11.85,6.12,18.57,17.78,18.57,10.27,0,17.38-3.75,21.14-11.65m-36.35-21.93h35.76c.59-10.27-4.94-16.59-14.62-16.59-10.27,0-17.78,6.12-21.14,16.59" fill="currentColor"/>
      <path d="m193.19,113.79c-10.08,7.11-23.11,10.67-39.51,10.67h-62.03L122.66,0h56.9c24.7,0,38.13,10.08,38.13,29.04,0,16.4-12.05,29.04-29.24,31.61,12.44,2.37,19.95,11.66,19.95,24.3s-5.14,21.73-15.21,28.84m-30.42-40.89h-29.24l-6.91,27.46h27.85c15.01,0,23.71-5.93,23.71-16.79,0-7.11-5.14-10.67-15.41-10.67m-16.99-48.99l-6.72,26.47h24.7c15.21,0,23.11-5.14,23.11-15.41,0-7.11-4.94-11.06-14.23-11.06h-26.87Z" fill="currentColor"/>
      <path d="m232.86,36.75h28.64l-21.93,87.71h-28.45l21.73-87.71Zm2.37-10.67L241.95,0h29.04l-6.72,26.08h-29.04Z" fill="currentColor"/>
      <polygon points="285.93 0 314.38 0 283.37 124.46 254.92 124.46 285.93 0"/>
      <path d="m369.87,93.64l23.11,2.17c-7.11,19.56-24.3,31.02-47.02,31.02-26.08,0-42.28-14.62-42.28-38.32,0-15.21,5.14-28.05,15.41-38.52,10.27-10.47,22.92-15.61,37.93-15.61,24.69,0,39.12,14.81,39.12,38.52,0,5.33-.4,9.29-1.78,13.83h-63.41c-.2,11.85,6.12,18.57,17.78,18.57,10.27,0,17.39-3.75,21.14-11.65m-36.35-21.93h35.76c.59-10.27-4.94-16.59-14.62-16.59-10.27,0-17.78,6.12-21.14,16.59" fill="currentColor"/>
      <path d="m404.38,96.8l10.08-40.1h-12.05l4.35-17.38,6.32-.99c7.9-1.38,12.05-4.54,16.99-14.23l5.73-11.65h17.78l-5.93,24.3h23.31l-5.14,19.96h-23.11l-9.29,36.74c-1.78,7.31.4,10.87,7.51,10.87,5.73,0,10.07-.79,15.01-2.96l-4.74,18.17c-9.48,5.53-16.4,7.31-25.68,7.31-17.19,0-25.88-11.86-21.14-30.03" fill="currentColor"/>
      <path d="m251.29,165.37c2.89,0,5.17.98,6.77,2.99,1.6,1.97,2.4,4.8,2.4,8.4s-.8,6.43-2.43,8.46c-1.63,2-3.88,3.02-6.74,3.02-1.45,0-2.74-.28-3.94-.8-1.2-.52-2.19-1.36-2.98-2.46h-.25l-.71,2.83h-2.4v-31.37h3.36v7.63c0,1.69-.06,3.23-.15,4.59h.15c1.57-2.22,3.88-3.29,6.93-3.29m-.49,2.8c-2.28,0-3.94.65-4.93,1.97-1.02,1.32-1.51,3.51-1.51,6.62s.52,5.33,1.54,6.65c1.05,1.32,2.71,2,4.99,2,2.06,0,3.6-.74,4.59-2.25,1.02-1.51,1.51-3.66,1.51-6.46s-.49-5.02-1.51-6.43c-1.02-1.42-2.55-2.13-4.68-2.13v.03Z" fill="currentColor"/>
      <path d="m262.8,165.71h3.6l4.83,12.59c1.05,2.86,1.72,4.95,1.97,6.22h.15c.19-.68.56-1.85,1.11-3.51.55-1.66,2.37-6.77,5.48-15.3h3.6l-9.48,25.15c-.96,2.49-2.03,4.25-3.3,5.29-1.26,1.05-2.8,1.57-4.62,1.57-1.02,0-2.03-.12-3.01-.34v-2.68c.74.15,1.57.25,2.49.25,2.31,0,3.94-1.3,4.92-3.88l1.23-3.14-8.9-22.22h-.09Z" fill="currentColor"/></g></svg>

  <img *ngIf="imageUrl" [src]="imageUrl | media" alt="logo" class="logo-image">
</div>
