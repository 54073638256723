<div class="title-card">
  <div class="title-card__img-frame eb-img-frame eb-img-frame--with-desc eb-skeleton" [ngClass]="{ 'title-card__img-frame--small': cardSize === 'small' }">
  </div>
  <div class="title-card__desc">
    <p class="h6 eb-skeleton title-card__title "></p>
    <p class="title-card__date eb-skeleton eb-size--tiny mb-0"></p>
    <p class="title-card__place eb-skeleton eb-size--tiny"></p>
    <p class="title-card__timer eb-skeleton" *ngIf="showTimer"></p>
  </div>
</div>
