import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeRestService } from '../rest-api/home-rest.service';
import { IDontMissTile } from '../rest-api/models/dont-miss-tile.interface';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class DontMissStoreService extends BaseStoreService<null, IDontMissTile[]> {
    private readonly _homeRestService = inject(HomeRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<IDontMissTile[]> {
        return () => this._homeRestService.getDontMiss();
    }

    public load(request: null): Observable<IDontMissTile[]> {
        return super.load(request);
    }

    public get(): Observable<IDontMissTile[]> {
        this.load(this._request);
        return super.get();
    }
}
