import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { EbAccordionPanelComponent } from './accordion-panel/accordion-panel.component';

@NgModule({
    imports: [CommonModule, SvgIconComponent],
    declarations: [EbAccordionPanelComponent],
    exports: [EbAccordionPanelComponent],
})
export class UiAccordionModule {}
