<ng-container *ngIf="root">
  <div class="eb-tree-select-option" (click)="goBack(root)">
    <svg-icon class="mr-2" key="chevron-left" />{{root.label}}
  </div>
  <div class="eb-tree-option__divider mx-3"></div>
  <div class="eb-tree-select-option" (click)="select(root)">
    <span>{{'libs.tree-select.allFrom' | transloco}} {{root.label}}{{allFromCategory}} </span>
    <svg-icon *ngIf="value === root" class="eb-tree-select-list__clear" key="close" (click)="clear()" />
  </div>
  <div class="eb-tree-option__divider mx-3"></div>
</ng-container>
<div class="eb-tree-select-option" *ngFor="let item of availableOptions" (click)="clicked(item)">
  <span>{{item.label}}</span>
  <svg-icon *ngIf="value === item" class="eb-tree-select-list__clear" key="close" (click)="clear()" />
</div>
