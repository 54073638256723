import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ISelectOptionGroup } from '@e-bilet/ui-select';
import { ITreeOption } from '@e-bilet/ui-tree-select';
import { EbButtonColor } from 'libs/ui-buttons/src/lib/button/button.component';
import { EbDateRangePickerComponent } from 'libs/ui-date-picker/src/lib/date-range-picker/date-range-picker.component';
import { EbInputTheme } from 'libs/ui-form-item/src/lib/form-item/form-item.component';
import { filter } from 'rxjs/operators';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { Province } from '../../../rest-api/models/province.model';
import { ISearchPageQueryParams } from '../../../search-page/search-page-query-params.interface';
import { SearchService } from '../../../services/search.service';
import { LocalizationTreeStoreService } from '../../../stores/localization-tree-store.service';
import { CalendarHelper } from '../calendar/calendar.helper';
import { EbLocationTreeSelectComponent } from '../location-tree-select/location-tree-select.component';
import { ISearchInputValue } from '../search-input/search-input-value.interface';
import { SearchOptionGroup } from '../search-select/search-select-value.interface';
import { EbSearchSelectComponent } from '../search-select/search-select.component';

@Component({
    selector: 'eb-quick-search-input-group',
    templateUrl: './quick-search-input-group.component.html',
    styleUrls: ['./quick-search-input-group.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'block lg:w-full' },
})
export class EbQuickSearchInputGroupComponent implements OnInit {
    private readonly _elementRef = inject(ElementRef);
    private readonly _formBuilder = inject(FormBuilder);
    private readonly _localizationTreeStoreService = inject(LocalizationTreeStoreService);
    private readonly _searchService = inject(SearchService);
    private readonly _router = inject(Router);
    private readonly _deviceService = inject(DeviceService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private _destroyRef = inject(DestroyRef);

    private _locationTree: ITreeOption<Province>[] = [];

    options: ISelectOptionGroup<SearchOptionGroup, any>[] = [];
    loading = false;
    searchForm: FormGroup;
    isCompact = false;
    overlayOrigin!: CdkOverlayOrigin;

    protected get locationTree(): ITreeOption<Province>[] {
        return this._locationTree;
    }

    protected get elementRef(): ElementRef {
        return this._elementRef;
    }

    @Input() theme: EbInputTheme = 'dark';
    @Input() ebColor: EbButtonColor = null;
    @Input() autoFocus = false;

    @Output() readonly searching = new EventEmitter<ISearchInputValue>();

    @ViewChild(EbSearchSelectComponent, { static: true }) readonly ebSearchSelectComponent!: EbSearchSelectComponent;
    @ViewChild(EbDateRangePickerComponent, { static: true }) readonly ebDateRangePicker!: EbDateRangePickerComponent;
    @ViewChild(EbLocationTreeSelectComponent, { static: true })
    readonly ebLocationTreeSelect!: EbLocationTreeSelectComponent;

    @HostListener('keydown', ['$event']) protected handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === 'Tab') {
            event.preventDefault();
            this._openNextInput();
        }
    }

    constructor() {
        this.searchForm = this._formBuilder.group({
            dateRange: [],
            location: [],
        });

        this.searchForm.valueChanges
            .pipe(
                filter(() => this.isCompact),
                takeUntilDestroyed(),
            )
            .subscribe(() => this._goToSearchPage(this._mapFormValueToSearchRequest()));

        this.overlayOrigin = new CdkOverlayOrigin(this._elementRef);
        this._localizationTreeStoreService
            .get()
            .pipe(takeUntilDestroyed())
            .subscribe((locationTree) => (this._locationTree = locationTree));
    }

    public ngOnInit(): void {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            this.isCompact = isMobile;
            this._changeDetectorRef.markForCheck();
        });
    }

    protected submit(): void {
        if (!this.ebSearchSelectComponent.preventParentSubmit) {
            this._goToSearchPage(this._mapToSearchPageQueryParams());
        }
    }

    public focus(): void {
        this.ebSearchSelectComponent.focus();
    }

    private _openNextInput(): void {
        if (this.ebSearchSelectComponent.ebSelectComponent.isOpen) {
            this.ebDateRangePicker.invokeClick();
        } else if (this.ebDateRangePicker.isOpen) {
            this.ebLocationTreeSelect.ebTreeSelectComponent.invokeClick();
        } else {
            this.ebSearchSelectComponent.ebSelectComponent.invokeClick();
            this.ebSearchSelectComponent.selectOptionFocusCounter = null;
        }
    }

    private _goToSearchPage(queryParams: ISearchPageQueryParams | null): void {
        this._router.navigate(['./wydarzenia'], { queryParams });
    }

    private _mapFormValueToSearchRequest(): ISearchPageQueryParams {
        const { dateRange, location } = this.searchForm.value;

        const request: ISearchPageQueryParams = {};

        if (dateRange) {
            if (Array.isArray(dateRange)) {
                request.dateFrom = CalendarHelper.getStartOfDay(dateRange[0]);
                request.dateTo = CalendarHelper.getEndOfDay(dateRange[1]);
            } else {
                request.date = dateRange;
            }
        }

        if (location) {
            if (location.parent) {
                request.province = location.parent.value.slug;
                request.city = location.value.slug;
            } else {
                request.province = location.value.slug;
            }
        }

        return request;
    }

    private _mapToSearchPageQueryParams(): ISearchPageQueryParams | null {
        return this._searchService.mapToSearchPageQueryParams(this.ebSearchSelectComponent.lastRequest, true);
    }
}
