<div class="title-dont-miss-button lg:fixed lg:w-auto lg:top-50 lg:left-0" [ngClass]="isMobile ? 'eb-affix eb-affix--fixed' : ''" *ngIf="isInited">
  <button class="uppercase w-full" eb-button ebType="primary" (click)="openDialog()"><svg-icon *ngIf="isMobile" class="rotate-90" [key]="'arrow-left'" /> {{"shared.title-dont-miss.dont-miss" | transloco}} <svg-icon *ngIf="!isMobile" class="-rotate-90" key="arrow-left" /></button>
</div>
<div class="title-dont-miss-overlay fixed left-0 w-screen h-screen flex align-items-end" *ngIf="isOpen">
  <div class="title-dont-miss-content" [ngClass]="{'title-dont-miss-content--opened': isOpen}">
    <div class="title-dont-miss-content--header flex justify-content-between">
      <h4>{{"shared.title-dont-miss.dont-miss" | transloco}}</h4>
      <svg-icon key="close" class="cursor-pointer" (click)="closeDialog()" />
    </div>
    <div class="title-dont-miss-content--body lg:flex justify-content-center flex-row-reverse mx-auto my-0">
      <eb-carousel *ngIf="isMobile" [fullWidthMobile]="false">
        <eb-title-card
        *ngFor="let title of titles; let i = index"
        [title]="title"
        ebIntersection
        [ebOnlyFirst]="true"
        (intersecting)="aggregatedGa4EventsService.titleCardIntersecting(ga4ListNames.DontMiss, title, i, ga4ListNames.DontMiss)"
        [cardSize]="'small'"
        [listName]="ga4ListNames.DontMiss"
        [promotionName]="ga4ListNames.DontMiss"
        [index]="i"
        />
      </eb-carousel>
      <div class="title-dont-miss-content--content scroll-hide" ebScrollbar [y]="true" *ngIf="!isMobile">
        <eb-title-card
        *ngFor="let title of titles; let i = index"
        [title]="title"
        ebIntersection
        [ebOnlyFirst]="true"
        (intersecting)="aggregatedGa4EventsService.titleCardIntersecting(ga4ListNames.DontMiss, title, i, ga4ListNames.DontMiss)"
        [cardSize]="'small'"
        [listName]="ga4ListNames.DontMiss"
        [promotionName]="ga4ListNames.DontMiss"
        [index]="i"
        />
        <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'wydarzenia']"><button eb-button ebType="secondary">{{"shared.title-dont-miss.showMore" | transloco}} <svg-icon key="chevron-down" /></button></a>
      </div>
      <a *ngIf="isMobile" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'wydarzenia']" class="all-link"><button eb-button>{{"shared.title-dont-miss.showAll" | transloco}} <svg-icon key="chevron-right" /></button></a>
    </div>
  </div>
</div>
