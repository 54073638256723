import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseVirtualPageView } from '../../../../../../../libs/gtm/src/lib/virtual-page-view';
import {
    TitleEventAvailabilities,
    TitleEventAvailabilityData,
} from '../../../rest-api/models/title-event-availability.models';
import { TitleEvent } from '../../../rest-api/models/title-page.model';
import { ShopQueue, ShopQueueService } from '../../../services/shop-queue.service';
import { TitleEventAvailabilityStoreService } from '../../../stores/title-event-availability-store.service';
import { TitleEventMicrodataMapper } from '../title-event-list/title-event-microdata.mapper';

@Component({
    selector: 'eb-title-event-time-slot',
    templateUrl: './title-event-time-slot.component.html',
    styleUrls: ['./title-event-time-slot.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbTitleEventTimeSlotComponent implements OnInit, OnDestroy {
    private readonly _titleEventAvailabilityStoreService = inject(TitleEventAvailabilityStoreService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _shopQueueService = inject(ShopQueueService);
    private readonly _platformId = inject(PLATFORM_ID);
    private _destroyRef = inject(DestroyRef);

    @Input({ required: true }) titleEvent!: TitleEvent;
    @Input() addMicroData = false;
    @Input() virtualPageView: BaseVirtualPageView | undefined;
    @Input() partnerId?: string;
    @Input() tokenCheckSum: string | undefined;
    @Input() microDescription: string | undefined;
    @Input() artists: string[] = [];
    @Input() titleUrl: string | undefined;

    showQueue = false;
    queueTimeout: number | undefined;
    eventJson: Record<string, any> | null = null;
    titleEventAvailability: TitleEventAvailabilities = TitleEventAvailabilities.UNDEFINED;
    minPrice: number | undefined;

    protected get disabled(): boolean {
        return this.titleEvent?.soldOut || this.titleEventAvailability !== TitleEventAvailabilities.AVAILABLE;
    }

    protected get lastPlaces(): boolean {
        return this.titleEvent?.lastPlaces;
    }

    public ngOnInit(): void {
        if (this.addMicroData) {
            this.eventJson = TitleEventMicrodataMapper.mapToJsonLd(
                this.titleEvent,
                this.microDescription,
                this.artists,
                this.titleUrl,
            );
        }

        this._shopQueueService.setQueue$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((queue: ShopQueue) => {
            this.showQueue = queue.eventId === this.titleEvent.id;
            this._changeDetectorRef.markForCheck();
        });

        if (isPlatformBrowser(this._platformId)) {
            this._shopQueueService.redirect$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((id: string) => {
                if (id === this.titleEvent.id) {
                    setTimeout(() => {
                        this.showQueue = false;
                        this._changeDetectorRef.markForCheck();
                    }, 1000);
                }
            });
        }
    }

    public ngOnDestroy(): void {
        clearTimeout(this.queueTimeout);
    }

    protected checkAvailability(): void {
        if (this.titleEvent) {
            this._titleEventAvailabilityStoreService
                .find$(this.titleEvent.id)
                .subscribe((availabilityData: TitleEventAvailabilityData) => {
                    this.titleEventAvailability = availabilityData.titleEventAvailability;
                    this.minPrice = availabilityData.minPrice;
                    this._changeDetectorRef.markForCheck();
                });
            this._titleEventAvailabilityStoreService
                .load({
                    partnerId: this.partnerId,
                    events: [this.titleEvent],
                    cacheLong: !!this.titleEvent.webShopMaxDelay,
                })
                .pipe(takeUntilDestroyed(this._destroyRef))
                ?.subscribe();
        }
    }

    protected queueRedirectToShop(): void {
        if (this.disabled) {
            return;
        }

        this._shopQueueService.queueRedirectToShop(
            this.titleEvent,
            this.partnerId,
            this.tokenCheckSum,
            false,
            this.virtualPageView,
        );
    }
}
