import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiScrollbarModule } from '@e-bilet/ui-scrollbar';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { EbCarouselItemDirective } from './carousel-item.directive';
import { EbCarouselComponent } from './carousel/carousel.component';

@NgModule({
    imports: [CommonModule, UiButtonsModule, UiScrollbarModule, SvgIconComponent],
    declarations: [EbCarouselComponent, EbCarouselItemDirective],
    exports: [EbCarouselComponent, EbCarouselItemDirective],
})
export class UiCarouselModule {}
