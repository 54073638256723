import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Article } from './models/article.interface';

@Injectable({
    providedIn: 'root',
})
export class CreativeContentRestService {
    private _baseUrl = '/CreativeContent';

    constructor(private _httpClient: HttpClient) {}

    public getArticlesForHomePage(): Observable<Article[]> {
        return this._httpClient.get<Article[]>(`${environment.portalApi}${this._baseUrl}/GetArticlesForSG`);
    }

    public getArticlesForArtistPage(artistSlug: string): Observable<Article[]> {
        let params = new HttpParams();
        params = params.append('artistSlug', artistSlug);
        return this._httpClient.get<Article[]>(`${environment.portalApi}${this._baseUrl}/GetArticlesForArtist`, {
            params,
        });
    }

    public getArticlesForTitlePage(title: string, category: string, subcategory: string): Observable<Article[]> {
        let params = new HttpParams();
        params = params.append('title', title);
        params = params.append('category', category);
        params = params.append('subcategory', subcategory);

        return this._httpClient.get<Article[]>(`${environment.portalApi}${this._baseUrl}/GetArticlesForTitle`, {
            params,
        });
    }

    public getArticlesForCategoryPage(categorySlug: string): Observable<Article[]> {
        let params = new HttpParams();
        params = params.append('categorySlug', categorySlug);
        return this._httpClient.get<Article[]>(`${environment.portalApi}${this._baseUrl}/GetArticlesForCategory`, {
            params,
        });
    }
}
