import { isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Ga4ListNames } from '@e-bilet/gtm';
import { AggregatedGa4EventsService } from 'libs/gtm/src/lib/aggregated-ga4-events.service';
import { timer } from 'rxjs';
import { delayWhen, tap } from 'rxjs/operators';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { Title } from '../../../rest-api/models/title.model';
import { InternationalizationService } from '../../../services/internationalization.service';
import { TitlesDontMissStoreService } from '../../../stores/titles-dont-miss-store.service';

@Component({
    selector: 'eb-title-dont-miss',
    templateUrl: './title-dont-miss.component.html',
    styleUrls: ['./title-dont-miss.component.less'],
})
export class TitleDontMissComponent {
    private readonly _deviceService = inject(DeviceService);
    private readonly _titlesDontMissStoreService = inject(TitlesDontMissStoreService);
    private readonly _platformId = inject(PLATFORM_ID);
    public readonly i18nService = inject(InternationalizationService);
    public readonly aggregatedGa4EventsService = inject(AggregatedGa4EventsService);

    isInited = false;
    isOpen = false;
    titles: Title[] = [];
    isMobile = true;
    readonly ga4ListNames = Ga4ListNames;

    constructor() {
        if (isPlatformBrowser(this._platformId)) {
            this._deviceService.isMobile$
                .pipe(takeUntilDestroyed())
                .subscribe((isMobile: boolean) => (this.isMobile = isMobile));

            this._titlesDontMissStoreService
                .get()
                .pipe(
                    tap((res) => (this.titles = res)),
                    delayWhen(() => timer(5000)),
                    takeUntilDestroyed(),
                )
                .subscribe((res) => {
                    this.isInited = true;
                    if (!this.isMobile) {
                        this.openDialog();
                    }
                });
        }
    }

    protected openDialog(): void {
        this.isOpen = true;
    }

    protected closeDialog(): void {
        this.isOpen = false;
    }
}
