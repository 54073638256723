import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { UiButtonsModule } from '../../../ui-buttons/src/lib/ui-buttons.module';
import { UiFormItemModule } from '../../../ui-form-item/src/lib/ui-form-item.module';
import { UiTooltipModule } from '../../../ui-tooltip/src/lib/ui-tooltip.module';
import { FanAlertComponent } from './fan-alert/fan-alert.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiTooltipModule,
        UiButtonsModule,
        UiFormItemModule,
        TranslocoModule,
        SvgIconComponent,
    ],
    declarations: [FanAlertComponent],
    exports: [FanAlertComponent],
})
export class UiFanAlertModule {}
