import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, PLATFORM_ID, inject } from '@angular/core';

@Component({
    selector: 'eb-scroll-button',
    templateUrl: './scroll-button.component.html',
    styleUrls: ['./scroll-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollButtonComponent {
    private _platformId = inject(PLATFORM_ID);

    isScrolledDown = false;
    isDisabled = false;
    readonly breakpointPosition = 150;

    @HostListener('window:scroll', [])
    protected onScroll(): void {
        this.isScrolledDown = window.scrollY > this.breakpointPosition;
    }

    @HostListener('window:scrollend', [])
    protected onScrollEnd(): void {
        this.isDisabled = false;
    }

    protected scrollToTop(): void {
        this.isDisabled = true;
        const element = document.getElementById('header');
        if (isPlatformBrowser(this._platformId) && element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
