<button eb-button ebType="secondary" [ebGhost]="true" (click)="setNextListView()">
  <ng-container [ngSwitch]="next">
    <ng-container *ngSwitchCase="ListViewTypes.CARD">
      <svg-icon key="grid" /> <span *ngIf="showLabels">{{ 'libs.list-view-toggle-button.tileView' | transloco }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="ListViewTypes.BAR">
      <svg-icon key="list" /> <span *ngIf="showLabels">{{ 'libs.list-view-toggle-button.listView' | transloco }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="ListViewTypes.CALENDAR">
      <svg-icon key="calendar-month" /> <span *ngIf="showLabels">{{ 'libs.list-view-toggle-button.calendarView' | transloco }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="ListViewTypes.DAY">
      <svg-icon key="calendar" /> <span *ngIf="showLabels">{{ 'libs.list-view-toggle-button.dayView' | transloco }}</span>
    </ng-container>
  </ng-container>
</button>
