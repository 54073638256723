import { HttpParams } from '@angular/common/http';

export class ParamsHelper {
    static preparePageParams(size?: number, top?: number, params?: HttpParams): HttpParams {
        if (!params) {
            params = new HttpParams();
        }

        if (size) {
            params = params.append('size', size);
        }

        if (top) {
            params = params.append('top', top);
        }

        return params;
    }
}
