import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
    Component,
    HostListener,
    OnDestroy,
    PLATFORM_ID,
    QueryList,
    ViewChild,
    ViewChildren,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import { Ga4ListNames, GtmService, GtmVariableNames } from '@e-bilet/gtm';
import { SyneriseService } from '@e-bilet/synerise';
import { IBreadcrumb } from '@e-bilet/ui-breadcrumbs';
import { ListViewTypes } from '@e-bilet/ui-list-view-toggle-button';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { CookieName, CookiesService } from 'libs/cookies-policy/src/lib/cookies.service';
import { AggregatedGa4EventsService } from 'libs/gtm/src/lib/aggregated-ga4-events.service';
import { EbButtonSize } from 'libs/ui-buttons/src/lib/button/button.component';
import { EbCarouselComponent } from 'libs/ui-carousel/src/lib/carousel/carousel.component';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CookieConsent, CookiesPolicyService } from '../../../../../libs/cookies-policy/src/lib/cookies-policy.service';
import { DeviceService } from '../../../../../libs/device/src/lib/device.service';
import { TitleVirtualPageView } from '../../../../../libs/gtm/src/lib/virtual-page-view';
import { nameToId } from '../../consts/name-to-id';
import { UTM_SHARE_LINK_URL_QUERY_PARAMS, UtmContentType } from '../../consts/utm-params';
import { environment } from '../../environments/environment';
import { UrlHelper } from '../helpers/url.helper';
import { TitleEventsPresentationTypeMapper } from '../mappers/title-events-presentation-type.mapper';
import { CreativeContentRestService } from '../rest-api/creative-content-rest.service';
import { Article } from '../rest-api/models/article.interface';
import { IDontMissTile } from '../rest-api/models/dont-miss-tile.interface';
import { FanAlertTypes, IFanAlertParams } from '../rest-api/models/fan-alert-request.model';
import {
    BrandingCookieModel,
    ICarouselBrandingData,
    Place,
    TitleEvent,
    TitleEventGroup,
    TitleEventsPresentationTypes,
    TitlePage,
    TitlePageEvents,
} from '../rest-api/models/title-page.model';
import { Title } from '../rest-api/models/title.model';
import { TitlePageRestService } from '../rest-api/title-page-rest.service';
import { BackgroundService } from '../services/background.service';
import { BrandingService } from '../services/branding.service';
import { InternationalizationService } from '../services/internationalization.service';
import { SeoService } from '../services/seo.service';
import { ShopQueueService } from '../services/shop-queue.service';
import { ITdProduct, TdPageTypeEnum, TradedoublerService } from '../services/tradedoubler.service';
import { DoubleCardsTypes } from '../shared/components/double-cards/double-cards.model';
import { EbGalleryComponent } from '../shared/components/gallery/gallery.component';
import { CampaignTypes, Poi } from '../shared/components/map/map.component';
import { IShareData } from '../shared/components/share-button/share-data.interface';
import { TitleEventMicrodataMapper } from '../shared/components/title-event-list/title-event-microdata.mapper';
import { DontMissStoreService } from '../stores/dont-miss-store.service';
import { TitleEventAvailabilityStoreService } from '../stores/title-event-availability-store.service';
import { EVENTS_WITHOUT_BUFFER } from '../../consts/events-without-buffer';
import { TitlePageStoreService } from './title-page-store.service';
import { ITitlePageQueryParams, ITitlePageRouteParams } from './title-page.guard';
import { TitlePageMapper } from './title-page.mapper';

const POLAND_COORDINATES = {
    lat: 52.069167,
    lng: 19.480556,
};

const TRADEDOUBLER = 'tradedoubler';

export const BRANDING_LOCAL_STORAGE_PREFIX = 'title-branding-';

@Component({
    selector: 'eb-title-page',
    templateUrl: './title-page.component.html',
    styleUrls: ['./title-page.component.less'],
})
export class TitlePageComponent implements OnDestroy {
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _seoService = inject(SeoService);
    private readonly _titlePageRestService = inject(TitlePageRestService);
    private readonly _titlePageStoreService = inject(TitlePageStoreService);
    private readonly _titleEventAvailabilityStoreService = inject(TitleEventAvailabilityStoreService);
    private readonly _brandingService = inject(BrandingService);
    private readonly _syneriseService = inject(SyneriseService);
    private readonly _gtmService = inject(GtmService);
    private readonly _cookiesService = inject(CookiesService);
    private readonly _dontMissStoreService = inject(DontMissStoreService);
    private readonly _translocoService = inject(TranslocoService);
    private readonly _deviceService = inject(DeviceService);
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);
    private readonly _shopQueueService = inject(ShopQueueService);
    private readonly _tradedoublerService = inject(TradedoublerService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly scope = inject(TRANSLOCO_SCOPE);
    private _creativeContentRestService = inject(CreativeContentRestService);
    public readonly i18nService = inject(InternationalizationService);
    public readonly activatedRoute = inject(ActivatedRoute);
    public readonly aggregatedGa4EventsService = inject(AggregatedGa4EventsService);

    readonly polandCoordinates = {
        lat: 52.069167,
        lng: 19.480556,
    };

    readonly tradedoubler = 'tradedoubler';
    readonly listViewTypes = ListViewTypes;
    readonly ga4ListNames = Ga4ListNames;
    readonly campaignTypes = CampaignTypes;
    readonly doubleCardsTypes = DoubleCardsTypes;
    // eslint-disable-next-line max-len
    readonly titlesWithEnglishDescriptionHack = ['127647', '144233', '38728', '10672']; // USYK prod, WHOOP UCI prod, Silesia Memoriał Kamili Skolimowskiej prod, Drift Masters prod
    protected readonly blogUrl: string = environment.blogUrl;
    protected articles: Article[] = [];

    titlePage: TitlePage | undefined;
    titleEventsGroup: TitleEventGroup[] = [];
    shareData!: IShareData;
    breadcrumbs: IBreadcrumb[] = [];
    listViewType = ListViewTypes.BAR;
    fanAlertParams!: IFanAlertParams;

    ListViewType = ListViewTypes;
    proposedTitles: Title[] = [];
    otherTitlesByOrganizer: Title[] = [];
    buttonSize: EbButtonSize = 'default';
    todayDateKey = '';
    tomorrowDateKey = '';
    isLoading = true;
    isArticlesLoading = false;
    isEventsLoading = true;
    dontMissTiles: IDontMissTile[] = [];
    virtualPageView: TitleVirtualPageView | undefined;
    isMobile = false;
    pois: Poi[] = [];
    tokenCheckSum: string | undefined;
    artists: string[] = [];
    titleUrl: string | undefined;
    lat: number | undefined = this.polandCoordinates.lat;
    lng: number | undefined = this.polandCoordinates.lng;
    mapZoom = 4;
    citiesText = '';
    citiesOther = false;
    polishDescription = '';
    englishDescription = '';
    isCookiesConsentAccepted = false;
    showButtonPrice = false;
    showFanAlert = true;
    showDontMissSection = false;
    artistSkeletonVisible = false;
    placeSkeletonVisible = false;
    private _brandingData: ICarouselBrandingData = {};
    private _lastTitleId = '';
    private _viewedTitlesCounter = 0;

    @ViewChild(EbGalleryComponent) readonly galleryElement: EbGalleryComponent | undefined;

    @ViewChildren(EbCarouselComponent) readonly carousels: QueryList<EbCarouselComponent> | undefined;

    protected get hasGallery(): boolean {
        return !!this.titlePage?.gallery?.items?.length;
    }

    protected get hasEvents(): boolean {
        return !!this.titleEventsGroup?.length && !!this.titleEventsGroup[0].events.length;
    }

    protected get hasDetails(): boolean {
        return !!this.titlePage?.properties?.length;
    }

    protected get hasArtists(): boolean {
        return !!this.titlePage?.artists?.length;
    }

    protected get hasPlaces(): boolean {
        return !!this.titlePage?.places?.length;
    }

    private get _queryParams(): Params {
        return this._activatedRoute.snapshot.queryParams;
    }

    @HostListener('window:beforeunload')
    protected onWindowClose(): void {
        if (isPlatformBrowser(this._platformId)) {
            localStorage.removeItem(BRANDING_LOCAL_STORAGE_PREFIX + this.titlePage?.id);
        }
    }

    constructor() {
        inject(BackgroundService).color();

        if (isPlatformBrowser(this._platformId)) {
            this._cookiesPolicyService.cookiesPolicyConsent$
                .pipe(takeUntilDestroyed())
                .subscribe((cookiesConsent: CookieConsent | undefined) => {
                    this.isCookiesConsentAccepted = !!cookiesConsent?.statistics;
                });

            this._dontMissStoreService
                .get()
                .pipe(takeUntilDestroyed())
                .subscribe((res) => {
                    this.dontMissTiles = res;
                });
        }

        const titlePage$ = combineLatest([this._activatedRoute.params, this._activatedRoute.queryParams]).pipe(
            tap(() => (this.isLoading = true)),
            switchMap(([routeParams, queryParams]) => {
                this.tokenCheckSum = queryParams.cs ?? undefined;
                const { artist, city, partner, eid } = queryParams as ITitlePageQueryParams;
                const { category, subcategory, title } = routeParams as ITitlePageRouteParams;
                const language = this._translocoService.getActiveLang();
                if (title !== 'taylor-swift-the-eras-tour-123082024' || partner !== 'alterartpl') {
                    this.showButtonPrice = true;
                } else {
                    this.showFanAlert = false;
                }
                this.virtualPageView = new TitleVirtualPageView(category, subcategory, title);
                this.titleUrl = TitleEventMicrodataMapper.getTitleUrl(category, subcategory, title);
                return this._titlePageStoreService.load({
                    category,
                    subcategory,
                    title,
                    artist,
                    city,
                    partner,
                    eid,
                    language,
                });
            }),
            shareReplay(1),
            takeUntilDestroyed(),
        );

        combineLatest([this._deviceService.isMobile$, titlePage$])
            .pipe(takeUntilDestroyed())
            .subscribe(([isMobile, titlePage]) => {
                this.isMobile = isMobile;
                this.buttonSize = this.isMobile ? 'default' : 'large';
            });

        titlePage$
            .pipe(
                withLatestFrom(this._activatedRoute.params),
                switchMap(([titlePage, params]) =>
                    this._titlePageRestService.getProposed(params.category, params.subcategory, titlePage.id),
                ),
                takeUntilDestroyed(),
            )
            .subscribe((proposedTitles) => {
                this.proposedTitles = proposedTitles;
            });

        titlePage$
            .pipe(
                withLatestFrom(this._activatedRoute.queryParams),
                switchMap(([titlePage, params]: [TitlePage, any]) =>
                    this._titlePageRestService
                        .getEvents(titlePage.id, {
                            artist: params.artist,
                            city: params.city,
                            partner: params.partner,
                            eid: params.eid,
                        } as ITitlePageQueryParams)
                        .pipe(
                            map((titlePageEvents: TitlePageEvents): [TitlePage, any, TitlePageEvents] => [
                                titlePage,
                                params,
                                titlePageEvents,
                            ]),
                        ),
                ),
                tap(([titlePage, params, titlePageEvents]) => {
                    this.isEventsLoading = true;
                    if (isPlatformBrowser(this._platformId)) {
                        const events: TitleEvent[] = [];

                        titlePageEvents.groups.find((group: TitleEventGroup) => {
                            group.events.find((event: TitleEvent) => {
                                events.push(event);
                                return events.length >= EVENTS_WITHOUT_BUFFER;
                            });

                            return events.length >= EVENTS_WITHOUT_BUFFER;
                        });

                        this._titleEventAvailabilityStoreService.loadWithoutBuffer({
                            partnerId: this.titlePage?.branding?.partnerId ?? titlePage?.branding?.partnerId,
                            events,
                        });
                    }
                }),
                takeUntilDestroyed(),
            )
            .subscribe({
                next: ([titlePage, params, titlePageEvents]) => {
                    this.isEventsLoading = false;
                    if (isPlatformBrowser(this._platformId)) {
                        if (!sessionStorage.getItem('DONT_MISS') && titlePageEvents.showDontMiss) {
                            sessionStorage.setItem('DONT_MISS', '1');
                            this.showDontMissSection = true;
                        } else {
                            this.showDontMissSection = false;
                        }
                    }

                    this.titleEventsGroup = titlePageEvents.groups;
                    this._checkTradedoubler();
                },
            });

        titlePage$
            .pipe(
                withLatestFrom(
                    this._activatedRoute.queryParams as Observable<ITitlePageQueryParams>,
                    this._activatedRoute.fragment as Observable<string>,
                ),
                takeUntilDestroyed(),
            )
            .subscribe(([titlePage, queryParams, hashParam]) => {
                this.isLoading = false;
                this.listViewType = TitleEventsPresentationTypeMapper.mapToListViewType(
                    titlePage.eventsDefaultPresentationType || TitleEventsPresentationTypes.SINGLE,
                );
                if (this._viewedTitlesCounter && isPlatformBrowser(this._platformId)) {
                    localStorage.removeItem(BRANDING_LOCAL_STORAGE_PREFIX + this._lastTitleId);
                }
                this._viewedTitlesCounter++;
                this.titlePage = titlePage;

                if (titlePage.title.includes('The Eras Tour')) {
                    this.polishDescription = this.titlePage.extendedDescription;
                    this.englishDescription = this.titlePage.description;
                } else if (this.titlesWithEnglishDescriptionHack.includes(titlePage.id)) {
                    this.polishDescription = titlePage.description;
                    this.englishDescription = titlePage.extendedDescription;
                } else {
                    this.polishDescription = '';
                    this.englishDescription = '';
                }
                this._lastTitleId = titlePage.id;

                this.artists = this.titlePage?.artists?.map((artist) => artist.name);
                const cities: string[] = [];

                this.titlePage?.places?.forEach((place) => {
                    if (!cities.includes(place.city)) {
                        cities.push(place.city);
                    }
                });

                if (cities.length > 3) {
                    this.citiesText = cities.slice(0, 3).join(', ');
                    this.citiesOther = true;
                } else {
                    this.citiesText = cities.join(', ');
                    this.citiesOther = false;
                }

                this._initMap();
                this._resetCarousels();

                this.fanAlertParams = {
                    objectId: this.titlePage.id,
                    name: this.titlePage.title,
                    fanAlertType: FanAlertTypes.TITLE,
                };

                let shareUrl = '';
                if (isPlatformBrowser(this._platformId)) {
                    shareUrl = UrlHelper.addQueryParams(
                        location.toString(),
                        UTM_SHARE_LINK_URL_QUERY_PARAMS,
                        titlePage.slug,
                        UtmContentType.EVENT,
                    );
                    // used localStorage to preserve carousel branding data when title opened in new tab
                    this._brandingData = JSON.parse(
                        localStorage.getItem(BRANDING_LOCAL_STORAGE_PREFIX + this.titlePage?.id) ?? '{}',
                    );
                }

                this.shareData = {
                    title: titlePage.title,
                    url: shareUrl,
                    textTransKey: 'titlePage.shareText',
                };

                this.breadcrumbs = TitlePageMapper.mapToBreadcrumbs(titlePage);
                this._seoService.set(titlePage.seo);

                const paramsOrganizerIds =
                    this._getOrganizerIdsFromHashParams(this.titlePage.organizer?.id, hashParam) ||
                    this._getOrganizerIdsFromQueryParams(this.titlePage.organizer?.id, queryParams);
                this._gtmService.deleteEvents([GtmVariableNames.Organizer, GtmVariableNames.PageModel]);
                this._gtmService.organizer(
                    this._getOrganizerId(
                        this.titlePage.organizer?.id,
                        paramsOrganizerIds,
                        this._cookiesService.getCookieValue(CookieName.GtmOrganizerIds),
                    ),
                );
                this._gtmService.pageModel({ titleId: this.titlePage.id, organizerId: this.titlePage.organizer.id });
                this._gtmService.pageView();
                this._syneriseService.pageView();

                this._gtmService.viewItem(
                    titlePage,
                    this._brandingData.listName,
                    nameToId(this._brandingData.listName),
                );
                this._checkTradedoubler();
            });

        titlePage$
            .pipe(
                switchMap((titlePage) => this._titlePageRestService.getOtherTitlesByOrganizer(titlePage.id)),
                takeUntilDestroyed(),
            )
            .subscribe((otherTitlesByOrganizer) => (this.otherTitlesByOrganizer = otherTitlesByOrganizer));

        this._shopQueueService.setBrandingCookie$.pipe(takeUntilDestroyed()).subscribe((titleEventId) => {
            this._setBrandingCookie(titleEventId);
        });
        this._activatedRoute.params
            .pipe(
                tap(() => (this.isArticlesLoading = true)),
                switchMap((params) =>
                    this._creativeContentRestService.getArticlesForTitlePage(
                        params.title,
                        params.category,
                        params.subcategory,
                    ),
                ),
                takeUntilDestroyed(),
            )
            .subscribe((articles: Article[]) => {
                this.articles = articles;
                this.isArticlesLoading = false;
            });
    }

    private _checkTradedoubler(): void {
        if (isPlatformBrowser(this._platformId)) {
            if (!this.titlePage || !this.titleEventsGroup.length) {
                return;
            }

            if (
                (this._queryParams.tduid || this._cookiesService.getCookieValue(CookieName.Tradedoubler)) &&
                this.titleEventsGroup.find(
                    (titleEventGroup) => !!titleEventGroup.events.find((event) => event.isTradedoublerEnabled),
                )
            ) {
                const tdProductItem: ITdProduct = {
                    id: this.titlePage?.id,
                    name: this.titlePage?.title,
                    qty: 1,
                };
                const tdPrefix =
                    this.titlePage?.branding?.partnerId ??
                    this._cookiesService.getCookieValue(CookieName.TradedoublerPartnerId);

                this._tradedoublerService.activateTradedoubler(TdPageTypeEnum.PRODUCT, [tdProductItem], tdPrefix);
            }
        }
    }

    private _getOrganizerId(
        organizerId: string,
        paramsOrganizerIds: string,
        cookieOrganizerIds: string | undefined,
    ): string | null {
        if (!organizerId) {
            return null;
        }

        const [umbracoOrganizerId, nposOrganizerId] = (paramsOrganizerIds || '').split(',');

        if (umbracoOrganizerId == organizerId) {
            if (paramsOrganizerIds !== cookieOrganizerIds) {
                this._cookiesService.setCookieValue(CookieName.GtmOrganizerIds, paramsOrganizerIds, {
                    path: '/',
                    domain: true,
                    sameSite: 'None',
                    secure: true,
                    partitioned: true,
                });
            }
            return umbracoOrganizerId;
        }

        const [cookieUmbracoOrganizerId, cookieNposOrganizerId] = (cookieOrganizerIds || '').split(',');
        if (cookieUmbracoOrganizerId == organizerId) {
            return cookieUmbracoOrganizerId;
        }

        return null;
    }

    private _getOrganizerIdsFromQueryParams(organizerId: string, queryParams: ITitlePageQueryParams): string {
        if (!organizerId) {
            return '';
        }

        const [queryTitelName, queryOrganizerIds] = (queryParams.utm_campaign || '').split('_');
        const [queryUmbracoOrganizerId, queryNposOrganizerId] = (queryOrganizerIds || '').split(',');

        if (queryUmbracoOrganizerId == organizerId && queryParams.utm_medium && queryParams.utm_source) {
            return queryOrganizerIds;
        }

        return '';
    }

    private _getOrganizerIdsFromHashParams(organizerId: string, hashParams: string): string {
        if (!organizerId) {
            return '';
        }

        const [hashTitelName, hashOrganizerIds] = (hashParams || '').split('_');
        const [hashUmbracoOrganizerId, hashNposOrganizerId] = (hashOrganizerIds || '').split(',');

        if (hashUmbracoOrganizerId == organizerId) {
            return hashOrganizerIds;
        }

        return '';
    }

    private _initMap(): void {
        if (this.titlePage?.places && this.titlePage?.places[0]?.latitude) {
            this.pois = this.titlePage.places.map(
                (place: Place) =>
                    ({
                        lat: place.latitude,
                        lng: place.longitude,
                        desc: `${place.name}, ${place.city}`,
                    }) as Poi,
            );

            if (this.pois.length === 1) {
                this.mapZoom = 13;
                this.lat = this.pois[0].lat;
                this.lng = this.pois[0].lng;
            }
        }
    }

    protected showMap(place: Place): void {
        this.lat = place.latitude;
        this.lng = place.longitude;
        this.mapZoom = 13;
    }

    private _resetCarousels(): void {
        this.carousels?.forEach((carousel) => carousel.reset());
    }

    protected showGallery(): void {
        if (this.galleryElement && this.isMobile) {
            this.galleryElement.overlayGallery?.show();
        }
    }

    protected addToWishlist(): void {
        if (this.titlePage) {
            this._gtmService.addToWishlist(
                this._gtmService.mapTitlePageToGa4Item(
                    this.titlePage,
                    this._brandingData.listName,
                    nameToId(this._brandingData.listName),
                ),
            );
        }
    }

    private _setBrandingCookie(eventId: string): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        const brandingCookieContent: BrandingCookieModel = {
            titleId: this.titlePage?.id,
            eventId,
            affiliantName: this.titlePage?.branding?.isAffiliate ? this.titlePage?.branding?.partnerName : undefined,
            listName: this._brandingData.listName,
            listPosition: this._brandingData.index?.toString(),
            listId: nameToId(this._brandingData.listName),
            promotionName: this._brandingData.promotionName,
            promotionId: this._brandingData.promotionId,
        };

        if (this.isCookiesConsentAccepted) {
            this._cookiesService.setCookieValue(CookieName.Branding, JSON.stringify(brandingCookieContent), {
                path: '/',
                domain: true,
                expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
            });
        }
    }

    public ngOnDestroy(): void {
        if (!this.i18nService.changingLanguage) {
            this._brandingService.setBranding(undefined);
            this._gtmService.deleteEvents([GtmVariableNames.Organizer, GtmVariableNames.PageModel]);
            if (isPlatformBrowser(this._platformId)) {
                localStorage.removeItem(BRANDING_LOCAL_STORAGE_PREFIX + this.titlePage?.id);
            }
        }
    }
}
