export interface IFanAlertRequest extends IFanAlertParams {
    partnerId?: string;
    email: string;
}

export interface IFanAlertParams {
    partnerId?: string;
    name: string | undefined;
    objectId: string;
    fanAlertType: FanAlertTypes;
}

export enum FanAlertTypes {
    UNKNOWN,
    TITLE,
    ARTIST,
    PLACE,
    ORGANIZER,
}
