import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffixComponent } from './affix/affix.component';
import { SectionIntersectDirective } from './section-intersect.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [AffixComponent, SectionIntersectDirective],
    exports: [AffixComponent, SectionIntersectDirective],
})
export class UiAffixModule {}
