import { IFAQItem } from '../rest-api/models/faq.model';

export interface FaqJson {
    '@context': 'https://schema.org';
    '@type': 'FAQPage';
    mainEntity: FaqItemJson[];
}

export interface FaqItemJson {
    '@type': 'Question';
    name: string;
    acceptedAnswer: {
        '@type': 'Answer';
        text: string;
    };
}

export class FaqJsonHelper {
    static getJson(items?: IFAQItem[]): FaqJson {
        return {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: this.mapFaqItemsToJson(items),
        };
    }

    static mapFaqItemsToJson(items?: IFAQItem[]): FaqItemJson[] {
        return (items ?? []).map((faqItem: IFAQItem) => ({
            '@type': 'Question',
            name: faqItem.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: faqItem.answer,
            },
        }));
    }
}
