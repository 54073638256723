import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';

@Component({
    selector: 'eb-accordion-panel',
    templateUrl: './accordion-panel.component.html',
    styleUrls: ['./accordion-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EbAccordionPanelComponent implements OnDestroy {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private _isActive = false;
    private _height: number | null = null;
    private _mutationObserverObserver!: MutationObserver;
    contentRendered = false;

    protected get height(): number | null {
        return this._height;
    }

    @Input({ required: true }) header: TemplateRef<void> | null = null;
    @Input() content: TemplateRef<void> | null = null;
    @HostBinding('class.eb-accordion-panel') protected get isPanel(): boolean {
        return true;
    }

    @HostBinding('class.eb-accordion-panel--active') protected get isActive(): boolean {
        return this._isActive;
    }

    @ViewChild('body', { static: true }) private readonly _bodyElementRef!: ElementRef<HTMLDivElement>;

    public ngOnDestroy(): void {
        this._destroyMutationObserver();
    }

    protected toggle(): void {
        this._isActive ? this._close() : this._open();
    }

    protected _open(): void {
        if (!this._isActive) {
            if (this.content) {
                this.contentRendered = true;
            }
            this._isActive = true;
            this._height = this._bodyElementRef.nativeElement.scrollHeight;
            this._createMutationObserver();
        }
    }

    protected _close(): void {
        if (this._isActive) {
            this._isActive = false;
            this._height = null;
            this._changeDetectorRef.markForCheck();
            this._destroyMutationObserver();
        }
    }

    private _createMutationObserver(): void {
        this._mutationObserverObserver = new MutationObserver(() => this._updateHeight());
        this._mutationObserverObserver.observe(this._bodyElementRef.nativeElement, { childList: true, subtree: true });
    }

    private _destroyMutationObserver(): void {
        if (this._mutationObserverObserver) {
            this._mutationObserverObserver.disconnect();
        }
    }

    private _updateHeight(): void {
        if (this._isActive) {
            this._height = this._bodyElementRef.nativeElement.scrollHeight;
            this._changeDetectorRef.markForCheck();
        }
    }
}
