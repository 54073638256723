import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'eb-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbLogoComponent {
    @Input() imageUrl: string | undefined;
}
