import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    HostListener,
    inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieName, CookiesService } from 'libs/cookies-policy/src/lib/cookies.service';
import { EbDropdownTriggerForDirective } from 'libs/ui-dropdown/src/lib/dropdown-trigger-for.directive';
import { catchError, of, retry, Subject, switchMap } from 'rxjs';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { EbTopbarComponent } from '../../../../../../../libs/ui-topbars/src/lib/topbar/topbar.component';
import { environment } from '../../../../environments/environment';
import { DateHelper } from '../../../helpers/date.helper';
import { UrlHelper } from '../../../helpers/url.helper';
import { BasketRestService } from '../../../rest-api/basket-rest.service';
import { IBasket } from '../../../rest-api/models/basket.model';
import { Category } from '../../../rest-api/models/category.model';
import { IFrame, IFrameTypeEnum } from '../../../rest-api/models/iframe';
import { IBranding } from '../../../rest-api/models/title-page.model';
import { BrandingService } from '../../../services/branding.service';
import { IframeService } from '../../../services/iframe.service';
import { InternationalizationService } from '../../../services/internationalization.service';
import { CategoryStoreService } from '../../../stores/category-store.service';
import { EbBottomNavBarComponent } from '../bottom-nav-bar/bottom-nav-bar.component';

@Component({
    selector: 'eb-portal-master-page',
    templateUrl: './portal-master-page.component.html',
    styleUrls: ['./portal-master-page.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class EbPortalMasterPageComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _document = inject(DOCUMENT);
    private readonly _brandingService = inject(BrandingService);
    private readonly _router = inject(Router);
    private readonly _categoryStoreService = inject(CategoryStoreService);
    private readonly _basketRestService = inject(BasketRestService);
    private readonly _i18nService = inject(InternationalizationService);
    private readonly _deviceService = inject(DeviceService);
    private readonly _changeDetectionRef = inject(ChangeDetectorRef);
    private readonly _iframeService = inject(IframeService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _cookiesService = inject(CookiesService);
    private _destroyRef = inject(DestroyRef);
    public readonly internationalizationService = inject(InternationalizationService);
    public readonly activatedRoute = inject(ActivatedRoute);

    private _resizeObserver: ResizeObserver | undefined;
    isQuickSearchVisible = true;
    isSimpleSearchVisible = false;
    categories: Category[] = [];
    menuVisible = false;
    searchVisible = false;
    branding: IBranding | null = null;
    iframe: IFrame | undefined;
    basket: IBasket | null = null;
    basketExpirationDate: Date | null = null;
    isBasketDrawerVisible = false;
    isBasketButtonVisible = false;
    readonly iframeTypeEnum = IFrameTypeEnum;
    readonly homeUrl = environment.siteUrl;
    readonly blogUrl = environment.blogUrl;
    readonly collaborationUrl = 'https://www.ebilet.pl/now/wspolpraca/';
    readonly languageDropdownWidth = '240px';
    readonly basketDropdownWidth = '360px';
    readonly amountOfEventForDirectRedirect = 1;
    readonly redirectToBasketParam = '?mode=btb'; // żeby bezpośrednio przekierować do koszyka w sklepie

    @ViewChild('megaMenuOverlay') readonly megaMenuOverlayDiv!: ElementRef<HTMLDivElement>;
    @ViewChild('page') readonly page!: ElementRef<HTMLDivElement>;
    @ViewChild('topbarEl') readonly topbarEl!: EbTopbarComponent;
    @ViewChild('bottomNavBar') readonly bottomNavBar!: EbBottomNavBarComponent;
    @ViewChild(EbDropdownTriggerForDirective) readonly languageDropdownTrigger: EbDropdownTriggerForDirective | undefined;

    @ViewChild(EbDropdownTriggerForDirective) readonly basketDropdownTrigger: EbDropdownTriggerForDirective | undefined;

    @HostListener('document:visibilitychange', ['$event'])
    onVisibilityChange() {
        if (document.visibilityState === 'visible') {
            this._checkBasketExpirationDateCookie();
        }
    }

    basketButtonClick$ = new Subject<void>();

    isMobile = false;
    protected showGooglePriceStatement = signal(false);

    constructor() {
        this.isQuickSearchVisible = !this._activatedRoute.snapshot.data.isQuickSearchHidden;
        this.isSimpleSearchVisible = this._activatedRoute.snapshot.data.isSimpleSearchVisible;

        const queryParams = this._activatedRoute.snapshot.queryParams;
        if ('gclid' in queryParams && 'gad_source' in queryParams) {
            this.showGooglePriceStatement.set(true);
        }
    }

    ngOnInit(): void {
        this._checkBasketExpirationDateCookie();
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            if (isMobile !== this.isMobile) {
                this.isMobile = isMobile;
                this._changeDetectionRef.markForCheck();
            }
        });

        this._iframeService.iframe$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((iframe: IFrame | undefined) => {
                this.iframe = iframe;
            });

        this._brandingService.branding$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((branding: IBranding | null) => {
                this.branding = branding;
            });

        this._categoryStoreService
            .load()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((categories) => {
                this.categories = categories;
            });
        this._router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._checkBasketExpirationDateCookie();
                this.menuVisible = false;
                this.topbarEl.isActive = this.menuVisible;
                this._document.removeEventListener('click', this._onDocumentClickForMegaMenu);
            }
        });

        if (this.internationalizationService.changingLanguage) {
            this.internationalizationService.changingLanguage = false;
        }

        this.basketButtonClick$
            .pipe(
                switchMap(() => this._basketRestService.getActiveBasketData(this._getSessionId())),
                retry(3),
                catchError((err) => of(err)),
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe((basket: IBasket) => {
                this.basket = basket;
                this.basket.eventsCount = this.basket.activeBasketEventsData.length;
                if (basket.eventsCount === this.amountOfEventForDirectRedirect) {
                    this.redirectToShop(basket.activeBasketEventsData[0].eventId);
                } else if (basket.eventsCount > this.amountOfEventForDirectRedirect) {
                    if (this.isMobile) {
                        this.isBasketDrawerVisible = true;
                    } else {
                        this.basketDropdownTrigger?.toggleDropdown();
                    }
                }
            });
    }

    public ngAfterViewInit(): void {
        this._initResizeObserver();
    }

    public ngOnDestroy(): void {
        this._resizeObserver?.disconnect();
    }

    protected toggleMegaMenu = (): void => {
        if (this.menuVisible) {
            this.menuVisible = false;
            this._document.removeEventListener('click', this._onDocumentClickForMegaMenu);
        } else {
            this.menuVisible = true;
            if (isPlatformBrowser(this._platformId)) {
                setTimeout(() => {
                    this._document.addEventListener('click', this._onDocumentClickForMegaMenu);
                });
            } else {
                this._document.addEventListener('click', this._onDocumentClickForMegaMenu);
            }
        }

        this.topbarEl.isActive = this.menuVisible;
    };

    private _onDocumentClickForMegaMenu = (event: MouseEvent): void => {
        const target = (event as any).target;
        const isClickInsideTopBar = this.topbarEl.elementRef.nativeElement
            .querySelector('.eb-topbar__content')
            ?.contains(target);
        const isClickInsideMenu = this.megaMenuOverlayDiv.nativeElement.contains(target);
        if (!(isClickInsideMenu || isClickInsideTopBar)) {
            this.toggleMegaMenu();
        }
    };

    protected redirect(event: MouseEvent): void {
        event.preventDefault();

        if (this.branding?.headerImageLink) {
            window.location.href = this.branding?.headerImageLink;
        } else if (this._router.url === '/') {
            this._router.navigate(['kontakt'], { skipLocationChange: true }).then(() => {
                this._router.navigate(['/']);
            });
        } else if (this._router.url.split('/')[1] === 'en') {
            this._router.navigate(['kontakt'], { skipLocationChange: true }).then(() => {
                this._router.navigate(['/en']);
            });
        } else {
            this._router.navigate(['/']);
        }
    }

    protected goToLoginPage(): void {
        window.location.href = UrlHelper.getLoginUrl(this.internationalizationService.isEnglishActive);
    }

    protected setLanguage(languageId: string): void {
        if (languageId !== this.internationalizationService.activeLanguage) {
            this.internationalizationService.changingLanguage = true;
            this.internationalizationService.setLanguage(languageId, true);
            this.languageDropdownTrigger?.toggleDropdown();
            this._changeCookiebotDialogLanguage(languageId);
        }
    }

    protected handleTimerEnd(): void {
        this._resetBasketParameters();
    }

    protected redirectToShop(eventId: string): void {
        if (isPlatformBrowser(this._platformId)) {
            const internationalizationRoute = this._i18nService.isEnglishActive ? 'en/' : '';
            window.location.href = `${environment.shopUrl}/${internationalizationRoute}${eventId}${this.redirectToBasketParam}`;
        }
    }

    protected handleBasketClick(): void {
        this.basketDropdownTrigger?.toggleDropdown();
        this.basketButtonClick$.next();
    }

    private _initResizeObserver(): void {
        if (isPlatformBrowser(this._platformId) && this.iframe) {
            this._resizeObserver = new ResizeObserver((entries) => {
                this._iframeService.sendHeightPostMessage(entries[0].target.clientHeight + 50);
            });

            this._resizeObserver.observe(this.page.nativeElement);
        }
    }

    private _changeCookiebotDialogLanguage(languageId: string): void {
        if (isPlatformBrowser(this._platformId)) {
            const cookiebotScript = document.getElementById('Cookiebot');
            cookiebotScript?.setAttribute('data-culture', languageId.toUpperCase());
        }
    }

    private _checkBasketExpirationDateCookie(): void {
        if (isPlatformBrowser(this._platformId)) {
            if (this._cookiesService.getCookieValue(CookieName.BasketExpiration) !== 'null') {
                this.basketExpirationDate = DateHelper.parseFromTimeStampStringToDate(
                    this._cookiesService.getCookieValue(CookieName.BasketExpiration),
                );
                this._setBasketButtonVisibility();
            } else {
                this._resetBasketParameters();
            }
        }
    }

    private _resetBasketParameters(): void {
        this.basketExpirationDate = null;
        this.basket = null;
        this.isBasketButtonVisible = false;
        this._changeDetectionRef.detectChanges();
    }

    private _setBasketButtonVisibility(): void {
        if (
            this.basketExpirationDate &&
            this.basketExpirationDate.getTime() > new Date().getTime() &&
            this._getSessionId() !== ''
        ) {
            this.isBasketButtonVisible = true;
        } else {
            this.isBasketButtonVisible = false;
        }
    }

    private _getSessionId(): string {
        if (isPlatformBrowser(this._platformId)) {
            const session = this._cookiesService.getCookieValue(CookieName.Session);
            return session ? JSON.parse(session).Values[Object.keys(JSON.parse(session).Values)[0]] : '';
        } else {
            return '';
        }
    }

    protected closeBasketDrawer(): void {
        this.isBasketDrawerVisible = false;
    }
}
