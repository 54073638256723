<eb-select
  [icon]="showIcon ? 'search' : undefined"
  [label]="'shared.search-select.search' | transloco"
  [size]="size"
  [theme]="theme"
  [overlayOrigin]="overlayOrigin"
  [overlayWidth]="overlayOrigin?.nativeElement.offsetWidth"
  [loading]="loading"
  [serverFilter]="true"
  [customValue]="true"
  [showSuffixIcon]="showSuffixIcon"
  (searching)="emitSearching($event)"
  (clearInput)="clear()"
  [placeholder]="'shared.search-select.placeholder' | transloco"
>
  <div class="p-3">
    <ng-container *ngIf="options.length; then searchResultTmpl; else proposedResultTmpl" />
  </div>

  <ng-template #searchResultTmpl>
    <ng-container *ngFor="let group of options">
      <ng-container
        *ngTemplateOutlet="
          optionGroupTmpl;
          context: { $implicit: group.items, title: group.label, total: group.total, itemTmpl: getTemplate(group.type) }
        "
      />
    </ng-container>
  </ng-template>

  <ng-template #proposedResultTmpl>
    <p class="option-group__name mb-3">{{ 'shared.search-select.featuredEvents' | transloco }}</p>
    <ng-container *ngFor="let item of proposedResult?.titleSearch; let i = index">
      <eb-select-option [focused]="i === selectOptionFocusCounter" [option]="{ label: '', value: item, translationKey: '' }">
        <div>
          <ng-container *ngTemplateOutlet="titleOptionTemplate; context: { $implicit: item, i: i, isProposedResult: true }" />
        </div>
      </eb-select-option>
    </ng-container>
    <button eb-button *ngIf="(proposedResult?.titleSearch?.length || 0) > 3" ebType="secondary" ebSize="small" (click)="selectGroup()">
      {{ 'shared.search-select.showAll' | transloco }}
    </button>
  </ng-template>

  <ng-template #optionGroupTmpl let-title="title" let-total="total" let-itemTmpl="itemTmpl" let-items>
    <p class="option-group__name mb-3">{{ title | transloco }} ({{ total || 0 }})</p>
    <ng-container *ngFor="let item of items; let i = index">
      <eb-select-option [option]="item">
        <div>
          <ng-container
            *ngTemplateOutlet="
              itemTmpl;
              context: {
                $implicit: item,
                i: i,
                minPrice: titleEventAvailabilityDataMap.get(item.id)?.minPrice,
                isPromoted: titleEventAvailabilityDataMap.get(item.id)?.isPromoted
              }
            "
          />
        </div>
      </eb-select-option>
    </ng-container>
    <button eb-button *ngIf="total > 3" ebType="secondary" ebSize="small" (click)="selectGroup()">
      {{ 'shared.search-select.showAll' | transloco }}
    </button>

    <div class="eb-divider option-group__divider"></div>
  </ng-template>
</eb-select>

<ng-template #titleOptionTemplate let-title let-i="i" let-isProposedResult="isProposedResult">
  <div
    ebIntersection
    [ebOnlyFirst]="true"
    (intersecting)="aggregatedGa4EventsService.titleSearchItemIntersecting(title, isProposedResult, i)"
    class="search-option"
    (click)="selectTitleOption($event, title, i)"
  >
    <div class="search-option__thumbnail">
      <img [src]="title.imageLandscape | media" [alt]="title.title" />
    </div>
    <div class="ml-3">
      <span class="search-option__title">{{ title.title }}</span>
      <span class="search-option__description eb-size--small line-height-3">
        <ng-container *ngIf="title.isPromotion">
          <span class="eb-promotion-label">{{ 'promotion.promotion' | transloco }}</span>
          <span class="eb-text-divider"></span>
        </ng-container>
        <ng-container *ngIf="title.dateFrom && !title.isEventDateHidden">
          <span class="white-space-nowrap">{{ title.dateFrom | ebDate: title.dateTo }}</span>
          <span class="eb-text-divider"></span>
        </ng-container>
        <span *ngIf="title.place">{{ title.place }}</span>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #eventOptionTemplate let-event let-i="i" let-minPrice="minPrice" let-isPromoted="isPromoted">
  <div
    ebIntersection
    [ebOnlyFirst]="true"
    (intersecting)="aggregatedGa4EventsService.eventSearchItemIntersecting(event)"
    class="search-option"
    (click)="handleSearchClick($event, event, i, event)"
  >
    <div class="search-option__thumbnail">
      <img [src]="event.imageLandscape | media" [alt]="event.title" />
    </div>
    <div class="ml-3" ebIntersection [ebOnlyFirst]="true" (intersecting)="checkAvailability(event)">
      <span class="search-option__title">{{ event.title }}</span>
      <span class="search-option__description eb-size--small">
        <span *ngIf="!isPromoted && (minPrice | ebIsNumber)"
          >{{ 'shared.search-select.from' | transloco }} {{ minPrice | ebCurrency: 'PLN' }}</span
        >
        <span *ngIf="isPromoted" class="eb-promotion-label">{{ 'promotion.promotion' | transloco }}</span>
        <span *ngIf="(minPrice | ebIsNumber) || isPromoted" class="eb-text-divider"></span>
        <span *ngIf="!event.isEventDateHidden">{{ event.dateEvent | ebDate }}</span>
        <ng-container *ngIf="event.address.city">
          <span *ngIf="!event.isEventDateHidden" class="eb-text-divider"></span>
          <span>{{ event.address.city }}, {{ event.address.namePlace }}, {{ event.address.street }}</span>
        </ng-container>
      </span>
    </div>
    <div class="search-option__action">
      <ng-container [ngSwitch]="titleEventAvailabilityDataMap.get(event.id)?.titleEventAvailability">
        <ng-container *ngSwitchCase="titleEventAvailabilities.AVAILABLE">
          <eb-buy-ticket-button [titleEvent]="event" [ebSize]="'small'" [virtualPageView]="virtualPageView">
            <svg-icon key="ticket" /> {{ 'shared.search-select.buyTicket' | transloco }}
            <ng-container eb-buy-ticket-queue>
              <p class="eb-size--tiny search-queue">
                <span [innerHtml]="'libs.title-event-bar.redirectToShop' | transloco"></span>
              </p>
            </ng-container>
          </eb-buy-ticket-button>
        </ng-container>
        <ng-container *ngSwitchCase="titleEventAvailabilities.CURRENTLY_UNAVAILABLE">
          <p class="eb-size--tiny mb-0">{{ 'shared.search-select.ticketsTemporarilyUnavailable' | transloco }}</p>
          <p class="eb-size--tiny">{{ 'shared.search-select.firstToKnow' | transloco }}</p>
          <button eb-button ebType="primary" ebType="secondary">
            <svg-icon key="bell" /> {{ 'shared.search-select.notifyMe' | transloco }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="titleEventAvailabilities.SOLD_OUT">
          <p class="h6 eb-title-event-bar__sold-out">{{ 'shared.search-select.soldOut' | transloco }}</p>
        </ng-container>
        <ng-container *ngSwitchCase="titleEventAvailabilities.SELL_OPEN_SOON">
          <p class="h6">{{ 'shared.search-select.saleSoon' | transloco }}</p>
        </ng-container>
        <ng-container *ngSwitchCase="titleEventAvailabilities.SELL_ENDED">
          <p class="h6">{{ 'shared.search-select.saleEnded' | transloco }}</p>
        </ng-container>
        <ng-container *ngSwitchCase="titleEventAvailabilities.CHECK_FREE_SEATS">
          <svg-icon key="loading" />
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #artistsOptionTemplate let-artist>
  <div class="search-option" (click)="selectArtistOption($event, artist)">
    <div class="search-option__thumbnail">
      <div class="img--circle">
        <img [src]="artist.imageUrl | media" [alt]="artist.artistName" />
      </div>
    </div>
    <div class="ml-3">
      <span class="search-option__title">{{ artist.artistName }}</span>
    </div>
  </div>
</ng-template>

<ng-template #placeOptionTemplate let-place>
  <div class="search-option" (click)="selectPlaceOption($event, place)">
    <div class="search-option__thumbnail">
      <img [src]="place.imageUrl | media" [alt]="place.customName" />
    </div>
    <div class="ml-3">
      <span class="search-option__title">{{ place.customName }}</span>
      <span class="search-option__description eb-size--small">
        <span>{{ place.city }}</span>
      </span>
    </div>
  </div>
</ng-template>
