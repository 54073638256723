import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IBasket } from '../../../rest-api/models/basket.model';

@Component({
    selector: 'eb-basket-drawer',
    templateUrl: './basket-drawer.component.html',
    styleUrl: './basket-drawer.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketDrawerComponent {
    private _isVisible = false;
    @Input() basket!: IBasket;

    @Input({ required: true }) public set isVisible(isVisible: boolean) {
        if (isVisible) {
            this._show();
        } else {
            this._close();
        }
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    @Output() isVisibleChange = new EventEmitter<boolean>();

    private _show(): void {
        this._isVisible = true;
        if (this.isVisibleChange.observed) {
            this.isVisibleChange.emit(this.isVisible);
        }

    }

    private _close(): void {
        this._isVisible = false;
        if (this.isVisibleChange.observed) {
            this.isVisibleChange.emit(this.isVisible);
        }
    }
}
