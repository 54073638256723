import { ListViewTypes } from '@e-bilet/ui-list-view-toggle-button';
import { TitleEventsPresentationTypes } from '../rest-api/models/title-page.model';

export class TitleEventsPresentationTypeMapper {
    static mapToListViewType(titleEventsPresentationType: TitleEventsPresentationTypes): ListViewTypes {
        switch (titleEventsPresentationType) {
            case TitleEventsPresentationTypes.DAY:
                return ListViewTypes.DAY;
            case TitleEventsPresentationTypes.MONTH:
                return ListViewTypes.CALENDAR;
            case TitleEventsPresentationTypes.SINGLE:
                return ListViewTypes.BAR;
            default:
                throw new Error(`TitleEventsPresentationTypeMapper unrecognize value '${titleEventsPresentationType}'`);
        }
    }
}
