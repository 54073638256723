import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EbDrawerComponent } from 'libs/ui-drawer/src/lib/drawer/drawer.component';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { TitleGallery, TitleGalleryItem, TitleGalleryItemTypes } from '../../../rest-api/models/title-page.model';
import { YOUTUBE_NOOCOOKIE, YOUTUBE_REGEX } from '../../../../consts/social-urls.const';

@Component({
    selector: 'eb-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbGalleryComponent implements OnInit, OnChanges {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _deviceService = inject(DeviceService);
    private _destroyRef = inject(DestroyRef);

    gridItems: TitleGalleryItem[] = [];
    galleryItems: TitleGalleryItem[] = [];
    isGridVisible = false;
    hiddenItemsCount = 0;
    lastGridItem?: TitleGalleryItem;
    readonly titleGalleryItemTypes = TitleGalleryItemTypes;
    protected get isMobileGallery(): boolean | undefined {
        return !this.isGridVisible || this.overlayGallery?.isVisible;
    }

    @Input() titleGallery: TitleGallery | null | undefined = null;
    @Input() parentPageTitle: string | undefined;
    @Input() isLoading = false;

    @ViewChild('overlayGallery') readonly overlayGallery: EbDrawerComponent | undefined;

    public ngOnInit(): void {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            this.isGridVisible = !isMobile;
            this._changeDetectorRef.markForCheck();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.titleGallery.currentValue) {
            let galleryItems = changes.titleGallery.currentValue.items.sort(
                (a: TitleGalleryItem, b: TitleGalleryItem) => {
                    if (typeof a.row !== 'number' || typeof a.column !== 'number') {
                        return 1;
                    }

                    if (typeof b.row !== 'number' || typeof b.column !== 'number') {
                        return -1;
                    }

                    if (a.row === b.row) {
                        return a.column - b.column;
                    }

                    return a.row - b.row;
                },
            );

            this.galleryItems = this._setNoCookieGallery(galleryItems);
            this.gridItems = this.galleryItems.filter((i) => typeof i.column === 'number');
            this.lastGridItem = this._getLastGridItem(this.gridItems);
            this.hiddenItemsCount = this.galleryItems.length - this.gridItems.length;
        }
    }

    private _getLastGridItem(gridItems: TitleGalleryItem[]): TitleGalleryItem {
        const grid: TitleGalleryItem[][] = [[], [], []];

        gridItems.forEach((item) => {
            const row = item.row as number;
            const maxRow = row + (item.rowSpan as number);
            const column = item.column as number;
            const maxColumn = column + (item.columnSpan as number);

            for (let col = column; col < maxColumn; col++) {
                for (let r = row; r < maxRow; r++) {
                    grid[col][r] = item;
                }
            }
        });

        return grid[2][1];
    }

    private _setNoCookieGallery(galleryItems: TitleGalleryItem[]): TitleGalleryItem[] {
        galleryItems.map((item: TitleGalleryItem) => {
            if (item.type === TitleGalleryItemTypes.VIDEO) {
                item.url = item.url.replace(YOUTUBE_REGEX, YOUTUBE_NOOCOOKIE);
                item.smallUrl = item.smallUrl.replace(YOUTUBE_REGEX, YOUTUBE_NOOCOOKIE);
                item.largeUrl = item.largeUrl.replace(YOUTUBE_REGEX, YOUTUBE_NOOCOOKIE);
            }

            return item;
        });

        return galleryItems;
    }
}
