<div class="eb-accordion-panel-header flex align-items-center py-4 px-3 cursor-pointer" (click)="toggle()">
  <div class="eb-accordion-panel-header__content h6 flex-1">
    <ng-container *ngTemplateOutlet="header" />
  </div>
  <div class="eb-accordion-panel-arrow">
    <svg-icon [key]="isActive ? 'chevron-up' : 'chevron-down'" />
  </div>
</div>
<div class="eb-accordion-panel-divider"></div>
<div class="eb-accordion-panel-body-container overflow-hidden" [style.height.px]="height">
  <div #body class="eb-accordion-panel-body py-4 px-3">
    <ng-content />
    <ng-container *ngIf="contentRendered">
      <ng-container *ngTemplateOutlet="content" />
    </ng-container>
  </div>
</div>
