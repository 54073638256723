import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { EbPagedListComponent } from './paged-list/paged-list.component';

@NgModule({
    imports: [CommonModule, UiButtonsModule, UiPipeModule, TranslocoModule, SvgIconComponent],
    declarations: [EbPagedListComponent],
    exports: [EbPagedListComponent],
})
export class UiPagedListModule {}
