import { format, isFirstDayOfMonth } from 'date-fns';

export class CalendarHelper {
    static createKey(date: Date): string {
        return format(date, 'yyyy-MM-dd');
    }

    static createLabel(date: Date): string {
        return isFirstDayOfMonth(date) ? format(date, 'd.MM') : format(date, 'd');
    }

    static getStartOfDay(date: Date): string {
        return `${format(date, 'yyyy-MM-dd')}T00:00:00.000Z`;
    }

    static getEndOfDay(date: Date): string {
        return `${format(date, 'yyyy-MM-dd')}T23:59:59.999Z`;
    }
}
