<div class="flex flex-column mb-3" [ngClass]="{ ' eb-calendar-container': listViewType === listViewTypes.DAY }">
  <ng-container [ngSwitch]="isLoading">
    <ng-container *ngSwitchCase="true">
      <div class="title-event-list-header flex align-items-center justify-content-between w-full mb-5 md:mb-0">
        <h2 *ngIf="headerVisible" class="eb-skeleton" style="width: 100px"></h2>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <div class="title-event-list-header flex align-items-center justify-content-between w-full mb-5 md:mb-0">
        <h2 *ngIf="headerVisible" class="mb-0">{{ artistName }} {{ 'shared.title-event-list.header' | transloco }}</h2>
        <eb-list-view-toggle-button
          *ngIf="showFanAlert && listViewType !== listViewTypes.DAY && !isEventDateHidden"
          [(current)]="listViewType"
          [availableTypes]="availableListViewTypes"
          class="ml-auto"
        />
        <button
          *ngIf="listViewType === listViewTypes.DAY && !isEventDateHidden"
          eb-button
          ebType="secondary"
          class="eb-btn-calendar-filter eb-md-visible"
          [ebOverlay]="overlayPanel"
        >
          <svg-icon key="calendar-month" />
          {{ 'shared.title-event-list.selectDate' | transloco }}
        </button>
      </div>
      <div class="eb-md-hidden" *ngIf="listViewType === listViewTypes.DAY && !isEventDateHidden">
        <eb-title-event-calendar
          [titleEvents]="titleEvents"
          [partnerId]="partnerId"
          [hideNumberOfEventsPerDay]="hideNumberOfEventsPerDay"
          (selectedCalendarItemChanged)="changeFilteredEvents($event)"
          [isCalendarFilter]="true"
          [checkHeaderFormat]="checkCalendarFilterHeaderFormat"
          [calendarFilterHeaderFormat]="calendarFilterHeaderFormat"
          [fromDayView]="true"
        />
      </div>
      <eb-overlay-panel #overlayPanel [overlayStyle]="'eb-title-event-calendar-filter-overlay-panel'">
        <ng-template let-data>
          <eb-title-event-calendar
            [titleEvents]="titleEvents"
            [partnerId]="partnerId"
            [hideNumberOfEventsPerDay]="hideNumberOfEventsPerDay"
            [equalDays]="true"
            (selectedCalendarItemChanged)="changeFilteredEvents($event, overlayPanel)"
            [isCalendarFilter]="true"
            [checkHeaderFormat]="checkCalendarFilterHeaderFormat"
            [calendarFilterHeaderFormat]="calendarFilterHeaderFormat"
            [selectedDate]="selectedDate"
            [isOverlay]="true"
            [fromDayView]="true"
          />
        </ng-template>
      </eb-overlay-panel>
    </ng-container>
  </ng-container>
</div>

@if (showDelayedPayments) {
  <eb-double-cards />
}

<ng-container [ngSwitch]="listViewType">
  <ng-container *ngSwitchCase="listViewTypes.BAR">
    <ng-container [ngSwitch]="isLoading">
      <ng-container *ngSwitchCase="true">
        <div ebIntersection [ebOnlyFirst]="true" (intersecting)="skeletonVisible = true">
          <eb-title-event-bar
            class="mb-3"
            *ngFor="let titleEvent of skeletonVisible ? [1, 2, 3] : []"
            [skeletonView]="true"
            [isThumbnailHidden]="isThumbnailHidden"
            [showFanAlert]="showFanAlert"
          />
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <ng-container *ngIf="!barPagedList">
          <ng-container *ngIf="groupEvents; else ungroupedEvents">
            <div
              *ngFor="let eventsGroup of namedTitleEventsGroups; let i = index"
              class="title-event-group mb-3 pt-3 px-3 pb-2 md:pt-5 md:px-5 md:pb-3"
            >
              <p class="title-event-group__header h5 mb-3">{{ eventsGroup.groupName }}</p>
              <eb-title-event-bar
                class="mb-3"
                *ngFor="let titleEvent of eventsGroup.events"
                [titleEvent]="titleEvent"
                [isThumbnailHidden]="isThumbnailHidden"
                [partnerId]="partnerId"
                [tokenCheckSum]="tokenCheckSum"
                [virtualPageView]="virtualPageView"
                [addMicroData]="addMicroData && i < 10"
                [artists]="artists"
                [microDescription]="microDescription"
                [titleUrl]="titleUrl"
                [isTitlePage]="isTitlePage"
                [showFanAlert]="showFanAlert"
              />
            </div>
            <ng-container *ngFor="let eventGroup of unnamedTitleEventsGroups; let i = index">
              <eb-title-event-bar
                class="mb-3"
                *ngFor="let titleEvent of eventGroup.events"
                [titleEvent]="titleEvent"
                [isThumbnailHidden]="isThumbnailHidden"
                [partnerId]="partnerId"
                [tokenCheckSum]="tokenCheckSum"
                [virtualPageView]="virtualPageView"
                [addMicroData]="addMicroData && i < 10"
                [artists]="artists"
                [microDescription]="microDescription"
                [titleUrl]="titleUrl"
                [isTitlePage]="isTitlePage"
                [showFanAlert]="showFanAlert"
              />
            </ng-container>
          </ng-container>
          <ng-template #ungroupedEvents>
            <eb-title-event-bar
              class="mb-3"
              *ngFor="let titleEvent of titleEvents; let i = index"
              [titleEvent]="titleEvent"
              [isThumbnailHidden]="isThumbnailHidden"
              [partnerId]="partnerId"
              [tokenCheckSum]="tokenCheckSum"
              [virtualPageView]="virtualPageView"
              [addMicroData]="addMicroData && i < 10"
              [artists]="artists"
              [microDescription]="microDescription"
              [titleUrl]="titleUrl"
              [isTitlePage]="isTitlePage"
              [showFanAlert]="showFanAlert"
            />
          </ng-template>
        </ng-container>
        <ng-container *ngIf="barPagedList">
          <eb-paged-list
            [items]="titleEvents"
            [totalItems]="totalItems"
            [buttonLeft]="buttonLeft"
            [pageSize]="pageParamsManager?.pageParam?.size || 0"
            (fetchingPage)="pageParamsManager?.next()"
          >
            <ng-template let-titleEvent let-i="i">
              <eb-title-event-bar
                class="mb-3"
                [titleEvent]="titleEvent"
                [isThumbnailHidden]="isThumbnailHidden"
                [partnerId]="partnerId"
                [tokenCheckSum]="tokenCheckSum"
                [virtualPageView]="virtualPageView"
                [addMicroData]="addMicroData && i < 10"
                [artists]="artists"
                [microDescription]="microDescription"
                [titleUrl]="titleUrl"
                [showFanAlert]="showFanAlert"
              />
            </ng-template>
          </eb-paged-list>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="listViewTypes.CALENDAR">
    <eb-title-event-calendar
      *ngIf="!isLoading"
      [titleEvents]="titleEvents"
      [partnerId]="partnerId"
      [tokenCheckSum]="tokenCheckSum"
      [virtualPageView]="virtualPageView"
      [checkHeaderFormat]="true"
      [dateMode]="dateMode"
      [dateFrom]="dateFrom"
      [calendarData]="calendarEvents"
      (calendarViewChanged)="calendarViewChanged.emit($event)"
    >
    </eb-title-event-calendar>
  </ng-container>
  <ng-container *ngSwitchCase="listViewTypes.DAY">
    <ng-container *ngIf="isDayViewLoading" [ngTemplateOutlet]="loadingLabel"></ng-container>

    <eb-paged-list
      *ngIf="!isLoading"
      [styleClass]="'eb-paged-list--day-view pb-0'"
      [ngClass]="{ hidden: isDayViewLoading }"
      [pageSize]="3"
      [localPaging]="true"
      [items]="calendarData"
      [totalItems]="calendarData.length"
      [buttonLeft]="buttonLeft"
      [showMoreLabel]="'shared.title-event-list.showMore' | transloco"
      (itemsChanged)="onDayViewChanged()"
    >
      <ng-template let-dayTitleEvent>
        <div class="mb-3">
          <div class="day-view-header">
            <h6 *ngIf="todayDateKey === dayTitleEvent[0]">{{ 'shared.title-event-list.today' | transloco }}</h6>
            <h6 *ngIf="tomorrowDateKey === dayTitleEvent[0]">{{ 'shared.title-event-list.tomorrow' | transloco }}</h6>
            <span>{{ dayTitleEvent[0] | date: 'EEEE dd.MM.yyyy' : undefined : activeLanguage }}</span>
          </div>
          <eb-carousel>
            <eb-title-event-time-slot
              *ngFor="let titleEvent of dayTitleEvent[1]; let i = index"
              ebCarouselItem
              [partnerId]="partnerId"
              [tokenCheckSum]="tokenCheckSum"
              [virtualPageView]="virtualPageView"
              [titleEvent]="titleEvent"
              class="mt-3 mr-3"
              [addMicroData]="addMicroData && i < 10"
              [artists]="artists"
              [titleUrl]="titleUrl"
              [microDescription]="microDescription"
            />
          </eb-carousel>
          <ng-container> </ng-container>
        </div>
      </ng-template>
    </eb-paged-list>
  </ng-container>
</ng-container>

<ng-template #loadingLabel>
  <div *ngIf="isDayViewLoading" class="flex justify-content-center align-items-center">
    {{ 'shared.title-event-list.loading' | transloco }} <svg-icon key="loading" class="ml-2" />
  </div>
</ng-template>
