import {
    Component,
    ContentChild,
    DestroyRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewContainerRef,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, take } from 'rxjs';
import { EbDialogContainerComponent } from '../dialog-container/dialog-container.component';
import { EbDialogContentDirective } from '../dialog-content.directive';
import { EbDialogFooterDirective } from '../dialog-footer.directive';
import { EbDialogHeaderDirective } from '../dialog-header.directive';
import { DialogService } from './dialog.service';

@Component({
    selector: 'eb-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.less'],
})
export class EbDialogComponent<T = undefined> {
    private readonly _viewContainerRef = inject(ViewContainerRef);
    private readonly _dialogService = inject(DialogService);
    private _destroyRef = inject(DestroyRef);

    private _isVisible = false;
    private _container: EbDialogContainerComponent<T> | null = null;

    @Input() className?: string;
    @Input() showCloseIcon = true;

    @Input() public set isVisible(isVisible: boolean) {
        if (isVisible !== this._isVisible) {
            this._isVisible = isVisible;
            if (this._isVisible) {
                this.show();
            } else {
                this.hide();
            }
        }
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    @Output() readonly isVisibleChange = new EventEmitter<boolean>();

    @ContentChild(EbDialogHeaderDirective, { static: true, read: TemplateRef }) readonly header!: TemplateRef<unknown>;
    @ContentChild(EbDialogContentDirective, { static: true, read: TemplateRef })
    readonly content!: TemplateRef<unknown>;

    @ContentChild(EbDialogFooterDirective, { static: true, read: TemplateRef }) readonly footer!: TemplateRef<unknown>;

    public show(): Observable<T> {
        const result$ = new Subject<T>();
        this._container = this._dialogService.create({
            viewContainerRef: this._viewContainerRef,
            header: this.header,
            content: this.content,
            footer: this.footer,
            className: this.className || '',
            showCloseIcon: this.showCloseIcon,
        });
        this._container.closing.pipe(take(1), takeUntilDestroyed(this._destroyRef)).subscribe((result) => {
            this.isVisible = false;
            if (this.isVisibleChange.observed) {
                this.isVisibleChange.emit(this.isVisible);
            }
            if (result !== undefined) {
                result$.next(result);
            }
            result$.complete();
        });

        return result$.asObservable();
    }

    public hide(result?: T): void {
        if (this._container) {
            this._container.hide(result);
            this._container = null;
        }
    }
}
