import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { HeartButtonComponent } from './heart-button/heart-button.component';

@NgModule({
    imports: [CommonModule, UiButtonsModule, SvgIconComponent],
    declarations: [HeartButtonComponent],
    exports: [HeartButtonComponent],
})
export class UiHeartButtonModule {}
