import { Component, Input } from '@angular/core';

@Component({
    selector: 'eb-footer-iframe',
    templateUrl: './footer-iframe.component.html',
    styleUrls: ['./footer-iframe.component.less'],
})
export class FooterIframeComponent {
    @Input() dark = false;
}
