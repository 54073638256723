<div class="eb-scrollbar"
     [ngClass]="{
       'eb-scrollbar--active': isActive,
       'eb-scrollbar--full-width-mobile': fullWidthMobile,
       'eb-scrollbar--dark': dark,
       'eb-scrollbar--y': y
     }"
>
  <div class="eb-scrollbar__track"
       #scrollbarTrack
       (click)="onTrackClick($event)"
  >
    <div class="eb-scrollbar__thumb" *ngIf="isActive"
         [style]="y ? {
                height: thumbWidth + '%',
                top: thumbPosition + 'px'
         } :
         {
                width: thumbWidth + '%',
                left: thumbPosition + 'px'
         }"
         [ngClass]="{
            'eb-scrollbar__thumb--scrolled': scrolled
         }"
         (mousedown)="_startScroll($event)"
         (click)="$event.stopPropagation()"
    ></div>
  </div>
</div>
