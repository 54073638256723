import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollbarDirective } from './scrollbar.directive';
import { EbScrollbarComponent } from './scrollbar/scrollbar.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ScrollbarDirective, EbScrollbarComponent],
    exports: [ScrollbarDirective],
})
export class UiScrollbarModule {}
