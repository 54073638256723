import { Component, Input } from '@angular/core';
@Component({
    selector: 'eb-page-description',
    templateUrl: './page-description.component.html',
    styleUrls: ['./page-description.component.less'],
})
export class PageDescriptionComponent {
    showExtendedDescription = false;
    skeletonVisible = false;

    @Input({ required: true }) title!: string;
    @Input() isLoading = false;
    @Input() description: string | undefined;
    @Input() extendedDescription: string | undefined;

    protected toggleShowExtendedDescription(): void {
        this.showExtendedDescription = !this.showExtendedDescription;
    }
}
