import { ITitleOnTime } from 'apps/portal/src/app/rest-api/models/category.model';
import { EventSearch, TitleSearch } from 'apps/portal/src/app/rest-api/models/search-response.model';
import { Title } from 'apps/portal/src/app/rest-api/models/title.model';

export class ViewedTitles {
    viewedTitles: Title[] = [];
    viewedPromotedTitles: Title[] = [];
    viewedTitleSearchItems: TitleSearch[] = [];
    viewedPromotedTitleSearchItems: TitleSearch[] = [];
    viewedPromotedEventSearchItems: EventSearch[] = [];
    viewedPromotedItitleOnTimeItems: ITitleOnTime[] = [];
}
