import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { UiFormItemModule } from './../../../ui-form-item/src/lib/ui-form-item.module';
import { EbTreeOptionComponent } from './tree-option/tree-option.component';
import { EbTreeSelectListComponent } from './tree-select-list/tree-select-list.component';
import { EbTreeSelectComponent } from './tree-select/tree-select.component';

@NgModule({
    imports: [CommonModule, OverlayModule, ReactiveFormsModule, UiFormItemModule, TranslocoModule, SvgIconComponent],
    declarations: [EbTreeSelectComponent, EbTreeOptionComponent, EbTreeSelectListComponent],
    exports: [EbTreeSelectComponent, EbTreeOptionComponent, EbTreeSelectListComponent],
})
export class UiTreeSelectModule {}
