import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { AffixService } from 'libs/ui-affix/src/lib/affix/affix.service';
import { IBranding } from '../../../rest-api/models/title-page.model';
import { InternationalizationService } from '../../../services/internationalization.service';
import { FooterStoreService } from '../../../stores/footer-store.service';
import { SOCIAL_URLS } from './../../../../consts/social-urls.const';

export interface Footer {
    items: FooterSection[];
}

export interface FooterSection {
    name: string;
    items: FooterLink[];
}

export interface FooterLink {
    name: string;
    url: string;
    isUrlExternal: boolean;
    isBolded: boolean;
}

@Component({
    selector: 'eb-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbFooterComponent implements OnInit {
    private readonly _footerStoreService = inject(FooterStoreService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _affixService = inject(AffixService);
    private readonly _el = inject(ElementRef);
    private _destroyRef = inject(DestroyRef);
    public readonly i18nService = inject(InternationalizationService);
    public readonly activatedRoute = inject(ActivatedRoute);

    footer: Footer | null = null;
    readonly socialUrls = SOCIAL_URLS;
    year: number;
    @Input() branding: IBranding | null = null;

    constructor() {
        this.year = new Date().getFullYear();
        this._setFooterPadding();
    }

    public ngOnInit(): void {
        this._footerStoreService
            .get()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((footer: Footer) => {
                this.footer = footer;
                this._changeDetectorRef.markForCheck();
            });
    }

    private _setFooterPadding(): void {
        this._affixService.fixed$.pipe(takeUntilDestroyed()).subscribe((isAffixFixed) => {
            const ebFooter = (this._el.nativeElement as HTMLElement).children[0];
            if (isAffixFixed) {
                ebFooter.classList.toggle('eb-footer-padding-with-affix', true);
            } else {
                ebFooter.classList.remove('eb-footer-padding-with-affix');
            }
        });
    }

    protected redirect(): void {
        if (this.branding?.footerImageLink) {
            window.location.href = this.branding?.footerImageLink;
        }
    }

    protected generateInternalLink(linkUrl: string): string[] {
        if (linkUrl.charAt(0) === '/') {
            linkUrl = linkUrl.substring(1);
        }
        return [this.i18nService.isEnglishActive ? '/en' : '/', ...linkUrl.split('/').filter((value) => value)];
    }
}
