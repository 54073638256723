<div class="eb-fan-alert flex">
  <button
    *ngIf="!isObserved"
    [ebType]="unobservedButtonType"
    eb-button
    ebTooltip
    [ebSize]="size"
    [ebGhost]="isGhostButton"
    [ebTooltipTemplateRef]="tooltipContentTmpl"
    [ebTooltipCloseButtonVisible]="false"
    [ebTooltipIsFanAlert]="true"
    (isLoading)="onTooltipLoad($event)"
    (click)="onTooltipLoad(true)"
  >
    <div class="flex align-items-center" *ngIf="labelVisible">
      <svg-icon *ngIf="iconVisible" key="bell" class="icon--lg-root-relative mr-2" size="lgr" />
      <span>{{ label | transloco }}</span>
    </div>
    <div class="eb-fan-alert-default-button-label" *ngIf="!labelVisible">
      <div><svg-icon key="bell" class="icon--lg-root-relative" size="lgr" /></div>
      <div>
        <span class="eb-size--tiny">{{ 'libs.fan-alert.observe' | transloco }}</span>
      </div>
    </div>
  </button>

  <button
    *ngIf="isObserved"
    eb-button
    [ebType]="observedButtonType"
    [ebColor]="observedButtonColor"
    [ebSize]="size"
    [ebGhost]="isGhostButton"
  >
    <div class="flex align-items-center" *ngIf="labelVisible">
      <svg-icon key="check" class="icon--lg-root-relative mr-2" size="lgr" />
      <span class="ml-2">{{ 'libs.fan-alert.notifications' | transloco }}</span>
    </div>
    <div class="eb-fan-alert-default-button-label" *ngIf="!labelVisible">
      <div [ngClass]="{ 'eb-fan-alert-icon--observed': isObserved && observedIcon === 'bell-yellow' }">
        <svg-icon key="bell-yellow" class="icon--lg-root-relative mr-2" size="lgr" />
      </div>
      <div>
        <span class="eb-size--tiny">{{ 'libs.fan-alert.observe' | transloco }}</span>
      </div>
    </div>
  </button>

  <div *ngIf="isTooltipLoading" class="eb-fan-alert__skeleton flex align-items-center justify-content-center">
    <svg-icon key="loading" />
  </div>

  <ng-template #tooltipContentTmpl>
    <div class="eb-fan-alert-header mb-3">{{ 'libs.fan-alert.header' | transloco }}.</div>

    <form class="eb-fan-alert-form mb-3">
      <div class="flex flex-1 mb-2">
        <eb-form-item class="flex-1" label="Email" prefixIcon="envelope" theme="light" [required]="true">
          <input
            type="email"
            [email]="true"
            placeholder=" "
            (keyup.enter)="observe()"
            class="eb-input"
            [ngClass]="{ 'eb-input-error': isValidated && emailControl.invalid }"
            [formControl]="emailControl"
          />
        </eb-form-item>
        <button eb-button ebType="primary" type="button" (click)="observe()">
          <svg-icon [key]="isInProgress ? 'loading' : 'chevron-right'" />
        </button>
      </div>
      <span *ngIf="isValidated && emailControl.invalid" class="eb-form-error eb-size--small">
        {{ 'libs.fan-alert.invalidEmail' | transloco }}
      </span>
    </form>

    <div class="eb-fan-alert-info">
      <div class="mb-2">
        {{ 'libs.fan-alert.info' | transloco }}
        <a class="eb-link--underline" href="https://www.ebilet.pl/lp/polityka-prywatnosci/">{{
          'libs.fan-alert.privacyPolicy' | transloco
        }}</a>
      </div>
      <div class="mb-2">
        {{ 'libs.fan-alert.statute' | transloco }}
        <a class="eb-link--underline" href="https://www.ebilet.pl/lp/regulamin-swiadczenia-uslug-droga-elektroniczna/">{{
          'libs.fan-alert.here' | transloco
        }}</a>
      </div>
      <div>{{ 'libs.fan-alert.infoAboutResignation' | transloco }}</div>
    </div>
  </ng-template>
</div>
