  @if(!!titlesData && ((titlesData.titlesFeatured | notEmptyArray) || (titlesData.titlesInRange | notEmptyArray))) {
    <ng-content />
    <ng-container>
      <ng-container *ngTemplateOutlet="itemTemplate" />
    </ng-container>
  } @else if(items.length !== 0 ) {
    <div class="eb-paged-list pb-5" [ngClass]="styleClass">
      <ng-content /> <!-- błąd hydracji https://github.com/angular/angular/issues/50543 -->
      <ng-container *ngFor="let item of items | slice:0:totalVisible; let i = index;">
        <ng-container *ngTemplateOutlet="itemTemplate; context:{ $implicit: item, i: i}" />
      </ng-container>
    </div>
  }
<div class="eb-paged-list__controls pb-5" [ngClass]="{'text-center': !buttonLeft}" *ngIf="hasMoreItems && titlesData !== null">
  <button eb-button ebType="secondary" [disabled]="loading" (click)="showMore()">
    <ng-container *ngIf="loading; then loadingLabel; else idleLabel " />
  </button>
</div>

<ng-template #idleLabel>
  {{ showMoreLabel || 'libs.paged-list.showMore' | transloco }} <svg-icon key="chevron-down"  ebIntersection [ebDisabled]="!infiniteScroll" (intersecting)="$event && showMore()" />
</ng-template>

<ng-template #loadingLabel>
  {{'libs.paged-list.loading' | transloco}} <svg-icon key="loading" />
</ng-template>
