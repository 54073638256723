import { CdkOverlayOrigin, ConnectedOverlayPositionChange, ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ITooltipPosition } from '../tooltip-position.interface';
import { FAN_ALERT_AVAILABLE_POSITIONS, TOOLTIP_POSITIONS } from '../tooltip-positions.const';

@Component({
    selector: 'eb-tooltip-element',
    templateUrl: './tooltip-element.component.html',
    styleUrls: ['./tooltip-element.component.less'],
})
export class TooltipElementComponent implements OnInit {
    text = '';
    templateRef!: TemplateRef<unknown>;
    origin!: CdkOverlayOrigin;
    isOpen = false;
    closeButtonVisible = true;
    positions: ConnectionPositionPair[] = [];
    activePosition = 'top';
    isFanAlert = false;
    public loadedEvent$: Subject<void> = new Subject<void>();

    public ngOnInit(): void {
        if (this.isFanAlert) {
            this.activePosition = 'bottom';
            this.positions = TOOLTIP_POSITIONS.filter(({ name }) => FAN_ALERT_AVAILABLE_POSITIONS.includes(name)).map(
                ({ pair }) => pair,
            );
        } else {
            this.positions = TOOLTIP_POSITIONS.map(({ pair }) => pair);
        }
    }

    public show(el: ElementRef): void {
        this.origin = new CdkOverlayOrigin(el);
        this.isOpen = true;
    }

    public close(): void {
        this.isOpen = false;
    }

    protected positionChange(e: ConnectedOverlayPositionChange): void {
        this.activePosition = this._getPositionName(e);
    }

    protected loaded(): void {
        this.loadedEvent$.next();
    }

    private _getPositionName(position: ConnectedOverlayPositionChange): string {
        const pos = TOOLTIP_POSITIONS.find(
            (_pos: ITooltipPosition) =>
                _pos.pair.overlayY === position.connectionPair.overlayY &&
                _pos.pair.overlayX === position.connectionPair.overlayX &&
                _pos.pair.originY === position.connectionPair.originY &&
                _pos.pair.originX === position.connectionPair.originX,
        );

        return pos?.name ?? 'bottom';
    }
}
