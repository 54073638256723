import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AffixService } from './affix.service';

@Component({
    selector: 'eb-affix',
    templateUrl: './affix.component.html',
    styleUrls: ['./affix.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'block' },
})
export class AffixComponent implements OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _affixService = inject(AffixService);
    private _elementRef = inject(ElementRef);
    private _platformId = inject(PLATFORM_ID);
    private _destroyRef = inject(DestroyRef);

    private _intersectionObserver!: IntersectionObserver;
    private _isFixed = false;
    private _windowWidth: number | undefined;
    private _documentScrollTop: number | undefined;

    @Input() ebDisabled = false;

    @HostBinding('class.eb-affix-container') protected get isAffix(): boolean {
        return true;
    }

    protected get isFixed(): boolean {
        return this._isFixed && !this.ebDisabled;
    }

    public ngOnInit(): void {
        this._createIntersectionObservers();

        this._affixService.fixed$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isFixed) => {
            this._isFixed = isFixed;
            this._changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this._destroyIntersectionObservers();
    }

    private _createIntersectionObservers(): void {
        if (isPlatformBrowser(this._platformId)) {
            this._windowWidth = window.innerWidth;
            this._documentScrollTop = document.documentElement.scrollTop;

            const options = {
                rootMargin: '0px',
                threshold: 0.15,
            };

            this._intersectionObserver = new IntersectionObserver((entries) => {
                const buttonIntersecting = entries.some((e) => e.isIntersecting);
                const windowWidthChanged = window.innerWidth !== this._windowWidth;
                const documentScrollTopChanged = document.documentElement.scrollTop !== this._documentScrollTop;

                if (windowWidthChanged || documentScrollTopChanged) {
                    this._affixService.buttonIntersecting = buttonIntersecting;
                }

                this._windowWidth = window.innerWidth;
                this._documentScrollTop = document.documentElement.scrollTop;
            }, options);

            this._intersectionObserver.observe(this._elementRef.nativeElement);
        }
    }

    private _destroyIntersectionObservers(): void {
        if (this._intersectionObserver) {
            this._intersectionObserver.disconnect();
        }
    }
}
