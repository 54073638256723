export interface FooterDto {
    name: string;
    items: FooterSectionDto[];
    externalId?: number | null;
    isDefault?: boolean;
}

export interface FooterSectionDto {
    name: string;
    items: FooterLinkDto[];
    order: number;
    externalId?: number | null;
}

export interface FooterLinkDto {
    slug: string;
    name: string;
    order: number;
    pageType: FooterPageType;
    externalId?: number | null;
    parentExternalId?: number | null;
}

export enum FooterPageType {
    UNKNOWN = 0,
    CONTENT = 1,
    FAQ_TYPE_BUYER = 2,
    FAQ_TYPE_SELLER = 3,
    FAQ_BUYER_CATEGORY = 4,
    FOOTER_ITEM_LINK = 5,
}
