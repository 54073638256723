import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ISyneriseFanAlert } from '../../../../../libs/synerise/src/lib/model/synerise-fan-alert.model';
import { SyneriseService } from '../../../../../libs/synerise/src/lib/synerise.service';
import { environment } from '../../environments/environment';
import { IFanAlertRequest } from './models/fan-alert-request.model';

@Injectable({
    providedIn: 'root',
})
export class FanAlertRestService {
    private readonly _httpClient = inject(HttpClient);
    private readonly _syneriseService = inject(SyneriseService);

    private readonly _baseUrl = `${environment.portalApi}/fanalert`;

    public subscribeFanAlert(request: IFanAlertRequest): Observable<void> {
        return this._httpClient.post<void>(`${this._baseUrl}/subscribe`, request).pipe(
            tap(() =>
                this._syneriseService.sendData(
                    {
                        email: request.email,
                        customattributes: 'event',
                    } as ISyneriseFanAlert,
                    'setFanAlert',
                ),
            ),
        );
    }
}
