import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { EbDrawerComponent } from './drawer/drawer.component';

@NgModule({
    imports: [CommonModule, NzDrawerModule],
    declarations: [EbDrawerComponent],
    exports: [EbDrawerComponent],
})
export class UiDrawerModule {}
