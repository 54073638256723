<eb-drawer #drawer
[(isVisible)]="isVisible"
placement="bottom" [closable]="true" height="100%"
[bodyStyle]="{ 'padding': '0 0 80px'}"
>
  <div #container class="main-container eb-category-mega-menu py-2">
    <div class="eb-category-mega-menu__actionbar" *ngIf="activeCategory">
      <button eb-button (click)="activeCategory = null; activeSubcategory = null">
        <svg-icon key="chevron-left" />
        <span>{{activeCategory.categoryName}}</span>
      </button>
    </div>
    <div [ngClass]="{ 'hidden': activeCategory }">
      <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'wydarzenia']" class="category-tree__option">
        <div>{{'shared.category-mega-menu-drawer.allEvents' | transloco}}</div>
      </a>
      <div *ngFor="let category of categories" class="category-tree__option" (touchend)="onTouchendMainCategory($event, category)" (click)="activeCategory = category; activeSubcategory = null">
        <ng-container *ngTemplateOutlet="categoryTmpl; context:{ $implicit: category, hasIcon: true }" />
      </div>
    </div>
    <div>
      <div *ngFor="let category of categories" class="category-tree__subcategory" [ngClass]="{'category-tree__subcategory--active': category === activeCategory}">
        <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug]" class="category-tree__option" (click)="activeCategory = category; activeSubcategory = null">
          <div>
            <span>{{'shared.category-mega-menu-drawer.allEvents' | transloco}}</span>
          </div>
        </a>
        <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug]" *ngFor="let subcategory of category.subcategory" class="category-tree__option" (click)="activeSubcategory = subcategory">
          <ng-container *ngTemplateOutlet="categoryTmpl; context:{ $implicit: subcategory, hasIcon: false }" />
        </a>
      </div>
      <div class="category-tree__events-in-city">
        <div *ngIf="cities.length && !activeCategory && !activeSubcategory">
          <p class="h5">{{'shared.category-mega-menu.allEventsInCity' | transloco}}</p>
          <div class="category-tree__cities" *ngIf="cities.length > 0 && !activeCategory && !activeSubcategory">
            <span *ngFor="let city of cities | slice:0:visibleCitesLength">
              <a *ngIf="city" eb-button ebType="secondary" ebSize="small" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'miasto', city.slug]">{{city.name}}</a>
            </span>
          </div>
        </div>

        <div *ngFor="let category of categories">
          <div [ngClass]="(activeCategory === category) && !activeSubcategory ? '' : 'hidden'">
            <p class="h5" *ngIf="category.mostPopularCities?.length">{{'shared.category-mega-menu.allEventsInCity' | transloco}}</p>
            <div class="category-tree__cities">
            <span *ngFor="let mostPopularCity of category.mostPopularCities | slice:0:visibleCitesLength">
              <a *ngIf="mostPopularCity?.name" eb-button ebType="secondary" ebSize="small" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]">{{mostPopularCity.name}}</a>
            </span>
            </div>
          </div>
          <div *ngFor="let subcategory of category.subcategory" [ngClass]="activeSubcategory === subcategory ? '' : 'hidden'">
            <p class="h5" *ngIf="subcategory.mostPopularCities?.length">{{'shared.category-mega-menu.allEventsInCity' | transloco}}</p>
            <div class="category-tree__cities">
            <span *ngFor="let mostPopularCity of subcategory.mostPopularCities | slice:0:visibleCitesLength">
              <a *ngIf="mostPopularCity?.name" eb-button ebType="secondary" ebSize="small" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug, 'miasto', mostPopularCity.slug]">{{mostPopularCity.name}}</a>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</eb-drawer>
<div class="hidden">
  <span *ngFor="let city of cities | slice:0:visibleCitesLength">
    <a *ngIf="city" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'miasto', city.slug]">{{city.name}}</a>
  </span>
  <div *ngFor="let category of categories">
    <span *ngFor="let mostPopularCity of category.mostPopularCities | slice:0:visibleCitesLength">
      <a *ngIf="mostPopularCity?.name" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]">{{mostPopularCity.name}}</a>
    </span>
    <div *ngFor="let subcategory of category.subcategory">
      <span *ngFor="let mostPopularCity of subcategory.mostPopularCities | slice:0:visibleCitesLength">
        <a *ngIf="mostPopularCity?.name" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug, 'miasto', mostPopularCity.slug]">{{mostPopularCity.name}}</a>
      </span>
    </div>
  </div>
</div>

<ng-template #categoryTmpl let-category let-routerLink="routerLink" let-hasIcon="hasIcon">
  <div (touchstart)="onTouchmoveCategory($event, category)" (touchend)="onTouchendCategory($event, category)">
    <svg-icon [key]="category?.icon | ebCategoryIcon" />
    <span>
      {{category?.categoryName}}
    </span>
    <svg-icon *ngIf="hasIcon" key="chevron-right" class="ml-auto" />
  </div>
</ng-template>
