import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SyneriseService } from '@e-bilet/synerise';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ITitlePageQueryParams } from '../title-page/title-page.guard';
import { setNoCookieDescription } from '../../consts/social-urls.const';
import { ITitlePageGetRequest } from './models/title-page-get-request.model';
import { TitlePage, TitlePageEvents } from './models/title-page.model';
import { Title } from './models/title.model';

@Injectable({
    providedIn: 'root',
})
export class TitlePageRestService {
    private readonly _httpClient = inject(HttpClient);
    private readonly _syneriseService = inject(SyneriseService);

    private readonly _baseUrl = `${environment.portalApi}`;

    public get(request: ITitlePageGetRequest): Observable<TitlePage> {
        const queryParams = {
            artist: request.artist,
            city: request.city,
            partner: request.partner,
            eid: request.eid,
            language: request.language,
        } as ITitlePageQueryParams;

        const params = this._getHttpParams(queryParams);

        return this._httpClient
            .get<TitlePage>(`${this._baseUrl}/${request.category}/${request.subcategory}/${request.title}`, { params })
            .pipe(
                map((res) => {
                    if (request.partner && !res.branding) {
                        res.branding = {};
                    }

                    res.description = setNoCookieDescription(res.description) as string;
                    res.extendedDescription = setNoCookieDescription(res.extendedDescription) as string;

                    return res;
                }),
            );
    }

    public getProposed(category: string, subcategory: string, titleId: string): Observable<Title[]> {
        let params = new HttpParams();
        params = params.append('titleId', titleId);

        return this._httpClient.get<Title[]>(`${this._baseUrl}/getProposed/${category}/${subcategory}`, {
            params,
        });
    }

    public getOtherTitlesByOrganizer(titleId: string): Observable<Title[]> {
        return this._httpClient.get<Title[]>(`${this._baseUrl}/Organizers/GetFullMerchantOffer/${titleId}`);
    }

    public setHeart(titleId: string): Observable<object> {
        let params = new HttpParams();
        params = params.append('add', true);

        return this._httpClient.put(`${this._baseUrl}/titlePage/${titleId}/Hearts`, {}, { params });
    }

    public getEvents(titleId: string, queryParams: ITitlePageQueryParams): Observable<TitlePageEvents> {
        const params = this._getHttpParams(queryParams);

        return this._httpClient.get<TitlePageEvents>(`${this._baseUrl}/titlePage/${titleId}/events`, { params });
    }

    public getDontMissTitles(): Observable<Title[]> {
        return this._syneriseService.getClient().pipe(
            switchMap((client) => {
                let params = new HttpParams();
                const userId = client?.uuid;

                if (userId && userId !== 'null') {
                    params = params.append('userId', client.uuid);
                }

                return this._httpClient.get<Title[]>(`${this._baseUrl}/titlePage/getTitlesDoNotMiss`, { params }).pipe(
                    map((res) => {
                        res.map((title) => {
                            const categoryName = title.category;
                            const category = title.categoryName;
                            const subcategoryName = title.subcategory;
                            const subcategory = title.subcategoryName;
                            title.categoryName = categoryName ?? '';
                            title.category = category;
                            title.subcategoryName = subcategoryName ?? '';
                            title.subcategory = subcategory;

                            return title;
                        });
                        return res;
                    }),
                );
            }),
        );
    }

    private _getHttpParams(queryParams: ITitlePageQueryParams): HttpParams {
        const { artist, city, partner, eid, language } = queryParams;
        let params = new HttpParams();
        if (artist) {
            params = params.append('artist', artist);
        }

        if (city) {
            params = params.append('city', city);
        }

        if (partner) {
            params = params.append('partner', partner);
        }

        if (eid) {
            params = params.append('eid', eid);
        }

        if (language) {
            params = params.append('languageCode', language);
        }

        return params;
    }
}
