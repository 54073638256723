import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '../rest-api/models/title.model';
import { TitlePageRestService } from '../rest-api/title-page-rest.service';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class TitlesDontMissStoreService extends BaseStoreService<null, Title[]> {
    private readonly _titlePageRestService = inject(TitlePageRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<Title[]> {
        return () => this._titlePageRestService.getDontMissTitles();
    }

    public load(request: null): Observable<Title[]> {
        return super.load(request);
    }

    public get(): Observable<Title[]> {
        this.load(this._request);
        return super.get();
    }
}
