<ng-container *ngIf="showQueueView; then queueView; else buttonView" />

<ng-template #buttonView>
  <div class="eb-buy-ticket-button flex gap-2 flex-column" [ngClass]="{ 'align-items-center lg:flex-row': row, 'align-items-end': !row }">
    <ng-content select="[eb-buy-ticket-button-before]" />

    <button
      eb-button
      ebType="primary"
      [ebSize]="ebSize"
      (click)="queueRedirectToShop($event)"
      [ngClass]="{ 'eb-promotion-buy-ticket-button': isPromoted && isTitlePage }"
    >
      <ng-content />
    </button>

    <ng-content select="[eb-buy-ticket-button-after]" />
  </div>
</ng-template>

<ng-template #queueView>
  <div [ngClass]="{ 'eb-buy-ticket-button--queue-bar-relative': barRelative }">
    <eb-progress-bar [duration]="queueDelay" />
  </div>
  <ng-content select="[eb-buy-ticket-queue]" />
</ng-template>
