import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceService } from 'libs/device/src/lib/device.service';
import { Article } from '../../../../../apps/portal/src/app/rest-api/models/article.interface';
@Component({
    selector: 'eb-article-card',
    templateUrl: './article-card.component.html',
    styleUrls: ['./article-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbArticleCardComponent implements OnInit {
    private readonly _destroyRef = inject(DestroyRef);

    @Input() article: Article | undefined;
    @Input() skeleton = false;
    @Input() size: 'small' | 'normal' | 'large' | 'extra-large' = 'normal';
    public amountOfTagsVisible = 2;
    public readonly AMOUNT_OF_TAGS_ON_MOBILE = 1;
    public readonly AMOUNT_OF_TAGS_ON_PC = 2;

    constructor(
        private _deviceService: DeviceService,
        private _cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            if (this.size !== 'small') {
                this.amountOfTagsVisible = isMobile ? this.AMOUNT_OF_TAGS_ON_MOBILE : this.AMOUNT_OF_TAGS_ON_PC;
            } else {
                this.amountOfTagsVisible = this.AMOUNT_OF_TAGS_ON_MOBILE;
            }
            this._cdr.detectChanges();
        });
    }
}
