import { ITooltipPosition } from './tooltip-position.interface';

export const TOOLTIP_POSITIONS: ITooltipPosition[] = [
    {
        name: 'top',
        pair: { offsetX: 0, offsetY: -20, originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
    },
    {
        name: 'bottom',
        pair: { offsetX: 0, offsetY: 20, originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
    },
    {
        name: 'topRight',
        pair: { offsetX: -30, offsetY: -20, originX: 'center', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
    },
    {
        name: 'topLeft',
        pair: { offsetX: 30, offsetY: -20, originX: 'center', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
    },
    {
        name: 'bottomRight',
        pair: { offsetX: -30, offsetY: 20, originX: 'center', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    },
    {
        name: 'bottomLeft',
        pair: { offsetX: 30, offsetY: 20, originX: 'center', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    },
];

export const FAN_ALERT_AVAILABLE_POSITIONS: string[] = ['bottom', 'bottomRight', 'bottomLeft'];
