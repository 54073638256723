import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbJsonLdComponent } from './json-ld/json-ld.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EbJsonLdComponent],
    exports: [EbJsonLdComponent],
})
export class JsonLdModule {}
