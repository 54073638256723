import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild, inject } from '@angular/core';

@Component({
    selector: 'eb-scrollbar',
    templateUrl: './scrollbar.component.html',
    styleUrls: ['./scrollbar.component.less'],
})
export class EbScrollbarComponent {
    private readonly _renderer = inject(Renderer2);

    private _thumbWidth = 100;
    private _isActive = false;

    public set thumbWidth(value: number) {
        this._thumbWidth = value;
        this._isActive = this._thumbWidth < 100;
    }

    public get thumbWidth(): number {
        return this._thumbWidth;
    }

    protected get isActive(): boolean {
        return this._isActive;
    }

    thumbPosition = 0;
    scrolled = false;
    startScrollPosition = 0;
    dark = false;
    y = false;
    fullWidthMobile = false;

    moveListener: (() => void | undefined) | undefined;
    mouseUpListener: (() => void | undefined) | undefined;

    @Output() readonly startScroll = new EventEmitter();
    @Output() readonly stopScroll = new EventEmitter();
    @Output() readonly moveScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() readonly scrollTo: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('scrollbarTrack') readonly scrollbarTrack!: ElementRef;

    protected _startScroll(event: MouseEvent): void {
        this.startScrollPosition = this.y ? event.pageY : event.pageX;
        this.scrolled = true;
        if (this.startScroll.observed) {
            this.startScroll.emit();
        }
        this.moveListener = this._renderer.listen('window', 'mousemove', (_event: MouseEvent) =>
            this._moveScroll(_event),
        );
        this.mouseUpListener = this._renderer.listen('window', 'mouseup', () => this._stopScroll());
    }

    private _stopScroll(): void {
        if (this.scrolled && this.stopScroll.observed) {
            this.stopScroll.emit();
        }

        if (this.moveListener) {
            this.moveListener();
        }

        if (this.mouseUpListener) {
            this.mouseUpListener();
        }

        this.scrolled = false;
    }

    private _moveScroll(event: MouseEvent): void {
        if (this.scrolled) {
            const diff = (this.y ? event.pageY : event.pageX) - this.startScrollPosition;
            this.startScrollPosition = this.y ? event.pageY : event.pageX;
            if (this.moveScroll.observed) {
                this.moveScroll.emit(diff);
            }
        }
    }

    protected onTrackClick(event: MouseEvent): void {
        if (this.scrollTo.observed) {
            this.scrollTo.emit(event.offsetX);
        }
    }
}
