import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TitleEvent } from '../../../../../apps/portal/src/app/rest-api/models/title-page.model';
import { ShopQueue, ShopQueueService } from '../../../../../apps/portal/src/app/services/shop-queue.service';
import { BaseVirtualPageView } from '../../../../gtm/src/lib/virtual-page-view';
import { EbButtonSize } from '../../../../ui-buttons/src/lib/button/button.component';

@Component({
    selector: 'eb-buy-ticket-button',
    templateUrl: './eb-buy-ticket-button.component.html',
    styleUrls: ['./eb-buy-ticket-button.component.less'],
    host: { class: 'w-full' },
})
export class EbBuyTicketButtonComponent implements OnInit {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _shopQueueService = inject(ShopQueueService);
    private readonly _platformId = inject(PLATFORM_ID);
    private _destroyRef = inject(DestroyRef);

    showQueueView = false;
    queueDelay = 0;
    queueTimeout: number | undefined;
    @Input({ required: true }) titleEvent!: TitleEvent;
    @Input() ebSize: EbButtonSize = 'default';
    @Input() row = false;
    @Input() virtualPageView: BaseVirtualPageView | undefined;
    @Input() partnerId?: string;
    @Input() tokenCheckSum: string | undefined;
    @Input() isTitlePage = false;
    @Input() barRelative = false;
    @Input() isPromoted: boolean | undefined;

    public ngOnInit(): void {
        this._shopQueueService.setQueue$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((queue: ShopQueue) => {
            if (queue.eventId === this.titleEvent.id) {
                this.queueDelay = queue.queueDelay;
            } else {
                this.queueDelay = 0;
            }

            this.showQueueView = !!this.queueDelay;
            this._changeDetectorRef.markForCheck();
        });

        if (isPlatformBrowser(this._platformId)) {
            this._shopQueueService.redirect$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((id: string) => {
                if (id === this.titleEvent.id) {
                    setTimeout(() => {
                        this.showQueueView = false;
                        this._changeDetectorRef.markForCheck();
                    }, 1000);
                }
            });
        }
    }

    protected queueRedirectToShop(event: MouseEvent): void {
        event.stopPropagation();
        this._shopQueueService.queueRedirectToShop(
            this.titleEvent,
            this.partnerId,
            this.tokenCheckSum,
            event.ctrlKey,
            this.virtualPageView,
        );
    }
}
