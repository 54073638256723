import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'eb-double-cards-dialog',
    templateUrl: './double-cards-dialog.component.html',
    styleUrls: ['./double-cards-dialog.component.less'],
})
export class EbDoubleCardsDialogComponent {
    @Output() readonly closeDialogEvent = new EventEmitter<void>();
    @ViewChild('dialog') readonly dialog: ElementRef | undefined;

    protected closeDialog(): void {
        if (this.closeDialogEvent.observed) {
            this.closeDialogEvent.emit();
        }
    }
}
