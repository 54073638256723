import { Injectable, inject, PLATFORM_ID } from '@angular/core';
import { ITitleOnTime } from 'apps/portal/src/app/rest-api/models/category.model';
import { EventSearch, TitleSearch } from 'apps/portal/src/app/rest-api/models/search-response.model';
import { Title } from 'apps/portal/src/app/rest-api/models/title.model';
import { nameToId } from 'apps/portal/src/consts/name-to-id';
import { Ga4ListNames, GtmService, GtmVariableNames } from './gtm.service';
import { ViewedTitles } from './models/viewed-titles.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AggregatedGa4EventsService {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _gtmService = inject(GtmService);

    private _viewedTitlesMap = new Map<string, ViewedTitles>();
    private _debounceTimeout: ReturnType<typeof setTimeout> | null = null;

    public titleCardIntersecting(
        listName: string,
        title: Title,
        index: number,
        promotionName: string,
        isPromotedList?: boolean,
        disableViewItemList?: boolean,
    ): void {
        title.index = index;
        title.promotionName = this._gtmService.getPromotionNameForGA4(
            promotionName,
            title.manualOrder,
            title.isPromotion,
            isPromotedList,
        );
        const isPromotion = (isPromotedList || title.manualOrder || title.isPromotion) ?? false;

        if (!this._viewedTitlesMap.has(listName)) {
            this._addNewList(listName);
        }
        this._addViewedTitleToList(listName, title, isPromotion, disableViewItemList);

        this._sendEventsToGa4Timeout();
    }

    public iTitleOnTimeIntersecting(
        listName: string,
        iTitleOnTime: ITitleOnTime,
        index: number,
        promotionName: string,
    ): void {
        iTitleOnTime.index = index;
        iTitleOnTime.promotionName = this._gtmService.getPromotionNameForGA4(
            promotionName,
            iTitleOnTime.manualOrder,
            false,
            true,
        );

        if (!this._viewedTitlesMap.has(listName)) {
            this._addNewList(listName);
        }

        this._addViewedItitleOnTimeToList(listName, iTitleOnTime);
        this._sendEventsToGa4Timeout();
    }

    private _addNewList(listName: string): void {
        this._viewedTitlesMap.set(listName, new ViewedTitles());
    }

    private _addViewedTitleToList(
        listName: string,
        title: Title,
        isPromotion: boolean,
        disableViewItemList?: boolean,
    ): void {
        const currentlyViewedTitles = this._viewedTitlesMap.get(listName);

        if (!disableViewItemList) {
            currentlyViewedTitles?.viewedTitles.push(title);
        }

        if (isPromotion) {
            currentlyViewedTitles?.viewedPromotedTitles.push(title);
        }
    }

    public titleSearchItemIntersecting(titleSearch: TitleSearch, isProposedResult: boolean, index: number): void {
        titleSearch.index = index;
        titleSearch.promotionName = this._gtmService.getPromotionNameForGA4(
            Ga4ListNames.SearchSuggest,
            undefined,
            titleSearch.isPromotion,
        );

        if (!this._viewedTitlesMap.has(Ga4ListNames.SearchSuggest)) {
            this._addNewList(Ga4ListNames.SearchSuggest);
        }
        this._addViewedTitleSearchItemToList(Ga4ListNames.SearchSuggest, titleSearch, isProposedResult);

        this._sendEventsToGa4Timeout();
    }

    private _addViewedItitleOnTimeToList(listName: string, iTitleOnTime: ITitleOnTime): void {
        const currentlyViewedItitleOnTimeItems = this._viewedTitlesMap.get(listName);
        currentlyViewedItitleOnTimeItems?.viewedPromotedItitleOnTimeItems.push(iTitleOnTime);
    }

    private _addViewedTitleSearchItemToList(
        listName: string,
        titleSearch: TitleSearch,
        isProposedResult: boolean,
    ): void {
        const currentlyViewedTitleSearchItems = this._viewedTitlesMap.get(listName);

        currentlyViewedTitleSearchItems?.viewedTitleSearchItems.push(titleSearch);

        if (titleSearch.isPromotion || isProposedResult) {
            currentlyViewedTitleSearchItems?.viewedPromotedTitleSearchItems.push(titleSearch);
        }
    }

    public eventSearchItemIntersecting(eventSearch: EventSearch): void {
        if (!eventSearch.isPromotion) {
            return;
        }

        if (!this._viewedTitlesMap.has(Ga4ListNames.SearchSuggest)) {
            this._addNewList(Ga4ListNames.SearchSuggest);
        }

        this._viewedTitlesMap.get(Ga4ListNames.SearchSuggest)?.viewedPromotedEventSearchItems.push(eventSearch);

        this._sendEventsToGa4Timeout();
    }

    private _sendEventsToGa4Timeout(): void {
        if (this._debounceTimeout) {
            clearTimeout(this._debounceTimeout);
        }

        if (isPlatformBrowser(this._platformId)) {
            this._debounceTimeout = setTimeout(() => this._sendGa4Events(), 500);
        }
    }

    private _sendGa4Events(): void {
        for (const listName of this._viewedTitlesMap.keys()) {
            const viewedTitlesToSend = this._viewedTitlesMap.get(listName);

            if (viewedTitlesToSend?.viewedTitles?.length) {
                this._gtmService.viewItemList(viewedTitlesToSend.viewedTitles, listName, nameToId(listName));
            }

            if (viewedTitlesToSend?.viewedPromotedTitles?.length) {
                const mappedTitles = viewedTitlesToSend.viewedPromotedTitles.map((title) =>
                    this._gtmService.mapTitleToGa4Item(
                        title,
                        listName,
                        nameToId(listName),
                        title.index,
                        title.promotionName,
                        nameToId(title.promotionName),
                    ),
                );

                this._gtmService.setGa4Event(
                    mappedTitles,
                    GtmVariableNames.ViewPromotion,
                    undefined,
                    undefined,
                    undefined,
                    listName,
                    nameToId(listName),
                );
            }

            if (viewedTitlesToSend?.viewedTitleSearchItems.length) {
                const mappedTitleSearchItems = viewedTitlesToSend.viewedTitleSearchItems.map((titleSearch) =>
                    this._gtmService.mapTitleSearchToGa4Item(
                        titleSearch,
                        listName,
                        nameToId(listName),
                        titleSearch.index,
                        titleSearch.promotionName,
                        nameToId(titleSearch.promotionName),
                    ),
                );

                this._gtmService.setGa4Event(
                    mappedTitleSearchItems,
                    GtmVariableNames.ViewItemList,
                    listName,
                    nameToId(listName),
                );
            }

            if (viewedTitlesToSend?.viewedPromotedTitleSearchItems.length) {
                const mappedTitleSearchPromotedItems = viewedTitlesToSend.viewedPromotedTitleSearchItems.map(
                    (titleSearch) =>
                        this._gtmService.mapTitleSearchToGa4Item(
                            titleSearch,
                            listName,
                            nameToId(listName),
                            titleSearch.index,
                            titleSearch.promotionName,
                            nameToId(titleSearch.promotionName),
                        ),
                );

                this._gtmService.setGa4Event(
                    mappedTitleSearchPromotedItems,
                    GtmVariableNames.ViewPromotion,
                    undefined,
                    undefined,
                    undefined,
                    'promocja',
                    'promocja',
                );
            }

            if (viewedTitlesToSend?.viewedPromotedEventSearchItems.length) {
                const mappedEventSearchPromotedItems = viewedTitlesToSend.viewedPromotedEventSearchItems.map(
                    (eventSearch) =>
                        this._gtmService.mapEventSearchToGa4Item(eventSearch, listName, nameToId(listName)),
                );

                this._gtmService.setGa4Event(mappedEventSearchPromotedItems, GtmVariableNames.ViewPromotion);
            }

            if (viewedTitlesToSend?.viewedPromotedItitleOnTimeItems.length) {
                const mappedItitleOnTimeItems = viewedTitlesToSend.viewedPromotedItitleOnTimeItems.map((iTitleOnTime) =>
                    this._gtmService.mapItitleOnTimeToGa4Item(iTitleOnTime, listName, nameToId(listName) ?? ''),
                );

                this._gtmService.setGa4Event(mappedItitleOnTimeItems, GtmVariableNames.ViewPromotion);
            }
        }

        this._viewedTitlesMap.clear();
    }
}
