<div class="footer-container">
  <div class="footer fixed bottom-0 left-0 right-0 flex justify-content-center align-items-center text-center" [ngClass]="{'footer-dark': dark}">
    <div class="sales-carried-out">
      <span>Sprzedaż biletów realizowana w systemie</span>
    </div>
    <div class="footer-logo">
      <a href="https://www.ebilet.pl" target="_blank"><img [src]="dark ? '/assets/logo-by-allegro-white.svg' : '/assets/logo-by-allegro.svg'"></a>
    </div>
  </div>
</div>
