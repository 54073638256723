import { Component, Input } from '@angular/core';

@Component({
    selector: 'eb-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.less'],
})
export class EbProgressBarComponent {
    @Input() duration: number | null | undefined;
    @Input() progress = 0;

    protected get animationDuration(): string {
        return `${this.duration ?? 0}s`;
    }
}
