<div class="eb-progress-bar">
  <div class="eb-progress-bar__fill"
     [style]="{
      'animation-duration': animationDuration
     }"
     [style.width.%]="progress"
     [ngClass]="{'eb-progress-bar__fill--animated': duration}"
  >
  </div>
</div>
