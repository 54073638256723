import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HeartService {
    private readonly _heartLiked$ = new Subject<string>();

    public get heartLiked$(): Observable<string> {
        return this._heartLiked$.asObservable();
    }

    public likeHeart(key: string): void {
        this._heartLiked$.next(key);
    }
}
