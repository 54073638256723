import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ITreeOption, TreeSelectMapper } from '@e-bilet/ui-tree-select';
import { Category } from '../../../rest-api/models/category.model';
import { City } from '../../../rest-api/models/province.model';
import { InternationalizationService } from '../../../services/internationalization.service';
import { CategoryStoreService } from '../../../stores/category-store.service';
import { CityStoreService } from '../../../stores/city-store.service';

@Component({
    selector: 'eb-category-mega-menu-drawer',
    templateUrl: './category-mega-menu-drawer.component.html',
    styleUrls: ['./category-mega-menu-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbCategoryMegaMenuDrawerComponent implements OnInit {
    private readonly _categoryStoreService = inject(CategoryStoreService);
    private readonly _cityStoreService = inject(CityStoreService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    public readonly i18nService = inject(InternationalizationService);
    public readonly activatedRoute = inject(ActivatedRoute);
    private _destroyRef = inject(DestroyRef);

    private _isVisible = false;
    categoryTree: ITreeOption<Category>[] = [];
    activeCategory: Category | null = null;
    activeSubcategory: Category | null = null;

    categories: Category[] = [];
    cities: City[] = [];

    @Input({ required: true }) public set isVisible(isVisible: boolean) {
        isVisible ? this._show() : this._close();
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    readonly visibleCitesLength = 24;
    @Output() readonly isVisibleChange = new EventEmitter<boolean>();
    @ViewChild('container') readonly containerElement!: ElementRef<HTMLDivElement>;

    constructor() {
        this._cityStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((cities) => {
                this.cities = cities;
            });
    }

    public ngOnInit(): void {
        this._categoryStoreService
            .load()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((categories) => {
                this.categories = categories;
                this.categoryTree = TreeSelectMapper.map(
                    categories,
                    (c) => c.categoryName,
                    (c) => c.subcategory,
                );
                this._changeDetectorRef.markForCheck();
            });
    }

    private _show(): void {
        this._resetCategories();
        if (!this._isVisible) {
            this._isVisible = true;
            if (this.isVisibleChange.observed) {
                this.isVisibleChange.emit(this.isVisible);
            }
            this._changeDetectorRef.markForCheck();
        }
    }

    private _close(): void {
        if (this._isVisible) {
            this._isVisible = false;
            this.activeSubcategory = null;
            this.activeCategory = null;
            if (this.isVisibleChange.observed) {
                this.isVisibleChange.emit(this.isVisible);
            }
            this._changeDetectorRef.markForCheck();
        }
    }

    protected onTouchendMainCategory(event: TouchEvent, category: Category): void {
        event.preventDefault();
    }

    protected onTouchmoveCategory(event: TouchEvent, category: Category): void {
        event.stopPropagation();
    }

    protected onTouchendCategory(event: TouchEvent, category: Category): void {
        if (category.parentId) {
            this.activeSubcategory = category;
        } else {
            this.activeCategory = category;
            event.preventDefault();
            (this.containerElement.nativeElement.parentNode as HTMLDivElement).scrollTop = 0;
        }
    }

    private _resetCategories(): void {
        this.activeCategory = null;
        this.activeSubcategory = null;
    }
}
