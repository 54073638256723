<nz-drawer
  (nzOnClose)="close()"
  [nzClosable]="closable"
  [nzPlacement]="placement"
  [nzMask]="mask"
  [nzCloseOnNavigation]="true"
  [nzHeight]="height"
  [nzFooter]="footer"
  [nzTitle]="header"
  [nzBodyStyle]="bodyStyle"
  [nzWidth]="width"
  [nzMaskStyle]="maskStyle"
  [nzWrapClassName]="wrapClassName"
  >
  <ng-container *nzDrawerContent>
    <ng-content />
  </ng-container>
</nz-drawer>
