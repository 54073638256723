import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TitleCardSize } from '../title-card/title-card.component';

@Component({
    selector: 'eb-title-card-skeleton',
    templateUrl: './title-card-skeleton.component.html',
    styleUrls: ['./title-card-skeleton.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleCardSkeletonComponent {
    @Input() showTimer = false;
    @Input() cardSize: TitleCardSize = 'normal';
}
