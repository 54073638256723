<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayLockPosition]="isFanAlert"
  (detach)="close()"
  (overlayOutsideClick)="close()"
  (positionChange)="positionChange($event)"
  (attach)="loaded()"
>
  <div class="eb-tooltip-container" [ngClass]="'eb-tooltip-container--' + activePosition">
    <div class="eb-tooltip-content">
      <div [ngClass]="{'mr-2': closeButtonVisible}">
        <p *ngIf="text">{{ text }}</p>
        <ng-template [ngTemplateOutlet]="templateRef" />
      </div>
    </div>
    <svg-icon *ngIf="closeButtonVisible" key="close" class="eb-tooltip-close" (click)="close()" />
    <div class="eb-tooltip-triangle">
      <div class="eb-tooltip-triangle-background">
      </div>
    </div>
  </div>
</ng-template>
