import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JsonLdModule } from '@e-bilet/json-ld';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiBuyTicketButtonModule } from '@e-bilet/ui-buy-ticket-button';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { UiTooltipModule } from '@e-bilet/ui-tooltip';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { UiFanAlertModule } from '../../../ui-fan-alert/src/lib/ui-fan-alert.module';
import { UiTicketPanelModule } from './../../../ui-ticket-panel/src/lib/ui-ticket-panel.module';
import { EbTitleEventBarComponent } from './title-event-bar/title-event-bar.component';

@NgModule({
    imports: [
        CommonModule,
        UiButtonsModule,
        UiPipeModule,
        UiTicketPanelModule,
        UiTooltipModule,
        RouterModule,
        TranslocoModule,
        UiFanAlertModule,
        UiBuyTicketButtonModule,
        JsonLdModule,
        SvgIconComponent,
    ],
    declarations: [EbTitleEventBarComponent],
    exports: [EbTitleEventBarComponent],
})
export class UiTitleEventBarModule {}
