<form [formGroup]="searchForm" class="eb-input-group quick-search-input-group" #formEl>
  <eb-search-select
    [searchParams]="searchForm.value"
    [isQuickSearchInputGroup]="true"
    [showIcon]="false"
    [showSuffixIcon]="false"
    size="large"
    [theme]="theme"
    [overlayOrigin]="overlayOrigin?.elementRef"
    [debounceTime]="300"
    [autoFocus]="autoFocus"
  />
  <eb-date-range-picker
    [label]="'shared.search-input.date' | transloco"
    formControlName="dateRange"
    [onlyIcon]="isCompact"
    size="large"
    [theme]="theme"
    [origin]="isCompact ? overlayOrigin : undefined"
  />
  <eb-location-tree-select
    formControlName="location"
    [onlyIcon]="isCompact"
    [options]="locationTree"
    size="large"
    [theme]="theme"
    [origin]="isCompact ? overlayOrigin : undefined"
    [formElement]="formEl"
  />
  <button eb-button ebType="primary" ebSize="large" ebColor="purple" type="submit" (click)="submit()" class="eb-lg-visible">
    <svg-icon key="search" ebSize="large" class="mr-2" /> {{ 'shared.search-input.search' | transloco }}
  </button>
</form>
