
<div>
  @if (generateJson && !isLoading && json) {
    <eb-json-ld [type]="'faq'" [id]="id" [json]="json" />
  }
  <eb-accordion-panel *ngFor="let item of faqItems" [header]="faqItemHeader1" class="mb-2">
    <ng-template #faqItemHeader1>
      <span>{{item.question}}</span>
    </ng-template>
    <div>
      <div [innerHtml]="item.answer | ebSafeHtml"></div>
    </div>
  </eb-accordion-panel>
</div>
