import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EbButtonSize } from 'libs/ui-buttons/src/lib/button/button.component';
import { HeartType } from 'libs/ui-heart-button/src/lib/heart-button/heart-button.component';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { IFanAlertParams } from '../../../rest-api/models/fan-alert-request.model';
import { IShareData } from '../share-button/share-data.interface';

@Component({
    selector: 'eb-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'block' },
})
export class EbPageHeaderComponent implements OnInit {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _deviceService = inject(DeviceService);
    private _destroyRef = inject(DestroyRef);

    isLargeScreen = true;
    buttonSize: EbButtonSize = 'default';
    buttonisGhost = false;
    isMobile = true;

    @Input() isLoading = false;
    @Input() shareData!: IShareData;
    @Input() heartType!: HeartType;
    @Input() heartTotal?: number;
    @Input() heartId?: string;
    @Input() fanAlertParams!: IFanAlertParams;

    @Input() shareButtonVisible = true;
    @Input() heartButtonVisible = true;
    @Input() bellButtonVisible = true;
    @Input() buttonsRow = false;
    @Input() isTitlePagePromotion: boolean | undefined = false;
    @Output() readonly likeClicked: EventEmitter<undefined> = new EventEmitter<undefined>();

    @HostBinding('class.eb-page-header') protected get isPageHeader(): boolean {
        return true;
    }

    public ngOnInit(): void {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            this.isLargeScreen = !isMobile;
            this.buttonSize = this.isLargeScreen ? 'large' : 'default';
            this.buttonisGhost = this.isLargeScreen;

            if (isMobile != this.isMobile) {
                this.isMobile = isMobile;
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    protected onLikeClick(): void {
        if (this.likeClicked.observed) {
            this.likeClicked.emit();
        }
    }
}
