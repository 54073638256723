import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EmbeddedViewRef,
    EventEmitter,
    HostBinding,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { UiDialogConfig } from '../dialog-config.model';

@Component({
    selector: 'eb-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbDialogContainerComponent<T = undefined> extends BasePortalOutlet {
    public config = inject(UiDialogConfig<any>);

    readonly closing = new EventEmitter<T>();

    @HostBinding('class.eb-dialog-wrap') protected get isContainer(): boolean {
        return true;
    }

    @ViewChild(CdkPortalOutlet, { static: true }) readonly portalOutlet!: CdkPortalOutlet;

    constructor() {
        super();
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    public attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        if (this.portalOutlet.hasAttached()) {
            throw Error('Attempting to attach modal content after content is already attached');
        }

        return this.portalOutlet.attachComponentPortal(portal);
    }

    public attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        if (this.portalOutlet.hasAttached()) {
            throw Error('Attempting to attach modal content after content is already attached');
        }

        return this.portalOutlet.attachTemplatePortal(portal);
    }

    public hide(result?: T): void {
        if (this.closing.observed) {
            this.closing.emit(result);
        }
    }
}
