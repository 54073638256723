import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TitleEvent } from '../../../rest-api/models/title-page.model';
import { TitleEventMicrodataMapper } from '../title-event-list/title-event-microdata.mapper';

@Component({
    selector: 'eb-title-event-calendar-item',
    templateUrl: './title-event-calendar-item.component.html',
    styleUrls: ['./title-event-calendar-item.component.less'],
})
export class TitleEventCalendarItemComponent implements OnInit {
    @Input({ required: true }) titleEvent!: TitleEvent;
    @Input({ required: true }) artists: string[] = [];

    @Input() addMicroData = true;
    @Input() microDescription: string | undefined;
    @Input() titleUrl: string | undefined;
    @Output() readonly redirectToShop = new EventEmitter<unknown>();
    eventJson: Record<string, any> | null = null;

    public ngOnInit(): void {
        if (this.addMicroData) {
            this.eventJson = TitleEventMicrodataMapper.mapToJsonLd(
                this.titleEvent,
                this.microDescription,
                this.artists,
                this.titleUrl,
            );
        }
    }

    protected emitRedirectToShop(): void {
        if (this.redirectToShop.observed) {
            this.redirectToShop.emit();
        }
    }
}
