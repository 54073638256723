import { Directive, ElementRef, HostBinding, HostListener, Input, inject } from '@angular/core';
import { EbOverlayPanelComponent } from './overlay-panel/overlay-panel.component';

@Directive({
    selector: '[ebOverlay]',
})
export class EbOverlayDirective<T> {
    private readonly _elementRef = inject(ElementRef);

    @Input('ebOverlay') overlayPanel!: EbOverlayPanelComponent<T>;
    @Input('ebOverlayData') overlayPanelData: T | null = null;
    @Input() ebOverlayOrigin: ElementRef | null = null;
    @Input() ebOverlayGlobal = false;

    @HostBinding('class.eb-overlay') protected get isOverlay(): boolean {
        return true;
    }

    @HostListener('click') protected show(): void {
        if (this.ebOverlayGlobal) {
            this.overlayPanel.global();
        } else {
            this.overlayPanel.show(this.ebOverlayOrigin || this._elementRef, this.overlayPanelData);
        }
    }
}
