import { IBreadcrumb } from '@e-bilet/ui-breadcrumbs';
import { ArrayHelper } from '../helpers/array.helper';
import { TitlePage } from '../rest-api/models/title-page.model';

export class TitlePageMapper {
    static mapToBreadcrumbs(titlePage: TitlePage): IBreadcrumb[] {
        const breadcrumbs: IBreadcrumb[] = [];

        breadcrumbs.push({
            label: titlePage.category.name,
            urlParts: [titlePage.category.slug],
        });

        if (titlePage.category.subcategory) {
            breadcrumbs.push({
                label: titlePage.category.subcategory.name,
                urlParts: [titlePage.category.slug, titlePage.category.subcategory.slug],
            });
        }

        breadcrumbs.push({
            label: titlePage.title,
            urlParts: [...ArrayHelper.flatMap(breadcrumbs, (b) => b.urlParts), titlePage.slug],
        });

        return breadcrumbs;
    }
}
