import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Input, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { AffixService } from './affix/affix.service';

const MAX_BLINKING_INTERVAL = 80;

@Directive({
    selector: '[ebSectionIntersect]',
})
export class SectionIntersectDirective implements OnInit, OnDestroy {
    private readonly _elRef = inject(ElementRef);
    private readonly _affixService = inject(AffixService);
    private readonly _platformId = inject(PLATFORM_ID);

    private _intersectionObserver: IntersectionObserver | undefined;
    private _lastIntersectionTime = 0;
    private _lastIntersectionScrollY = 0;

    @Input() intersectEnabled = true;

    public ngOnInit(): void {
        if (this.intersectEnabled) {
            this._createIntersectionObserver();
        }
    }

    public ngOnDestroy(): void {
        this._destroyIntersectionObserver();
    }

    private _createIntersectionObserver(): void {
        if (isPlatformBrowser(this._platformId)) {
            const options = {
                rootMargin: '-100px 0px -200px 0px',
                threshold: [0, 0.1, 0.5],
            };

            this._intersectionObserver = new IntersectionObserver((entries) => {
                let intersectionTime!: number;
                let isBlinking!: boolean;
                let isTopEdgeOfSectionVisible!: boolean;
                const lastSectionIntersecting = this._affixService.sectionIntersecting;
                const scrollingDown = window.scrollY > this._lastIntersectionScrollY;
                const scrollYChanged = this._lastIntersectionScrollY !== window.scrollY;

                const sectionIntersecting = entries.some((e) => {
                    intersectionTime = e.time;
                    isBlinking = intersectionTime - this._lastIntersectionTime < MAX_BLINKING_INTERVAL;
                    isTopEdgeOfSectionVisible = e.boundingClientRect.y > 0;
                    return e.isIntersecting;
                });

                if (!isBlinking) {
                    this._affixService.sectionIntersecting = sectionIntersecting;
                } else if (isBlinking && scrollYChanged) {
                    this._affixService.sectionIntersecting = scrollingDown === isTopEdgeOfSectionVisible;
                }

                this._lastIntersectionScrollY = window.scrollY;
                this._lastIntersectionTime = intersectionTime;
            }, options);

            this._intersectionObserver.observe(this._elRef.nativeElement);
        }
    }

    private _destroyIntersectionObserver(): void {
        if (this._intersectionObserver) {
            this._intersectionObserver.disconnect();
        }
    }
}
