import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    PLATFORM_ID,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, filter } from 'rxjs';
import { ArtistPageRestService } from '../../../../../apps/portal/src/app/rest-api/artist-page-rest.service';
import { PlacePageRestService } from '../../../../../apps/portal/src/app/rest-api/place-page-rest.service';
import { TitlePageRestService } from '../../../../../apps/portal/src/app/rest-api/title-page-rest.service';
import { HeartService } from '../../../../../apps/portal/src/app/services/heart.service';
import { EbButtonSize, EbButtonType } from '../../../../ui-buttons/src/lib/button/button.component';

export type HeartType = 'artist' | 'title' | 'place';

@Component({
    selector: 'eb-heart-button',
    templateUrl: './heart-button.component.html',
    styleUrls: ['./heart-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeartButtonComponent implements OnInit {
    private readonly _placePageRestService = inject(PlacePageRestService);
    private readonly _titlePageRestService = inject(TitlePageRestService);
    private readonly _artistPageRestService = inject(ArtistPageRestService);
    private readonly _heartService = inject(HeartService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly _platformId = inject(PLATFORM_ID);

    private _likes: number | undefined;
    private _destroyRef = inject(DestroyRef);

    @Input({ required: true }) public set likes(likes: number | undefined) {
        this._likes = likes;
        this.likesCount = this._getLikesCount();
        if (isPlatformBrowser(this._platformId)) {
            this.itemStorageKey = `heart_${this.heartType}_${this.id}`;
            this._setLike(this.itemStorageKey);
        }
    }

    public get likes(): number | undefined {
        return this._likes;
    }

    @Input() ebSize: EbButtonSize = 'default';
    @Input() ebType: EbButtonType = 'secondary';
    @Input() ebGhost = true;
    @Input() whiteText = false;
    @Input() id: string | undefined;
    @Input() heartType!: HeartType;
    @Input() strokeWidth: number | undefined = undefined;

    @Output() readonly likeClicked: EventEmitter<undefined> = new EventEmitter<undefined>();

    liked = false;
    likesCount = '';
    itemStorageKey = '';

    public ngOnInit(): void {
        if (isPlatformBrowser(this._platformId)) {
            this.itemStorageKey = `heart_${this.heartType}_${this.id}`;
            this._setLike(this.itemStorageKey);

            this._heartService.heartLiked$
                .pipe(
                    filter((key) => key === this.itemStorageKey),
                    takeUntilDestroyed(this._destroyRef),
                )
                .subscribe(() => {
                    this._setLike(this.itemStorageKey);
                });
        }
    }

    private _getLikesCount(): string {
        if (!this.likes || this.likes < 10) {
            return '';
        } else if (this.likes < 99999) {
            return `${this.likes}`;
        } else if (this.likes < 999999) {
            const count = Math.floor(this.likes / 1000);
            return `${count}K`;
        } else {
            const count = Math.floor(this.likes / 1000000);
            return `${count}M`;
        }
    }

    protected onHeartClick(): void {
        if (this.liked) {
            return;
        }
        if (this.likeClicked.observed) {
            this.likeClicked.emit();
        }
        this._getRest()
            ?.pipe(takeUntilDestroyed(this._destroyRef))
            ?.subscribe(() => {
                this.likes = (this.likes || 0) + 1;
                this.likesCount = this._getLikesCount();
                this.liked = true;

                if (isPlatformBrowser(this._platformId)) {
                    sessionStorage.setItem(this.itemStorageKey, 'true');
                    this._heartService.likeHeart(this.itemStorageKey);
                }

                this.cdr.detectChanges();
            });
    }

    private _setLike(key: string): void {
        this.likesCount = this._getLikesCount();
        this.liked = sessionStorage.getItem(key) === 'true';
        this.cdr.detectChanges();
    }

    private _getRest(): Observable<object> | null | undefined {
        if (!this.id) {
            return;
        }

        switch (this.heartType) {
            case 'artist':
                return this._artistPageRestService.setHeart(this.id);
            case 'title':
                return this._titlePageRestService.setHeart(this.id);
            case 'place':
                return this._placePageRestService.setHeart(this.id);
            default:
                return null;
        }
    }
}
