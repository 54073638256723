import { ITreeOption } from './tree-option.interface';

export class TreeSelectMapper {
    static map<T>(items: Readonly<T[]>, labelGetter: (i: T) => string, subitemsGetter: (i: T) => T[]) {
        return (items || []).map((i) => {
            const option: ITreeOption<T> = {
                label: labelGetter(i),
                value: i,
                items: [],
            };
            option.items = subitemsGetter(i).map((subitem) => ({
                label: labelGetter(subitem),
                value: subitem,
                parent: option,
            }));
            return option;
        });
    }
}
