import { Directive, ElementRef, HostBinding, Input, inject } from '@angular/core';

@Directive({
    selector: '[ebCarouselItem]',
})
export class EbCarouselItemDirective {
    public readonly elementRef = inject(ElementRef);

    @Input() ebCarouselItemId = '';

    @HostBinding('class.eb-carousel-item') protected get isItem(): boolean {
        return true;
    }
}
