<div class="no-event-info align-items-center">
  <div>
    <p class="h5 mb-2">{{ header | transloco }}</p>
    <div>{{ eventInfo | transloco }}.</div>
  </div>
  <eb-fan-alert
    [fanAlertParams]="fanAlertParams"
    observedButtonColor="purple"
    unobservedButtonType="primary"
    observedButtonType="primary"
    label="shared.no-event-info.observe"
    [labelVisible]="true"
  >
  </eb-fan-alert>
</div>
