import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { FanAlertTypes, IFanAlertParams } from 'apps/portal/src/app/rest-api/models/fan-alert-request.model';
import {
    TitleEventAvailabilities,
    TitleEventAvailabilityData,
} from 'apps/portal/src/app/rest-api/models/title-event-availability.models';
import { TitleEvent } from '../../../../../apps/portal/src/app/rest-api/models/title-page.model';
import { TitleEventMicrodataMapper } from '../../../../../apps/portal/src/app/shared/components/title-event-list/title-event-microdata.mapper';
import { TitleEventAvailabilityStoreService } from '../../../../../apps/portal/src/app/stores/title-event-availability-store.service';
import { BaseVirtualPageView } from '../../../../gtm/src/lib/virtual-page-view';

@Component({
    selector: 'eb-title-event-bar',
    templateUrl: './title-event-bar.component.html',
    styleUrls: ['./title-event-bar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'block' },
})
export class EbTitleEventBarComponent implements OnInit {
    private _destroyRef = inject(DestroyRef);
    private readonly _translocoService = inject(TranslocoService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _titleEventAvailabilityStoreService = inject(TitleEventAvailabilityStoreService);

    @Input() titleEvent: TitleEvent | undefined;
    @Input() isThumbnailHidden = false;
    @Input() skeletonView = false;

    @Input() addMicroData = false;
    @Input() partnerId?: string;
    @Input() tokenCheckSum: string | undefined;
    @Input() virtualPageView: BaseVirtualPageView | undefined;
    @Input() microDescription: string | undefined;
    @Input() artists: string[] = [];
    @Input() titleUrl: string | undefined;
    @Input() isTitlePage = false;
    @Input() showFanAlert = true;

    eventJson: Record<string, any> | null = null;

    titleEventAvailability: TitleEventAvailabilities = TitleEventAvailabilities.UNDEFINED;
    titleEventIsUnavailable = false;
    minPrice: number | undefined;
    isPromoted: boolean | undefined;
    freeSeatsCount: string | undefined;
    activeLanguage = '';
    fanAlertParams!: IFanAlertParams;

    readonly unavailabilityStates = [TitleEventAvailabilities.SOLD_OUT, TitleEventAvailabilities.CURRENTLY_UNAVAILABLE];
    readonly TitleEventAvailability = TitleEventAvailabilities;

    public ngOnInit(): void {
        if (this.addMicroData && !this.skeletonView) {
            this.eventJson = TitleEventMicrodataMapper.mapToJsonLd(
                this.titleEvent,
                this.microDescription,
                this.artists,
                this.titleUrl,
            );
        }
        this.activeLanguage = this._translocoService.getActiveLang();
    }

    protected checkAvailability(): void {
        if (this.titleEvent) {
            this._titleEventAvailabilityStoreService
                .find$(this.titleEvent.id)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((availabilityData: TitleEventAvailabilityData) => {
                    this.titleEventAvailability = availabilityData.titleEventAvailability;
                    this._checkUnavailability();

                    if (!this.titleEventIsUnavailable) {
                        this.minPrice = availabilityData.minPrice;
                        this.isPromoted = availabilityData.isPromoted;
                        this.freeSeatsCount = availabilityData.freeSeatsCount;
                    }
                    this._changeDetectorRef.detectChanges();
                });
            this._titleEventAvailabilityStoreService
                .load({
                    partnerId: this.partnerId,
                    events: [this.titleEvent],
                    cacheLong: !!this.titleEvent.webShopMaxDelay,
                })
                .pipe(takeUntilDestroyed(this._destroyRef))
                ?.subscribe();

            this.fanAlertParams = {
                partnerId: this.partnerId,
                objectId: this.titleEvent.titleId as string,
                name: this.titleEvent.title,
                fanAlertType: FanAlertTypes.TITLE,
            };
        }
    }

    private _checkUnavailability(): void {
        this.titleEventIsUnavailable = this.unavailabilityStates.includes(this.titleEventAvailability);
    }

    protected readonly TitleEventAvailabilities = TitleEventAvailabilities;
}
