/* eslint-disable @typescript-eslint/no-empty-function */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { ITreeOption } from '../tree-select/tree-option.interface';

@Component({
    selector: 'eb-tree-select-list',
    templateUrl: './tree-select-list.component.html',
    styleUrls: ['./tree-select-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbTreeSelectListComponent), multi: true }],
})
export class EbTreeSelectListComponent<T> implements ControlValueAccessor {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private readonly _options$ = new ReplaySubject<ITreeOption<T>[]>(1);
    private _availableOptions: ITreeOption<T>[] = [];
    private _options: ITreeOption<T>[] = [];
    private _value: ITreeOption<T> | null = null;
    private _root: ITreeOption<T> | null = null;

    protected get root(): ITreeOption<T> | null {
        return this._root;
    }

    protected get value(): ITreeOption<T> | null {
        return this._value;
    }

    protected get availableOptions(): ITreeOption<T>[] {
        return this._availableOptions;
    }

    @Input({ required: true }) public set options(items: ITreeOption<T>[]) {
        this._options$.next(items);
    }

    public get options(): ITreeOption<T>[] {
        return this._options;
    }

    @Input() allFromCategory = '';

    @Output() readonly selectedChanged = new EventEmitter<ITreeOption<T> | null>();

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private _onTouched = () => {};
    private _onChange: (value: ITreeOption<T> | null) => void = () => {};

    constructor() {
        this._options$.pipe(takeUntilDestroyed()).subscribe((options) => {
            this._options = options;
            this._availableOptions = options;
        });
    }

    public writeValue(obj: ITreeOption<T>): void {
        if (this._value !== obj) {
            this._value = obj;
            this._changeDetectorRef.markForCheck();
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    protected touched(event: TouchEvent, item: ITreeOption<T>): void {
        event.preventDefault();
        if (item.items?.length) {
            this._show(item);
        } else {
            this._toggle(item);
        }
    }

    protected clicked(item: ITreeOption<T>): void {
        if (item.items?.length) {
            this._show(item);
        } else {
            this._toggle(item);
        }
    }

    protected goBack(item: ITreeOption<T>): void {
        if (item.parent) {
            this._show(item.parent);
        } else {
            this._root = null;
            this._availableOptions = this._options;
        }
    }

    protected select(item: ITreeOption<T> | null): void {
        if (item !== this._value) {
            this._value = item;
            if (this.selectedChanged.observed) {
                this.selectedChanged.emit(this._value);
            }
            this._onChange(this._value);
        }
    }

    private _toggle(item: ITreeOption<T> | null): void {
        if (this._value === item) {
            this.select(null);
        } else {
            this.select(item);
        }
    }

    protected clear(): void {
        this.select(null);
    }

    private _show(item: ITreeOption<T>): void {
        this._root = item;
        this._availableOptions = item.items || [];
    }
}
