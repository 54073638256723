<div class="eb-footer" [style]="branding?.footerColor ? 'background-color: ' + branding?.footerColor : ''">
  <div class="eb-footer__content" [ngClass]="{ 'eb-footer__content--no-border': branding?.footerColor }">
    <div class="eb-divider eb-size--huge" *ngIf="!branding?.footerColor"></div>

    <ng-container *ngIf="branding; then brandingView; else defaultView" />
  </div>
  <eb-scroll-button />
</div>

<ng-template #defaultView>
  <div class="eb-footer-section-container" *ngIf="footer">
    <div class="eb-footer-section" *ngFor="let section of footer.items">
      <div class="eb-footer-section__name">{{ section.name }}</div>
      <div class="eb-footer-section__item-container">
        <div class="eb-footer-section__item" *ngFor="let link of section.items">
          <ng-container *ngIf="link.isUrlExternal; then externalLink; else internalLink" />
          <ng-template #externalLink>
            <a [href]="link.url | ebSafeUrl" rel="nofollow">{{ link.name }}</a>
          </ng-template>
          <ng-template #internalLink>
            <a [routerLink]="generateInternalLink(link.url)" [ngClass]="{ 'font-bold': link.isBolded }">{{ link.name }}</a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="footer" class="eb-divider eb-size--huge"></div>

  <div class="eb-footer__copyright-and-social">
    <span class="eb-footer__copyright-and-powered">
      <span class="flex align-items-center">Copyright {{ year }} by eBilet</span>
    </span>
    <div>
      <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'newsletter']">
        <svg-icon fontSize="16px" key="envelope" />
        Newsletter
      </a>

      <a [href]="socialUrls.facebook" rel="nofollow">
        <svg-icon fontSize="16px" key="brand-facebook" />
        Facebook
      </a>

      <a [href]="socialUrls.instagram" rel="nofollow">
        <svg-icon fontSize="16px" key="brand-instagram" />
        Instagram
      </a>

      <a [href]="socialUrls.youtube" rel="nofollow">
        <svg-icon fontSize="16px" key="brand-youtube" />
        Youtube
      </a>

      <a [href]="socialUrls.tiktok" rel="nofollow">
        <svg-icon fontSize="16px" key="brand-tiktok" />
        TikTok
      </a>

      <a [href]="socialUrls.linkedin" rel="nofollow">
        <svg-icon fontSize="16px" key="brand-linkedin" />
        Linkedin
      </a>
    </div>
  </div>
</ng-template>

<ng-template #brandingView>
  <div class="eb-footer__copyright-and-social">
    <span class="eb-footer__copyright-and-powered">
      <a *ngIf="branding?.footerImage" (click)="redirect()">
        <img [src]="branding?.footerImage | media" alt="footer" class="eb-footer__branding-img" />
      </a>
    </span>
    <div>
      <a *ngFor="let footerLink of branding?.footerLinks" [href]="footerLink.url">
        {{ footerLink.anchor }}
      </a>
    </div>
  </div>
</ng-template>
