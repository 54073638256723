import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GtmService } from '@e-bilet/gtm';
import { ICarouselBrandingData } from 'apps/portal/src/app/rest-api/models/title-page.model';
import { InternationalizationService } from 'apps/portal/src/app/services/internationalization.service';
import { BRANDING_LOCAL_STORAGE_PREFIX } from 'apps/portal/src/app/title-page/title-page.component';
import { TitleEventMicrodataMapper } from '../../../../../apps/portal/src/app/shared/components/title-event-list/title-event-microdata.mapper';
import { nameToId } from '../../../../../apps/portal/src/consts/name-to-id';
import { Title } from './../../../../../apps/portal/src/app/rest-api/models/title.model';

export type TitleCardSize = 'small' | 'normal';

@Component({
    selector: 'eb-title-card',
    templateUrl: './title-card.component.html',
    styleUrls: ['./title-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleCardComponent implements OnInit {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _router = inject(Router);
    private readonly _gtmService = inject(GtmService);
    public readonly activatedRoute = inject(ActivatedRoute);
    public readonly i18nService = inject(InternationalizationService);

    private _isActive = false;
    private _listName: string | undefined;
    private _promotionNameForGA4: string | undefined;
    listId: string | undefined;
    promotionId: string | undefined;
    titleJson: Record<string, any> | null = null;

    protected get isActive(): boolean {
        return this._isActive;
    }

    protected get image(): string {
        return this.cardSize === 'small' ? this.title.imageLandscape : this.title.imagePortrait;
    }

    @Input({ required: true }) public set listName(value: string | undefined) {
        this._listName = value;
        this.listId = nameToId(value);
    }

    public get listName(): string | undefined {
        return this._listName;
    }

    @Input({ required: true }) title!: Title;
    @Input() withOverlay = false;
    @Input() showTimer = false;
    @Input() cardSize: TitleCardSize = 'normal';
    @Input() addMicroData = false;
    @Input() index: number | undefined;
    @Input() isPromotion = false;
    @Input() promotionName = '';

    public ngOnInit(): void {
        if (this.addMicroData) {
            this.titleJson = TitleEventMicrodataMapper.mapFromTitle(this.title);
        }

        this._promotionNameForGA4 = this._gtmService.getPromotionNameForGA4(
            this.promotionName,
            this.title.manualOrder,
            this.title.isPromotion,
            this.isPromotion,
        );
        this.promotionId = nameToId(this._promotionNameForGA4);
    }

    public onAnchorTouchStart(event: TouchEvent): void {
        if (event.cancelable && this.withOverlay) {
            event.preventDefault();
        }
    }

    protected onClick(e: Event, preventDefault: boolean, preventRedirect: boolean): void {
        if (preventDefault) {
            e.preventDefault();
        }
        this._setBrandingData();
        this._selectItem(preventRedirect);
    }

    public onTouchStart(event: TouchEvent): void {
        if (!event.cancelBubble) {
            this._isActive = !this._isActive;
        }
    }

    public onTouchButton(event: TouchEvent): void {
        event.cancelBubble = true;
        event.stopPropagation();
    }

    protected onHeartClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private _selectItem(preventRedirect: boolean): void {
        this._gtmService.selectItem(
            this.title,
            this.listName,
            this.listId,
            this.index,
            this._promotionNameForGA4,
            this.promotionId,
        );

        if (this.title.isPromotion || this.title.manualOrder || this.isPromotion) {
            this._gtmService.selectPromotion(
                this.title,
                this.listName,
                this.listId,
                this._promotionNameForGA4,
                this.promotionId,
                this.index,
            );
        }

        if (!preventRedirect) {
            if (this.title.linkTo) {
                if (
                    isPlatformBrowser(this._platformId) &&
                    (this.title.linkTo.indexOf(window.location.origin) < 0 ||
                        this.title.linkTo.includes('ebilet.pl/now/'))
                ) {
                    window.location.href = this.title.linkTo;
                } else {
                    const url = new URL(this.title.linkTo);
                    this._router.navigateByUrl(url.pathname + url.search);
                }
            } else {
                this._router.navigate([
                    this.i18nService.isEnglishActive ? 'en' : '',
                    this.title.categoryName,
                    this.title.subcategoryName,
                    this.title.slug,
                ]);
            }
        }
    }

    private _setBrandingData(): void {
        const brandingData: ICarouselBrandingData = {
            listName: this.listName,
            index: this.index,
            ...(this._promotionNameForGA4 && { promotionName: this._promotionNameForGA4 }),
            ...(this.promotionId && { promotionId: this.promotionId }),
        };

        if (isPlatformBrowser(this._platformId)) {
            localStorage.setItem(BRANDING_LOCAL_STORAGE_PREFIX + this.title.id, JSON.stringify(brandingData));
        }
    }

    protected addToWishlist(): void {
        this._gtmService.addToWishlist(
            this._gtmService.mapTitleToGa4Item(this.title, this.listName, this.listId, this.index),
        );
    }
}
