<button *ngIf="!isGridVisible" eb-button ebSize="small" ebType="secondary" (click)="overlayGallery.show()" class="width-100"><svg-icon class="icon--md-relative" size="mde" key="gallery" /> {{'shared.gallery.galleryBtn' | transloco}}</button>

<div *ngIf="isGridVisible" class="eb-gallery-grid eb-grid-3" [ngClass]="{'eb-skeleton': isLoading}">
  <div *ngFor="let item of gridItems; let last = last; let i = index" [ngClass]="{'eb-img-frame': item.type === titleGalleryItemTypes.IMAGE}" class="eb-gallery-grid-item eb-col-start-{{item.column}} eb-col-span-{{item.columnSpan}} eb-row-start-{{item.row}}  eb-row-span-{{item.rowSpan}}">
    <ng-template [ngTemplateOutlet]="galleryItem" [ngTemplateOutletContext]="{ type: item.type, url: item.smallUrl, id: item.url, video: item.url, i: i }" />
    <div class="more-items-overlay" *ngIf="hiddenItemsCount > 0 && lastGridItem === item" (click)="overlayGallery.show(); overlayGalleryCarousel.goTo(item.url);">
      <button (click)="$event.stopPropagation(); overlayGallery.show(); overlayGalleryCarousel.goTo(item.url);" eb-button ebType="secondary" ebSize="large" [ebOnlyIcon]="true" >
        +{{hiddenItemsCount}}
      </button>
    </div>
  </div>
</div>

<eb-drawer #overlayGallery [mask]="true" [closable]="true" [height]="'100%'" wrapClassName="eb-gallery-dialog" placement="bottom" [bodyStyle]="{ 'display':'grid', 'place-content': 'center'}">
  <div class="eb-gallery-dialog_body">
    <eb-carousel #overlayGalleryCarousel ebGalleryKeyboardNavigation [drawer]="overlayGallery" [isGallery]="true">
      <div class="eb-gallery-item-frame" *ngFor="let item of galleryItems; let i = index" ebCarouselItem [ebCarouselItemId]="item.url">
        <ng-template  [ngTemplateOutlet]="galleryItem" [ngTemplateOutletContext]="{ type: item.type, url: item.url, id: item.url, video: item.url, i:i }" />
      </div>
    </eb-carousel>
  </div>
</eb-drawer>


<ng-template #galleryItem let-type="type" let-url="url" let-id="id" let-video="video" let-i="i">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="titleGalleryItemTypes.OTHER">
      <iframe [src]="url | ebSafeUrl" frameborder="0"></iframe>
    </ng-container>
    <ng-container *ngSwitchCase="titleGalleryItemTypes.VIDEO">
      <div [ngClass]="isMobileGallery ? 'eb-video-container' : 'eb-full-height-video-container'">
        <iframe [src]="video | ebSafeUrl" class="video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="titleGalleryItemTypes.IMAGE">
      <img [src]="url | media" (click)="overlayGallery.show(); overlayGalleryCarousel.goTo(id);" [alt]="(parentPageTitle ? (parentPageTitle + ' - ') : '') + 'galeria: zdjęcie ' + (i + 1)" />
    </ng-container>
  </ng-container>
</ng-template>
