<button
  [attr.aria-label]="labels[brand]"
  eb-button
  [ebType]="ebType"
  [ebSize]="ebSize"
  [ebOnlyIcon]="true"
  [ngSwitch]="brand"
  (click)="openLink()"
>
  @switch (brand) {
    @case (1) {
      <svg-icon size="md" key="brand-facebook" />
    }
    @case (2) {
      <svg-icon size="md" key="brand-twitter" />
    }
    @case (3) {
      <svg-icon size="md" key="brand-instagram" />
    }
    @case (4) {
      <svg-icon size="md" key="brand-tiktok" />
    }
    @case (5) {
      <svg-icon size="md" key="link" />
    }
    @case (6) {
      <svg-icon size="md" key="brand-spotify" />
    }
    @case (7) {
      <svg-icon size="md" key="brand-youtube" />
    }
    @case (8) {
      <svg-icon size="md" key="brand-linkedin" />
    }
    @case (9) {
      <svg-icon size="md" key="www" />
    }
    @default {
      <svg-icon size="md" key="link" />
    }
  }
</button>
