@if (addMicroData && eventJson) {
  <eb-json-ld [type]="'event-data'" [id]="titleEvent.uniqueId" [json]="eventJson"/>
}
<div
  class="title-event-time-slot"
  ebIntersection
  [ebOnlyFirst]="true"
  (intersecting)="checkAvailability()"
  (click)="queueRedirectToShop()"
  [ngClass]="{
    'title-event-time-slot--disabled': disabled,
    'title-event-time-slot--has-warning': lastPlaces
  }"
>
  <ng-container [ngSwitch]="showQueue">
    <ng-container *ngSwitchCase="false">
      <div class="title-event-time-slot__warning" *ngIf="lastPlaces">{{ 'shared.title-event-time-slot.lastPlaces' | transloco }}</div>
      <h5>{{ titleEvent.date | date: 'HH:mm' : undefined : 'PL' }}</h5>
      <p *ngIf="minPrice | ebIsNumber" class="eb-size--tiny mb-0">
        {{ 'shared.title-event-time-slot.from' | transloco }} {{ minPrice | ebCurrency: 'PLN' }}
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <div class="title-event-time-slot__loader">
        <eb-spinner />
        <span class="eb-size--small mt-2">{{ 'shared.title-event-time-slot.waitAMinute' | transloco }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
