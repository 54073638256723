@if (addMicroData && titleJson) {
  <eb-json-ld [type]="'title'" [id]="title.id" [json]="titleJson"/>
}
<a
  class="title-card eb-img-frame-trigger"
  [ngClass]="{ 'title-card--active': isActive }"
  (auxclick)="onClick($event, false, true)"
  (click)="onClick($event, true, false)"
  [href]="
    title.linkTo ?? ([i18nService.isEnglishActive ? 'en' : '', title.categoryName, title.subcategoryName, title.slug] | ebArrayToUrlString)
  "
>
  <div
    class="title-card__img-frame eb-img-frame eb-img-frame--with-desc"
    [ngClass]="{ 'title-card__img-frame--small': cardSize === 'small' }"
  >
    <div class="title-card__img-frame_desc eb-img-frame_desc">
      <div class="title-card__img-frame_desc-content flex flex-column gap-3 h-full">
        <div class="title-card__fav mt-3 flex justify-content-end">
          <eb-heart-button
            [id]="title.id"
            [ebType]="'text'"
            [whiteText]="true"
            [heartType]="'title'"
            [likes]="title.hearts"
            [strokeWidth]="1"
            (click)="onHeartClick($event)"
            (likeClicked)="addToWishlist()"
          />
        </div>
        <ng-container *ngIf="withOverlay">
          <p class="title-card__description eb-size--tiny mb-0 mt-auto overflow-hidden">{{ title.metaDescription }}</p>
        </ng-container>
        <button eb-button ebType="primary" ebSize="small"><svg-icon key="ticket" /> {{ 'libs.title-bar.checkTickets' | transloco }}</button>
      </div>
    </div>
    <img class="title-card__img w-full h-full" [src]="image | media" [alt]="title.title" />
  </div>
  <div class="title-card__desc py-2 px-0">
    <p *ngIf="title.isPromotion" class="eb-promotion-label">{{ 'promotion.promotion' | transloco }}</p>
    <p class="h6 title-card__title mb-1">{{ title.title }}</p>
    <p *ngIf="!title.isEventDateHidden" class="title-card__date eb-size--tiny mb-0">{{ title.dateFrom | ebDate: title.dateTo }}</p>
    <p class="title-card__place eb-size--tiny">{{ title.place }}</p>
    <p *ngIf="showTimer && !title.isEventDateHidden" class="title-card__timer"><eb-timer [endDate]="title.sellDateTo" /></p>
  </div>
</a>
