<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="close()"
  (overlayOutsideClick)="close()"
>
  <div class="eb-overlay-panel" [ngClass]="overlayStyle">
    <ng-container *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ $implicit: data }" />
  </div>
</ng-template>
