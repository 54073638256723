@if (addMicroData && eventJson) {
  <eb-json-ld [type]="'event-data'" [id]="titleEvent.uniqueId" [json]="eventJson"/>
}
<div class="eb-title-event-calendar__item">
  <div class="mb-2">
    <div class="eb-title-event-calendar__item__title">{{titleEvent.city}}</div>
    <div class="eb-title-event-calendar__item__description">{{titleEvent.placeName}}</div>
  </div>
  <button *ngIf="!(titleEvent.soldOut || titleEvent.currentlyUnavailable)" eb-button ebType="primary" ebSize="small" (click)="emitRedirectToShop()">{{'shared.title-event-calendar-item.buyTicket' | transloco}}</button>
  <button *ngIf="titleEvent.currentlyUnavailable" eb-button ebType="primary" [ebGhost]="true" ebSize="small"><svg-icon key="bell" /> {{'shared.title-event-calendar-item.notifyMe' | transloco}}</button>
  <div *ngIf="titleEvent.soldOut">
    <div class="eb-title-event-calendar__item__sold-out">{{'shared.title-event-calendar-item.soldOut' | transloco}}</div>
  </div>
</div>
