import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbOverlayPanelComponent } from './overlay-panel/overlay-panel.component';
import { EbOverlayDirective } from './overlay.directive';

@NgModule({
    imports: [CommonModule, OverlayModule],
    declarations: [EbOverlayPanelComponent, EbOverlayDirective],
    exports: [EbOverlayPanelComponent, EbOverlayDirective],
})
export class UiOverlayModule {}
