import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiProgressBarModule } from '@e-bilet/ui-progress-bar';
import { EbBuyTicketButtonComponent } from './buy-ticket-button/eb-buy-ticket-button.component';

@NgModule({
    imports: [CommonModule, UiProgressBarModule, UiButtonsModule],
    declarations: [EbBuyTicketButtonComponent],
    exports: [EbBuyTicketButtonComponent],
})
export class UiBuyTicketButtonModule {}
