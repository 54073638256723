import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { IDontMissTile } from '../../../rest-api/models/dont-miss-tile.interface';
import { InternationalizationService } from '../../../services/internationalization.service';

@Component({
    selector: 'eb-dont-miss',
    templateUrl: './dont-miss.component.html',
    styleUrls: ['./dont-miss.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DontMissComponent {
    private readonly _i18nService = inject(InternationalizationService);

    private _tiles: IDontMissTile[] = [];

    @Input({ required: true }) public set tiles(value: IDontMissTile[]) {
        value.forEach((tile) => (tile.routerLink = this._generateTileRouterLink(tile.link)));
        this._tiles = value;
    }

    get tiles(): IDontMissTile[] {
        return this._tiles;
    }

    private _generateTileRouterLink(link: string | undefined): string[] {
        if (!link) {
            return [];
        }
        if (link.charAt(0) === '/') {
            link = link.substring(1);
        }
        return [this._i18nService.isEnglishActive ? '/en' : '/', ...link.split('/')];
    }
}
