import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ISelectOptionGroup } from '@e-bilet/ui-select';
import { ITreeOption, TreeSelectMapper } from '@e-bilet/ui-tree-select';
import { Province } from '../../../rest-api/models/province.model';
import { ISearchPageQueryParams } from '../../../search-page/search-page-query-params.interface';
import { SearchOptionGroup } from '../search-select/search-select-value.interface';
import { EbSearchSelectComponent } from '../search-select/search-select.component';
import { ISearchInputValue } from './search-input-value.interface';

@Component({
    selector: 'eb-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.less'],
    host: { class: 'block' },
})
export class EbSearchInputComponent {
    private _formBuilder = inject(FormBuilder);
    private _router = inject(Router);

    private _locationTree: ITreeOption<Province>[] = [];
    private _value: ISearchInputValue | null = null;
    private _provicens: Province[] = [];

    @Input({ required: true }) options: ISelectOptionGroup<SearchOptionGroup, any>[] = [];
    @Input({ required: true }) public set provinces(provinces: Province[]) {
        this._provicens = provinces;
        this._locationTree = TreeSelectMapper.map(
            provinces,
            (p) => p.name,
            (p) => p.cities as Province[],
        );
    }

    @Input() loading = false;

    @Output() readonly searching = new EventEmitter<ISearchInputValue>();

    @ViewChild(EbSearchSelectComponent, { static: true }) readonly ebSearchSelectComponent!: EbSearchSelectComponent;

    protected get locationTree(): ITreeOption<Province>[] {
        return this._locationTree;
    }

    searchForm: FormGroup;

    constructor() {
        this.searchForm = this._formBuilder.group({
            dateRange: [],
            location: [],
        });
    }

    protected submit(): void {
        this._router.navigate(['./wydarzenia'], { queryParams: this._mapToSearchPageQueryParams() });
    }

    private _mapToSearchPageQueryParams(): ISearchPageQueryParams | null {
        const lastRequest = this.ebSearchSelectComponent.lastRequest;
        if (lastRequest) {
            const queryParams: ISearchPageQueryParams = {
                text: lastRequest.text || undefined,
                dateFrom: lastRequest.dateFrom,
                dateTo: lastRequest.dateTo,
                province: lastRequest.province,
                city: lastRequest.city,
            };

            return queryParams;
        } else {
            return null;
        }
    }
}
