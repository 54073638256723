import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IPageParam } from '../infrastructure/page-params-manager.class';
import { ParamsHelper } from '../helpers/params.helper';
import { IFAQ } from './models/faq.model';
import { IPlacePageInfo } from './models/place-page-info.model';
import { IPlacePage } from './models/place-page.interface';
import { Artist, Place, TitleEvent } from './models/title-page.model';
import { Title } from './models/title.model';

@Injectable({
    providedIn: 'root',
})
export class PlacePageRestService {
    private readonly _httpClient = inject(HttpClient);

    private readonly _baseUrl = `${environment.portalApi}/Places`;

    public get(slug: string): Observable<IPlacePage> {
        return this._httpClient.get<IPlacePage>(`${this._baseUrl}/${slug}`);
    }

    public getAll(pageParams: IPageParam): Observable<Place[]> {
        return this._httpClient.get<Place[]>(`${this._baseUrl}/places`, {
            params: ParamsHelper.preparePageParams(pageParams.size, pageParams.top),
        });
    }

    public getEvents(placeId: string, pageParams?: IPageParam | null): Observable<TitleEvent[]> {
        let params;
        if (pageParams) {
            params = ParamsHelper.preparePageParams(pageParams.size, pageParams.top);
        }

        return this._httpClient.get<TitleEvent[]>(`${this._baseUrl}/${placeId}/events`, {
            params,
        });
    }

    public getTitles(placeId: string, pageParams: IPageParam): Observable<Title[]> {
        return this._httpClient.get<Title[]>(`${this._baseUrl}/${placeId}/titles`, {
            params: ParamsHelper.preparePageParams(pageParams.size, pageParams.top),
        });
    }

    public getPopularTitles(placeId: string): Observable<Title[]> {
        return this._httpClient.get<Title[]>(`${this._baseUrl}/${placeId}/popular-titles`);
    }

    public getPopularPlaces(placeId: string): Observable<Place[]> {
        return this._httpClient.get<Place[]>(`${this._baseUrl}/${placeId}/popular-places`);
    }

    public getPerformed(placeId: string): Observable<Artist[]> {
        return this._httpClient.get<Artist[]>(`${this._baseUrl}/${placeId}/performed`);
    }

    public getNearPlaces(placeId: string, latitude: string, longitude: string): Observable<Place[]> {
        let params = new HttpParams();
        params = params.append('Id', placeId);
        params = params.append('Size', 20);
        params = params.append('Top', 0);
        params = params.append('Localization.Latitude', latitude);
        params = params.append('Localization.Longitude', longitude);

        return this._httpClient.get<Place[]>(`${this._baseUrl}/near`, {
            params,
        });
    }

    public getFAQ(placeId: string): Observable<IFAQ> {
        return this._httpClient.get<IFAQ>(`${this._baseUrl}/${placeId}/faq`);
    }

    public setHeart(placeId: string): Observable<object> {
        let params = new HttpParams();
        params = params.append('add', true);

        return this._httpClient.put(`${this._baseUrl}/${placeId}/Hearts`, {}, { params });
    }

    public getPlacePageInfo(): Observable<IPlacePageInfo> {
        return this._httpClient.get<IPlacePageInfo>(`${this._baseUrl}/page`);
    }

    public getSuggestedTitles(placeId: string, queryParams: Params, pageParams: IPageParam): Observable<Title[]> {
        const { userId } = queryParams;
        let params = new HttpParams();

        if (userId) {
            params = params.append('userId', userId);
        }
        params = ParamsHelper.preparePageParams(pageParams.size, pageParams.top, params);

        return this._httpClient.get<Title[]>(`${this._baseUrl}/${placeId}/suggested-titles`, { params });
    }

    public getHistoricalTitles(placeId: string, pageParams: IPageParam): Observable<Title[]> {
        return this._httpClient.get<Title[]>(`${this._baseUrl}/${placeId}/historical-titles`, {
            params: ParamsHelper.preparePageParams(pageParams.size, pageParams.top),
        });
    }
}
