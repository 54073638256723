import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Ga4ListNames } from '@e-bilet/gtm';
import { ITreeOption, TreeSelectMapper } from '@e-bilet/ui-tree-select';
import { AggregatedGa4EventsService } from 'libs/gtm/src/lib/aggregated-ga4-events.service';
import { tap } from 'rxjs';
import { Category, ITitleOnTime } from '../../../rest-api/models/category.model';
import { City } from '../../../rest-api/models/province.model';
import { Title } from '../../../rest-api/models/title.model';
import { InternationalizationService } from '../../../services/internationalization.service';
import { CategoryStoreService } from '../../../stores/category-store.service';
import { CityStoreService } from '../../../stores/city-store.service';
import { OnTimeStoreService } from '../../../stores/on-time-store.service';
import { Article } from '../../../rest-api/models/article.interface';
import { CreativeContentStoreService } from '../../../stores/creative-content-store.service.ts.service';

@Component({
    selector: 'eb-category-mega-menu',
    templateUrl: './category-mega-menu.component.html',
    styleUrls: ['./category-mega-menu.component.less'],
})
export class EbCategoryMegaMenuComponent implements OnInit, OnChanges {
    private _platformId = inject(PLATFORM_ID);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _onTimeStoreService = inject(OnTimeStoreService);
    private readonly _cityStoreService = inject(CityStoreService);
    private readonly _categoryStoreService = inject(CategoryStoreService);
    private readonly _creativeContentStoreService = inject(CreativeContentStoreService);
    private readonly _router = inject(Router);
    public readonly i18nService = inject(InternationalizationService);
    public readonly activatedRoute = inject(ActivatedRoute);
    public readonly aggregatedGa4EventsService = inject(AggregatedGa4EventsService);
    private _destroyRef = inject(DestroyRef);
    private _viewedOnTimeLists: Set<string> = new Set<string>();

    categoryDrawerVisible = false;
    activeCategory: Category | null = null;
    activeSubcategory: Category | null = null;
    onTimeEvents: Title[] = [];
    cities: City[] = [];
    homePageArticles: Article[] = [];
    categoryTree: ITreeOption<Category>[] = [];
    ngForIterationsCounter = 0;
    menuInited = false;
    loadingCategories = false;
    readonly visibleCitesLength = 24;
    readonly Ga4ListName = Ga4ListNames;

    @Input({ required: true }) menuVisible = false;
    @Input() categories: Category[] = [];

    @ViewChild('container') readonly containerElement!: ElementRef<HTMLDivElement>;

    constructor() {
        this._onTimeStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((events) => {
                this.onTimeEvents = events;
            });

        this._cityStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((cities) => {
                this.cities = cities;
            });

        this._creativeContentStoreService
            .load()
            .pipe(takeUntilDestroyed())
            .subscribe((articles: Article[]) => {
                this.homePageArticles = articles.slice(0, 2);
            });
    }

    public ngOnInit(): void {
        this._categoryStoreService
            .load()
            .pipe(
                tap(() => (this.loadingCategories = true)),
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe((categories) => {
                this.categories = categories;
                this.categoryTree = TreeSelectMapper.map(
                    categories,
                    (c) => c.categoryName,
                    (c) => c.subcategory,
                );
                this.loadingCategories = false;
                this._changeDetectorRef.markForCheck();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.menuVisible) {
            this.menuInited = true;
        }
    }

    protected onTouchendMainCategory(event: TouchEvent, category: Category): void {
        event.preventDefault();
    }

    protected onTouchmoveCategory(event: TouchEvent, category: Category): void {
        event.stopPropagation();
    }

    protected onTouchendCategory(event: TouchEvent, category: Category): void {
        if (category.parentId) {
            this.activeSubcategory = category;
        } else {
            this.activeCategory = category;
            event.preventDefault();
            (this.containerElement.nativeElement.parentNode as HTMLDivElement).scrollTop = 0;
        }
    }

    protected onClick(e: Event, title: Title): void {
        e.preventDefault();
        if (title.linkTo) {
            if (isPlatformBrowser(this._platformId) && title.linkTo.indexOf(window.location.origin) < 0) {
                window.location.href = title.linkTo;
            } else {
                const url = new URL(title.linkTo);
                this._router.navigateByUrl(url.pathname);
            }
        } else {
            this._router.navigate([
                this.i18nService.isEnglishActive ? 'en' : '',
                title.categoryName,
                title.subcategoryName,
                title.slug,
            ]);
        }
    }

    protected onTimeEventIntersecting(titleOnTime: Title, index: number, count: number): void {
        this.ngForIterationsCounter++;
        const categoryName = 'onTimeEvents';
        if (!this._viewedOnTimeLists.has(categoryName)) {
            this.aggregatedGa4EventsService.titleCardIntersecting(
                Ga4ListNames.MegamenuOnTime,
                titleOnTime,
                index,
                Ga4ListNames.MegamenuOnTime,
                true,
            );

            if (this.ngForIterationsCounter === count) {
                this._viewedOnTimeLists.add(categoryName);
                this.ngForIterationsCounter = 0;
            }
        }
    }

    protected categoryOnTimeEventIntersecting(titleOnTime: ITitleOnTime, index: number, count: number): void {
        this.ngForIterationsCounter++;
        if (!this._viewedOnTimeLists.has(titleOnTime.category)) {
            this.aggregatedGa4EventsService.iTitleOnTimeIntersecting(
                Ga4ListNames.MegamenuCategory,
                titleOnTime,
                index,
                Ga4ListNames.MegamenuCategory,
            );

            if (this.ngForIterationsCounter === count) {
                this._viewedOnTimeLists.add(titleOnTime.category);
                this.ngForIterationsCounter = 0;
            }
        }
    }
}
