export const SOCIAL_URLS = {
    facebook: 'https://pl-pl.facebook.com/eBilet/',
    instagram: 'https://instagram.com/ebilet/',
    twitter: 'https://twitter.com/eBiletPL',
    tiktok: 'https://www.tiktok.com/@ebilet',
    youtube: 'https://www.youtube.com/user/eBilet',
    linkedin: 'https://www.linkedin.com/company/ebilet-polska/',
};

export const YOUTUBE_REGEX_WITH_SRC = /src="https?:\/\/(?:www\.)?youtube\.com/g;
export const YOUTUBE_NOOCOOKIE_WITH_SRC = 'src="https://www.youtube-nocookie.com';
export const YOUTUBE_REGEX = /https?:\/\/(?:www\.)?youtube\.com/g;
export const YOUTUBE_NOOCOOKIE = 'https://www.youtube-nocookie.com';

export const setNoCookieDescription = (description?: string): string | undefined => {
    if (description) {
        description = description.replace(YOUTUBE_REGEX_WITH_SRC, YOUTUBE_NOOCOOKIE_WITH_SRC);
    }

    return description;
};
