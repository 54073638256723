import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISeoBoxLinks } from '../../../rest-api/models/seo-info.model';

@Component({
    selector: 'eb-seo-section-link',
    templateUrl: './seo-section-link.component.html',
    styleUrls: ['./seo-section-link.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbSeoSectionLinkComponent {
    @Input({ required: true }) seoBoxLinks: ISeoBoxLinks[] | undefined = [];
}
