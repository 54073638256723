import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IPageParam } from '../infrastructure/page-params-manager.class';
import { ParamsHelper } from '../helpers/params.helper';
import { IArtistPageInfo } from './models/artist-page-info.model';
import { IArtistPage } from './models/artist-page.interface';
import { IFAQ } from './models/faq.model';
import { Artist, SimpleArtist, TitleEvent } from './models/title-page.model';
import { Title } from './models/title.model';

@Injectable({
    providedIn: 'root',
})
export class ArtistPageRestService {
    private readonly _httpClient = inject(HttpClient);

    private readonly _baseUrl = `${environment.portalApi}/Artists`;

    public get(artistSlug: string): Observable<IArtistPage> {
        return this._httpClient.get<IArtistPage>(`${this._baseUrl}/${artistSlug}`);
    }

    public getArtists(request: IPageParam, categorySlug?: string | undefined): Observable<Artist[]> {
        return categorySlug ? this._getArtistsByCategory(request, categorySlug) : this.getAll(request);
    }

    public getAll(request: IPageParam): Observable<Artist[]> {
        return this._httpClient.get<Artist[]>(`${this._baseUrl}/artists`, {
            params: ParamsHelper.preparePageParams(request.size, request.top),
        });
    }

    private _getArtistsByCategory(request: IPageParam, categorySlug: string): Observable<Artist[]> {
        return this._httpClient.get<Artist[]>(`${this._baseUrl}/Category/${categorySlug}/Artist`, {
            params: ParamsHelper.preparePageParams(request.size, request.top),
        });
    }

    public setHeart(artistId: string): Observable<object> {
        let params = new HttpParams();
        params = params.append('add', true);
        return this._httpClient.put(`${this._baseUrl}/${artistId}/Hearts`, {}, { params });
    }

    public getEvents(artistId: string, queryParams: Params, pageParams?: IPageParam | null): Observable<TitleEvent[]> {
        let params = new HttpParams();
        for (const key in queryParams) {
            params = params.append(key, queryParams[key]);
        }
        if (pageParams) {
            params = ParamsHelper.preparePageParams(pageParams.size, pageParams.top, params);
        }
        return this._httpClient.get<TitleEvent[]>(`${this._baseUrl}/${artistId}/events`, { params });
    }

    public getTitles(artistId: string, queryParams: Params, pageParams: IPageParam): Observable<Title[]> {
        const { event } = queryParams;

        let params = new HttpParams();
        if (event) {
            params = params.append('event', event);
        }
        params = ParamsHelper.preparePageParams(pageParams.size, pageParams.top, params);

        return this._httpClient.get<Title[]>(`${this._baseUrl}/${artistId}/titles`, { params });
    }

    public getFAQ(artistId: string): Observable<IFAQ> {
        return this._httpClient.get<IFAQ>(`${this._baseUrl}/${artistId}/faq`);
    }

    public getPopularArtists(artistId: string): Observable<Artist[]> {
        return this._httpClient.get<Artist[]>(`${this._baseUrl}/${artistId}/popular-artists`);
    }

    public getPopularTitles(artistId: string): Observable<Title[]> {
        return this._httpClient.get<Title[]>(`${this._baseUrl}/${artistId}/popular-titles`);
    }

    public getArtistPageInfo(): Observable<IArtistPageInfo> {
        return this._httpClient.get<IArtistPageInfo>(`${this._baseUrl}/page`);
    }

    public getArtistsCategoryPageInfo(categorySlug: string): Observable<IArtistPageInfo> {
        return this._httpClient.get<IArtistPageInfo>(`${this._baseUrl}/Category/${categorySlug}/Page`);
    }

    public getSuggestedTitles(artistId: string, queryParams: Params, pageParams: IPageParam): Observable<Title[]> {
        const { userId } = queryParams;

        let params = new HttpParams();
        if (userId) {
            params = params.append('userId', userId);
        }
        params = ParamsHelper.preparePageParams(pageParams.size, pageParams.top, params);

        return this._httpClient.get<Title[]>(`${this._baseUrl}/${artistId}/suggested-titles`, { params });
    }

    public getHistoricalTitles(artistId: string, pageParams: IPageParam): Observable<Title[]> {
        return this._httpClient.get<Title[]>(`${this._baseUrl}/${artistId}/historical-titles`, {
            params: ParamsHelper.preparePageParams(pageParams.size, pageParams.top),
        });
    }

    public findArtists(categorySlug: string, value: string): Observable<any[]> {
        let params = new HttpParams();
        params = params.append('artistName', value);
        return this._httpClient.get<SimpleArtist[]>(`${this._baseUrl}/Category/${categorySlug}/proposedArtists`, {
            params,
        });
    }
}
