import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeRestService } from '../rest-api/home-rest.service';
import { SearchResponse } from '../rest-api/models/search-response.model';
import { BaseStoreService } from './base-store.service';

@Injectable({
    providedIn: 'root',
})
export class SearchProposedStoreService extends BaseStoreService<null, SearchResponse> {
    private readonly _homeRestService = inject(HomeRestService);

    protected _shouldLoad(newRequest: null): boolean {
        return !this._loadingStream$.value && !this._lastResponse;
    }

    protected _getRest(): (request: null) => Observable<SearchResponse> {
        return () => this._homeRestService.getProposed();
    }

    public load(request: null): Observable<SearchResponse> {
        return super.load(request);
    }

    public get(): Observable<SearchResponse> {
        this.load(this._request);
        return super.get();
    }
}
