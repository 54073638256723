<div class="eb-dialog" [ngClass]="config.className">
  <button eb-button  type="submit" *ngIf="config.showCloseIcon" (click)="hide()" class="eb-dialog-close-button">
    <svg-icon key="close" />
  </button>
  <div class="eb-dialog-content">
    <div class="eb-dialog-header">
      <ng-container *ngTemplateOutlet="config.header || null" />
    </div>
    <div class="eb-dialog-body">
      <ng-template cdkPortalOutlet />
    </div>
    <div class="eb-dialog-footer" *ngIf="config.footer">
      <ng-container *ngTemplateOutlet="config.footer || null" />
    </div>
  </div>
</div>
