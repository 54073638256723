import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    PLATFORM_ID,
    SimpleChanges,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ShopQueueService } from 'apps/portal/src/app/services/shop-queue.service';
import { IFrame } from '../../../../../apps/portal/src/app/rest-api/models/iframe';
import { IframeService } from '../../../../../apps/portal/src/app/services/iframe.service';

export enum ListViewTypes {
    BAR = 'BAR',
    CARD = 'CARD',
    CALENDAR = 'CALENDAR',
    DAY = 'DAY',
}

@Component({
    selector: 'eb-list-view-toggle-button',
    templateUrl: './list-view-toggle-button.component.html',
    styleUrls: ['./list-view-toggle-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbListViewToggleButtonComponent implements OnChanges {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _iframeService = inject(IframeService);
    private readonly _shopQueueService = inject(ShopQueueService);

    readonly ListViewTypes = ListViewTypes;
    next: ListViewTypes | null = null;

    @Input({ required: true }) availableTypes: ListViewTypes[] = [];
    @Input() showLabels = true;
    @Input() current!: ListViewTypes;

    @Output() readonly currentChange = new EventEmitter<ListViewTypes>();
    iframe: IFrame | undefined;

    constructor() {
        this._iframeService.iframe$.pipe(takeUntilDestroyed()).subscribe((iframe) => {
            this.iframe = iframe;
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.current.currentValue === changes.current.previousValue &&
            changes.availableTypes.currentValue === changes.availableTypes.previousValue
        ) {
            return;
        }
        this.next = this._getNext(changes.current.currentValue);
    }

    protected setNextListView(): void {
        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => {
                this._shopQueueService.clearQueue();
                this.current = this.next as ListViewTypes;
                this.currentChange.next(this.current);
            });
        } else {
            this.current = this.next as ListViewTypes;
            this.currentChange.next(this.current);
        }
    }

    private _getNext(listViewType: ListViewTypes | null): ListViewTypes | null {
        if (this.availableTypes.length === 0) {
            return null;
        }

        if (!listViewType) {
            return this.availableTypes[0];
        }

        const currIdx = this.availableTypes.indexOf(listViewType);
        const nextIdx = currIdx === this.availableTypes.length - 1 ? 0 : currIdx + 1;
        return this.availableTypes[nextIdx];
    }
}
