<ng-container *ngIf="isLoading; then: skeletonView; else: contentView" />

<ng-template #skeletonView>
  <h2 class="eb-skeleton" style="width: 20%" ebIntersection [ebOnlyFirst]="true" (intersecting)="skeletonVisible = true"></h2>
  <div *ngIf="skeletonVisible">
    <p class="eb-skeleton"></p>
    <p class="eb-skeleton"></p>
    <p class="eb-skeleton"></p>
    <p class="eb-skeleton"></p>
    <p class="eb-skeleton"></p>
    <p class="eb-skeleton" style="width: 50%"></p>

    <p class="mt-5 eb-skeleton" style="width: 30%"></p>
  </div>
</ng-template>

<ng-template #contentView>
  <h2 [ngClass]=" {'eb-skeleton w-2': title === ''}"> {{ title }}</h2> <!-- hack na to żeby w momencie kiedy tłumaczenia się jeszcze nie pobrały i nie są dostępne był element typu skeleton co przeciwdziała CLS; do zastanowienia co zrobić z tym że tłumaczenie tyle się ładują-->
  <div [innerHtml]="description | ebSafeHtml">
  </div>
  <div *ngIf="showExtendedDescription" [innerHtml]="extendedDescription | ebSafeHtml" class="mt-3">
  </div>
  <a eb-button ebType="text" *ngIf="extendedDescription" (click)="toggleShowExtendedDescription()" class="mt-3">
    {{(showExtendedDescription ? 'pageDescription.showLess' : 'pageDescription.showMore') | transloco}}
    <svg-icon [key]="showExtendedDescription ? 'chevron-up' : 'chevron-down'" style="font-size: smaller;" />
  </a>
</ng-template>
