import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

export interface Poi {
    lat: number;
    lng: number;
    desc?: string;
    img?: string;
    url?: string;
}

export enum CampaignTypes {
    TITLE = 'title',
    VENUE = 'venue',
}

@Component({
    selector: 'eb-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbMapComponent {
    private readonly _id = 'ebiletpolska'; // Dla panelu nawigacyjnego (możliwość śledzenia kliknięć, obciążeń i zarobków).
    private readonly _mainColor = 'FFBC03'; // Tylko hex (bez #).
    private readonly _markerImageTransparent = `${environment.baseUrl}/markerimage.png`;

    @Input({ required: true }) lat: string | number = '';
    @Input({ required: true }) lng: string | number = '';

    @Input() zoom = 13;
    @Input() hideFilters = true; // Ukryj filtry.
    @Input() hideGuestPicker = true; // Ukryj przyckisk Goście.
    @Input() hideSearchBar = true; // Ukryj pasek wyszukiwania.
    @Input() hideInfoMenu = true; // Ukryj przycisk informacji (w lewym górnym roku).
    @Input() disableHotels = true; // Ukryj propozycje hoteli (Booking).
    @Input() disableRentals = true; // Ukryj propozycje wynajmu (Vrbo).
    @Input() freezeViewport = false; // Automatyczne pomniejszanie/powiększanie po załadowaniu mapy.
    @Input() hideSettings = true; // Ukryj przycisk ustawień (waluta i język).
    @Input() hideCheckInOut = true; // Ukryj przycisk Wybierz daty.
    @Input() hideHeatmap = true; // Ukryj przycisk w lewym dolnym roku (Mapa termiczna, Satelita, Teren, itd.).
    @Input() fitMapToPoi = true; // True gdy wczytujemy POI, czyli własne pinezki - wszystkie wyświetlane są wtedy przy początkowym wczytaniu mapy.
    // eslint-disable-next-line max-len
    @Input() gpx = ''; // Prazentacja własnego szlaku na mapie. Przykład użycia gpx=https://www.example.com/agpxfile.gpx (Punkty orientacyje: punkt: <wpt/>, trasa: <rte/>.).
    @Input() disableDirections = true;
    @Input() hideMarker = false;
    @Input() hideMapPanels = false;
    @Input() disableAirports = true;
    @Input() currency = 'PLN';
    @Input() campaign!: CampaignTypes;
    @Input() markerImage: string | undefined = undefined; // Zmiana pineski głównej mapy (Trzeba podać URL)

    /**
     * Wiele pinesek na mapie.
   Tablica poi, która przyjmuje następujący typ obiektu:
    {
        "lat": latitude,
        "lng": longitude,
        "desc": "optional, description displays on pin hover",
        "img": "optional, this is the pin image url in SSL (https), a default one will be used if omitted",
        "url": "optional, the url where it should redirect when clicked. Make sure the http(s):// is included"
      }
     */
    @Input() poi: Poi[] = [];

    protected get url(): string {
        this._addCoverImageForPoi();
        return (
            'https://www.stay22.com/embed/gm?' +
            `aid=${this._id}&` +
            `lat=${this.lat}&` +
            `lng=${this.lng}&` +
            `maincolor=${this._mainColor}&` +
            `hidemainmarkercover=${this.hideMarker}&` +
            `markerimage=${this._getCoverImage()}&` +
            `hidefilters=${this.hideFilters}&` +
            `hideguestpicker=${this.hideGuestPicker}&` +
            `hidesearchbar=${this.hideSearchBar}&` +
            `hideinfomenu=${this.hideInfoMenu}&` +
            `disablehotels=${this.disableHotels || this.zoom < 10}&` +
            `disablerentals=${this.disableRentals}&` +
            `freezeviewport=${this.freezeViewport}&` +
            `hidesettings=${this.hideSettings}&` +
            `hidecheckinout=${this.hideCheckInOut}&` +
            `hideheatmap=${this.hideHeatmap}&` +
            `hidemappanels=${this.hideMapPanels}&` +
            `disablefsp=${this.disableAirports}&` +
            `currency=${this.currency}&` +
            `gpx=${this.gpx}&` +
            `zoom=${this.zoom}&` +
            `poi=${this.poi.length !== 1 ? encodeURIComponent(JSON.stringify(this.poi)) : ''}&` +
            `disabledirections=${this.disableDirections}&` +
            'track=true&' +
            `isnear=${this._showBigPin()}&` +
            `campaign=${this.campaign}`
        );
    }

    private _showMarkerImage(): boolean {
        return !this.hideMarker && !!this.markerImage;
    }

    private _showBigPin(): boolean {
        return this.poi.length === 0 || this.poi.length === 1;
    }

    private _getCoverImage(): string {
        if (!this._showMarkerImage()) {
            return '';
        }

        return environment.mediaStay22 + this.markerImage; // ścieżka musi być absolutna
    }

    private _addCoverImageForPoi(): void {
        this.poi = this.poi.map((poi) => {
            poi.img = this._getCoverImage();
            return poi;
        });
    }
}
