<div class="carousel relative" [ngClass]="{ 'carousel--full-width-mobile': fullWidthMobile }">
  <div #carouselFrameRef class="carousel__frame scroll-hide overflow-x-auto" ebScrollbar [dark]="dark" [fullWidthMobile]="fullWidthMobile">
    <div #carouselItemsRef class="carousel__items" [ngClass]="{ 'carousel__items--padding': !isGallery }">
      <ng-content />
    </div>
  </div>
  <div class="carousel-controls">
    <button
      eb-button
      [ebOnlyIcon]="true"
      aria-label="Przewiń w lewo"
      ebType="primary"
      ebColor="purple"
      ebSize="small"
      (click)="scrollBy(isGallery ? 1 : numberOfItemsToScrollBy, CarouselNavigationDirections.LEFT)"
      *ngIf="isNavEnable"
      class="carousel-control__prev absolute z-1"
    >
      <svg-icon key="chevron-left" size="mdr" />
    </button>
    <button
      eb-button
      [ebOnlyIcon]="true"
      aria-label="Przewiń w prawo"
      ebType="primary"
      ebColor="purple"
      ebSize="small"
      (click)="scrollBy(isGallery ? 1 : numberOfItemsToScrollBy, CarouselNavigationDirections.RIGHT)"
      *ngIf="isNavEnable"
      class="carousel-control__next absolute z-1"
    >
      <svg-icon key="chevron-right" size="mdr" />
    </button>
  </div>
</div>
