import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbDropdownTriggerForDirective } from './dropdown-trigger-for.directive';
import { EbDropdownComponent } from './dropdown/dropdown.component';

@NgModule({
    declarations: [EbDropdownComponent, EbDropdownTriggerForDirective],
    imports: [CommonModule, OverlayModule],
    exports: [EbDropdownComponent, EbDropdownTriggerForDirective],
})
export class UiDropdownModule {}
