import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { UiButtonsModule } from './../../../ui-buttons/src/lib/ui-buttons.module';
import { EbListViewToggleButtonComponent } from './list-view-toggle-button/list-view-toggle-button.component';

@NgModule({
    imports: [CommonModule, UiButtonsModule, TranslocoModule, SvgIconComponent],
    declarations: [EbListViewToggleButtonComponent],
    exports: [EbListViewToggleButtonComponent],
})
export class UiListViewToggleButtonModule {}
