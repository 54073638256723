import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UiButtonsModule } from './../../../ui-buttons/src/lib/ui-buttons.module';
import { EbDialogContainerComponent } from './dialog-container/dialog-container.component';
import { EbDialogContentDirective } from './dialog-content.directive';
import { EbDialogFooterDirective } from './dialog-footer.directive';
import { EbDialogHeaderDirective } from './dialog-header.directive';
import { EbDialogComponent } from './dialog/dialog.component';

@NgModule({
    imports: [CommonModule, OverlayModule, PortalModule, A11yModule, UiButtonsModule, SvgIconComponent],
    declarations: [
        EbDialogComponent,
        EbDialogContainerComponent,
        EbDialogHeaderDirective,
        EbDialogContentDirective,
        EbDialogFooterDirective,
    ],
    exports: [EbDialogComponent, EbDialogHeaderDirective, EbDialogContentDirective, EbDialogFooterDirective],
})
export class UiDialogModule {}
