<div class="eb-dont-miss" *ngIf="tiles.length">
  <p class="h2">{{'shared.dont-miss.sectionHeader' | transloco}}</p>
  <div class="eb-dont-miss__tiles">
    <div class="eb-dont-miss__tile" *ngFor="let tile of tiles; let i = index" [routerLink]="tile.routerLink">
      <p class="eb-dont-miss__tile__title" [ngClass]="i < 2 ? 'eb-dont-miss__tile__title--large' : 'eb-dont-miss__tile__title--small'">
        {{tile.title}}
      </p>
      <span *ngIf="i < 2" class="eb-dont-miss__tile__subtitle">{{tile.subtitle}}</span>
    </div>
  </div>
</div>
