import { DateString } from './date-string.type';
import { ISeoBoxLinks, ISeoMeta } from './seo-info.model';
import { TitleEventsPresentationTypes, TitleGallery } from './title-page.model';

export interface IArtistPage {
    id: string;
    name: string;
    imageUrl: string;
    shortDescription: string;
    description: string;
    hearts?: number;
    gallery: TitleGallery;
    eventsDefaultPresentationType: TitleEventsPresentationTypes;
    details: IArtistProperties;

    categories: IArtistCategory[];

    seo: ISeoMeta;
    seoBoxLinks: ISeoBoxLinks[];
}

export interface IArtistCategory {
    name: string;
    slug: string | null;
}

export interface IArtistProperties {
    dateOfBirth?: DateString;
    createDate?: DateString;
    placeOfBirth?: string;
    socialMedia?: ISocialMediaLink[];
    originPlace?: string;
    band?: string;
    previousBands?: string;
    members?: string;
    mainRoles?: string;
    discipline?: string;
    previousMembers?: string;
    currentOccupation?: string;
    previousOccupation?: string;
}

export interface ISocialMediaLink {
    link: string;
    brand: SocialMediaBrand;
}

export enum SocialMediaBrand {
    FACEBOOK = 1,
    TWITTER = 2,
    INSTAGRAM = 3,
    TIK_TOK = 4,
    OTHER = 5,
    SPOTIFY = 6,
    YOUTUBE = 7,
    LINKEDIN = 8,
    WWW = 9,
}
