<div class="eb-ticket-panel">
  <div class="eb-ticket-panel__stub">
    <ng-content select="[eb-ticket-panel-stub]" />
  </div>
  <div class="eb-ticket-panel__indentation-wrapper-left">
    <div class="indentation indentation--left"></div>
  </div>
  <div class="eb-ticket-panel__indentation-wrapper-right">
    <div class="indentation indentation--right"></div>
  </div>
  <div class="eb-ticket-panel__cutline"></div>
  <div class="eb-ticket-panel__counterfoil" [ngClass]="{ 'eb-ticket-panel__counterfoil--no-price-info': noPriceInfo }">
    <ng-content select="[eb-ticket-panel-counterfoil]" />
  </div>
</div>
