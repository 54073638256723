import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import { GtmService } from '@e-bilet/gtm';
import {
  CookieConsent,
  CookiesPolicyService
} from 'libs/cookies-policy/src/lib/cookies-policy.service';
import { CookieName, CookiesService } from 'libs/cookies-policy/src/lib/cookies.service';
import { Subject, forkJoin } from 'rxjs';
import { Title } from '../rest-api/models/title.model';

export interface ITdProduct {
    id: string;
    name: string;
    qty: number;
}

export enum TdPageTypeEnum {
    LISTING = 'listing',
    HOMEPAGE = 'homepage',
    PRODUCT = 'product',
}

interface ITradedoublerInitData {
    pageType: TdPageTypeEnum;
    products: ITdProduct[] | undefined;
    prefix: string | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class TradedoublerService {
    private readonly _cookiesService = inject(CookiesService);
    private readonly _gtmService = inject(GtmService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _cookiesPolicyService = inject(CookiesPolicyService);

    private readonly _$cookiesAccepted: Subject<boolean> = new Subject<boolean>();
    private readonly _$activateTradedoubler: Subject<ITradedoublerInitData> = new Subject<ITradedoublerInitData>();

    public get queryParams(): Params {
        return this._activatedRoute.snapshot.queryParams;
    }

    constructor() {
        forkJoin({
            cookiesAccepted: this._$cookiesAccepted,
            activateTradedoubler: this._$activateTradedoubler,
        })
            .pipe(takeUntilDestroyed())
            .subscribe(({ cookiesAccepted: isCookiesConsentAccepted, activateTradedoubler: tradedoublerInitData }) => {
                this._initTradedoubler(isCookiesConsentAccepted, tradedoublerInitData);
            });

        this._cookiesPolicyService.cookiesPolicyConsent$
            .pipe(takeUntilDestroyed())
            .subscribe((cookiesConsent: CookieConsent|undefined) => {
                if (cookiesConsent?.marketing) {
                  this._$cookiesAccepted.next(true);
                  this._$cookiesAccepted.complete();
                } else {
                  this._$cookiesAccepted.next(false);
                  this._$cookiesAccepted.complete();
                }
            });
    }

    public checkTradedoubler(pageType: TdPageTypeEnum, titles?: Title[]): void {
        if (isPlatformBrowser(this._platformId)) {
            if (this.queryParams.tduid || this._cookiesService.getCookieValue(CookieName.Tradedoubler)) {
                const products: ITdProduct[] | undefined = titles?.map((title) => this._mapTitleToTdProduct(title));
                this.activateTradedoubler(pageType, products);
            }
        }
    }

    public activateTradedoubler(pageType: TdPageTypeEnum, products?: ITdProduct[], tdPrefix?: string): void {
        this._$activateTradedoubler.next({ pageType, products, prefix: tdPrefix } as ITradedoublerInitData);
        this._$activateTradedoubler.complete();
    }

    private _initTradedoubler(isCookiesConsentAccepted: boolean, tradedoublerInitData: ITradedoublerInitData): void {
        if (isCookiesConsentAccepted) {
            this._setTDCtag(tradedoublerInitData);
            this._setTDConf(tradedoublerInitData);
        } else {
            this._setTduidInSessionStorage();
        }
    }

    private _setTDCtag(tradedoublerInitData: ITradedoublerInitData): void {
        this._gtmService.setVariableObject({
            event: 'tdCtag',
            tdPageType: tradedoublerInitData.pageType,
            ...(tradedoublerInitData.products && { tdProducts: tradedoublerInitData.products }),
            ...(tradedoublerInitData.prefix && { tdPrefix: tradedoublerInitData.prefix }),
        });
    }

    private _setTDConf(tradedoublerInitData: ITradedoublerInitData): void {
        const theWindow = window as any;
        theWindow.TDConf = {};
        theWindow.TDConf.Config = {
            tdPageType: tradedoublerInitData.pageType,
        };

        if (tradedoublerInitData.products) {
            theWindow.TDConf.Config.products = tradedoublerInitData.products;
        }
    }

    private _setTduidInSessionStorage(): void {
        sessionStorage.setItem('tduid', this.queryParams.tduid);
    }

    private _mapTitleToTdProduct(title: Title): ITdProduct {
        return {
            id: title.id,
            name: title.title,
            qty: 1,
        } as ITdProduct;
    }
}
