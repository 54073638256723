<!-- Many articles -->

<ng-container *ngIf="showCarousel; else noCarousel">
    <ng-container *ngIf="!loading; else carouselSkeleton">
      <eb-carousel>
        <eb-article-card
          *ngFor="let article of articles; let i = index"
          [article]="article"
          [size]="'normal'"
          ebCarouselItem
          ebIntersection
        />
      </eb-carousel>
    </ng-container>
    <ng-template #carouselSkeleton>
      <eb-carousel class="grid-section__content">
        <eb-article-card *ebRepeat="6" [skeleton]="true" [size]="'normal'" ebCarouselItem />
      </eb-carousel>
    </ng-template>
</ng-container>


<ng-template #noCarousel>

  <!-- Two large articles -->

  <ng-container *ngIf="!isMegaMenu; else megaMenu">
    <ng-container *ngIf="articles.length === 2; else extraLargeArticle">
      <div ebScrollbar class="article-card-container--large-articles scroll-hide overflow-x-auto">
        <ng-container *ngIf="!loading; else largeArticleSkeleton">
          <eb-article-card
          *ngFor="let article of articles; let i = index"
          [article]="article"
          [size]="'large'"
          />
      </ng-container>

        <ng-template #largeArticleSkeleton>
            <eb-article-card
              *ebRepeat="2"
              [skeleton]="true"
              [size]="'large'"
            />
        </ng-template>
      </div>
    </ng-container>

     <!-- One extra large article -->

    <ng-template #extraLargeArticle>
      <ng-container *ngIf="!loading; else extraLargeArticleSkeleton">
        <eb-article-card
        class="article-card-container--extra-large-articles"
        [article]="articles[0]"
        [size]="'extra-large'"
        />
      </ng-container>

      <ng-template #extraLargeArticleSkeleton>
        <eb-article-card
          [skeleton]="true"
          [size]="'extra-large'"
        />
      </ng-template>
    </ng-template>
  </ng-container>

    <!-- Two small articles (mega menu) -->

  <ng-template #megaMenu>
      <div *ngIf="!loading; else smallArticlesSkeleton" class="article-card-container--small-articles">
        <eb-article-card
        *ngFor="let article of articles"
        class="article-card--small"
        [article]="article"
        [size]="'small'"
        />
      </div>

      <ng-template #smallArticlesSkeleton>
        <eb-article-card
          [skeleton]="true"
          [size]="'small'"
        />
      </ng-template>
  </ng-template>



</ng-template>
