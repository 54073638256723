<eb-form-item
  [label]="label"
  [prefixIcon]="icon"
  [size]="size"
  [theme]="theme"
  [onlyIcon]="onlyIcon"
  suffixIcon="chevron-down"
  [isClearIcon]="!!value"
  (clearInput)="clear()"
>
  <div class="eb-control-value ellipsis" *ngIf="value; else searchTmpl">
    <ng-container *ngTemplateOutlet="selectedTemplate ? selectedTemplate : labelTmpl; context: { $implicit: value }" />
  </div>
</eb-form-item>
<div
  #skeleton
  *ngIf="isLoading"
  [style.width]="skeletonWidth"
  [style.left]="skeletonLeft"
  class="eb-tree-select__skeleton border-1 border-solid"
>
  <svg-icon key="loading" />
</div>
<ng-template #searchTmpl>
  <input type="text" class="eb-input" [placeholder]="placeholder | transloco" [formControl]="searchFormControl" />
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayWidth]="origin.elementRef.nativeElement.offsetWidth"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="close()"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
  (attach)="opened()"
>
  <div class="eb-overlay-panel hm-60 p-0" #overlayPanel>
    <div class="eb-slide-menu">
      <div class="eb-slide-submenu" [ngClass]="{ 'eb-slide-submenu--active': !activeMenu }">
        <ng-container *ngIf="!hasOptions">
          <div class="eb-slide-menu-item eb-slide-menu-item--readonly">
            <div class="eb-slide-menu-item__label">
              <span class="ellipsis">{{ 'libs.tree-select.noOptionsAvailable' | transloco }}</span>
            </div>
          </div>
          <div class="eb-slide-menu-item-divider"></div>
        </ng-container>

        <div
          class="eb-slide-menu-item"
          [ngClass]="{ 'eb-slide-menu-item--active': activeMenu === availableOption }"
          *ngFor="let availableOption of availableOptions"
        >
          <div class="eb-slide-menu-item__label" (click)="activateMenu(availableOption)">
            <span class="ellipsis">
              <ng-container
                *ngTemplateOutlet="
                  optionTemplate ? optionTemplate : labelTmpl;
                  context: { $implicit: availableOption, flat: isSearchResult }
                "
              />
            </span>
            <svg-icon *ngIf="!isSearchResult && availableOption.items?.length" key="chevron-right" size="mde" class="ml-auto" />
          </div>
          <div class="eb-slide-submenu" [ngClass]="{ 'eb-slide-submenu--active': activeMenu === availableOption }">
            <div class="eb-slide-menu-item sticky top-0 bg-white" (click)="activateMenu(null)">
              <div class="eb-slide-menu-item__label">
                <svg-icon key="chevron-left" size="mde" class="mr-3" />
                <span class="ellipsis">
                  <ng-container
                    *ngTemplateOutlet="
                      optionTemplate ? optionTemplate : labelTmpl;
                      context: { $implicit: availableOption, flat: isSearchResult }
                    "
                  />
                </span>
              </div>
            </div>
            <div class="eb-divider"></div>
            <div class="eb-slide-menu-item">
              <div class="eb-slide-menu-item__label" (click)="select(availableOption)">
                <span class="ellipsis">{{ 'libs.tree-select.allFrom' | transloco }} {{ availableOption.label }}{{ allFromCategory }}</span>
              </div>
            </div>
            <div class="eb-slide-menu-item" *ngFor="let option of availableOption.items; let i = index" (click)="select(option)">
              <div class="eb-slide-menu-item__label">
                <span class="ellipsis">
                  <ng-container
                    *ngTemplateOutlet="optionTemplate ? optionTemplate : labelTmpl; context: { $implicit: option, flat: isSearchResult }"
                  />
                </span>
              </div>
              <div *ngIf="option.mostlySelected && !availableOption.items?.[i + 1]?.mostlySelected" class="eb-divider"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #labelTmpl let-option let-flat="flat">
  <ng-container *ngIf="flat && option.parent">{{ option.parent.label }} / </ng-container>
  {{ option.label }}
</ng-template>
