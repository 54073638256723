<eb-drawer
  [(isVisible)]="isVisible"
  placement="bottom"
  [closable]="true"
  height="60%"
  [bodyStyle]="{ 'padding': '0 0 80px' }"
>
<div class="double-cards-drawer-content">
  <ng-content />
</div>
</eb-drawer>
