import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFanAlertParams } from '../../../rest-api/models/fan-alert-request.model';

@Component({
    selector: 'eb-no-event-info',
    templateUrl: './no-event-info.component.html',
    styleUrls: ['./no-event-info.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbNoEventInfoComponent {
    @Input({ required: true }) header = '';
    @Input({ required: true }) eventInfo = '';
    @Input({ required: true }) fanAlertParams!: IFanAlertParams;
}
