import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewContainerRef,
    inject,
    PLATFORM_ID,
} from '@angular/core';
import { TooltipElementComponent } from './tooltip-element/tooltip-element.component';
import { isPlatformBrowser } from '@angular/common';

@Directive({
    selector: '[ebTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _elementRef = inject(ElementRef);
    private readonly _viewContainerRef = inject(ViewContainerRef);

    @Input({ required: true }) ebTooltip = '';
    @Input() trigger: 'click' | 'hover' = 'click';
    @Input() ebTooltipCloseButtonVisible = true;
    @Input() ebTooltipTemplateRef!: TemplateRef<unknown>;
    @Input() ebTooltipIsFanAlert = false;

    tooltipCoponentRef!: ComponentRef<TooltipElementComponent>;

    @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

    public ngOnInit(): void {
        this.tooltipCoponentRef = this._viewContainerRef.createComponent(TooltipElementComponent);
        this.tooltipCoponentRef.instance.text = this.ebTooltip;
        this.tooltipCoponentRef.instance.templateRef = this.ebTooltipTemplateRef;
        this.tooltipCoponentRef.instance.closeButtonVisible = this.ebTooltipCloseButtonVisible;
        this.tooltipCoponentRef.instance.isFanAlert = this.ebTooltipIsFanAlert;

        this.tooltipCoponentRef.instance.loadedEvent$.subscribe(() => {
            this.isLoading.emit(false);
        });
    }

    public ngOnDestroy(): void {
        this._viewContainerRef.clear();
    }

    @HostListener('click') protected onClick(): void {
        if (this.trigger === 'click') {
            if (isPlatformBrowser(this._platformId)) {
                setTimeout(() => this.tooltipCoponentRef.instance.show(this._elementRef));
            } else {
                this.tooltipCoponentRef.instance.show(this._elementRef);
            }
        }
    }

    @HostListener('mouseenter') protected onMouseEnter(): void {
        if (this.trigger === 'hover') {
            this.tooltipCoponentRef.instance.show(this._elementRef);
        }
    }

    @HostListener('mouseleave') protected onMouseLeave(): void {
        if (this.trigger === 'hover') {
            this.tooltipCoponentRef.instance.close();
        }
    }
}
