import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'eb-double-cards-drawer',
    templateUrl: './double-cards-drawer.component.html',
    styleUrls: ['./double-cards-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { ngSkipHydration: 'true' },
})
export class EbDoubleCardsDrawerComponent {
    private _isVisible = false;
    @Input({ required: true }) public set isVisible(isVisible: boolean) {
        isVisible ? this._show() : this._close();
    }

    public get isVisible(): boolean {
        return this._isVisible;
    }

    @Output() isVisibleChange = new EventEmitter<boolean>();

    private _show(): void {
        this._isVisible = true;
        if (this.isVisibleChange.observed) {
            this.isVisibleChange.emit(this.isVisible);
        }
    }

    private _close(): void {
        this._isVisible = false;
        if (this.isVisibleChange.observed) {
            this.isVisibleChange.emit(this.isVisible);
        }
    }
}
