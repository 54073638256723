import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TicketPanelComponent } from './ticket-panel/ticket-panel.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TicketPanelComponent],
    exports: [TicketPanelComponent],
})
export class UiTicketPanelModule {}
