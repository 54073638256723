import { TemplateRef, ViewContainerRef } from '@angular/core';

export class UiDialogConfig<T> {
    viewContainerRef?: ViewContainerRef;
    header?: TemplateRef<T>;
    content!: TemplateRef<T>;
    footer?: TemplateRef<T>;
    className!: string | string[];
    showCloseIcon!: boolean;
}
