import { ChangeDetectionStrategy, Component, HostBinding, Input, inject, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { EbButtonSize } from 'libs/ui-buttons/src/lib/button/button.component';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { IShareData } from './share-data.interface';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'eb-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbShareButtonComponent {
    private _platformId = inject(PLATFORM_ID);
    private readonly _translocoService = inject(TranslocoService);
    private readonly _deviceService = inject(DeviceService);

    private _isActive = false;
    private _hasCopied = false;

    @Input({ required: true }) shareData!: IShareData;

    @Input() ebSize: EbButtonSize = 'large';
    @Input() ebGhost = true;
    private _isMobile = false;

    @HostBinding('class.eb-share-btn--active') protected get isActive(): boolean {
        return this._isActive;
    }

    protected get hasCopied(): boolean {
        return this._hasCopied;
    }

    constructor() {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed()).subscribe((isMobile: boolean) => {
            this._isMobile = isMobile;
            this._deactive();
        });
    }

    protected share(): void {
        if (this._isMobile) {
            this._navigatorShare();
        } else {
            this._isActive = !this._isActive;
        }
    }

    private _deactive(): void {
        this._isActive = false;
        this._hasCopied = false;
    }

    protected shareFb(): void {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.shareData.url);
        this._deactive();
    }

    protected shareLink(): void {
        navigator.clipboard.writeText(decodeURIComponent(this.shareData.url));
        this._hasCopied = true;

        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => this._deactive(), 1000);
        } else {
            this._deactive();
        }
    }

    protected shareEmail(): void {
        this._navigatorShare();
        this._deactive();
    }

    private _navigatorShare(): void {
        const shareData: IShareData = { ...this.shareData, url: decodeURIComponent(this.shareData.url) };

        navigator.share({
            title: shareData.title,
            text: this._translocoService.translate(this.shareData.textTransKey, shareData),
            url: shareData.url,
        });
    }
}
