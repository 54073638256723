import { Component, Input } from '@angular/core';
import { Article } from '../../../../../apps/portal/src/app/rest-api/models/article.interface';

@Component({
    selector: 'eb-article-card-container',
    templateUrl: './article-card-container.component.html',
    styleUrls: ['./article-card-container.component.less'],
})
export class EbArticleCardContainerComponent {
    @Input() articles!: Article[];
    @Input() showCarousel = false;
    @Input() isMegaMenu = false;
    @Input() loading = true;
}
