import { DestroyRef, Injectable, Injector, TemplateRef, inject } from '@angular/core';
import { take } from 'rxjs/operators';

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UiDialogConfig } from '../dialog-config.model';
import { EbDialogContainerComponent } from '../dialog-container/dialog-container.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private readonly overlay = inject(Overlay);
    private readonly injector = inject(Injector);
    private _destroyRef = inject(DestroyRef);

    public create<T, R>(config: UiDialogConfig<T>): EbDialogContainerComponent<R> {
        const overlayRef = this._createOverlay();
        const dialogContainer = this._attachDialogContainer<T, R>(overlayRef, config);

        const dialogRef = this._attachDialogContent(config.content, dialogContainer, overlayRef, config);

        dialogContainer.closing.pipe(take(1), takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            overlayRef.dispose();
        });

        return dialogContainer;
    }

    private _createOverlay(): OverlayRef {
        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay.position().global(),
            disposeOnNavigation: true,
            backdropClass: 'eb-overlay-mask',
        });

        return this.overlay.create(overlayConfig);
    }

    private _attachDialogContainer<T, R>(
        overlayRef: OverlayRef,
        config: UiDialogConfig<T>,
    ): EbDialogContainerComponent<R> {
        const userInjector = config?.viewContainerRef?.injector;
        const injector = Injector.create({
            parent: userInjector || this.injector,
            providers: [
                { provide: OverlayRef, useValue: overlayRef },
                { provide: UiDialogConfig, useValue: config },
            ],
        });

        const containerPortal = new ComponentPortal<EbDialogContainerComponent<R>>(
            EbDialogContainerComponent,
            config.viewContainerRef,
            injector,
        );
        const containerRef = overlayRef.attach<EbDialogContainerComponent<R>>(containerPortal);

        return containerRef.instance;
    }

    private _attachDialogContent<T, R>(
        content: TemplateRef<T>,
        dialogContainer: EbDialogContainerComponent<R>,
        overlayRef: OverlayRef,
        config: UiDialogConfig<T>,
    ): void {
        if (content instanceof TemplateRef) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dialogContainer.attachTemplatePortal(new TemplatePortal<T>(content, null!));
        }
    }
}
