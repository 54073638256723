import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { CookieHelper } from '../../../../../apps/portal/src/app/helpers/cookie.helper';
import { FanAlertRestService } from '../../../../../apps/portal/src/app/rest-api/fan-alert-rest.service';
import { IFanAlertParams } from '../../../../../apps/portal/src/app/rest-api/models/fan-alert-request.model';
import { CookieName, CookiesService } from '../../../../cookies-policy/src/lib/cookies.service';
import { EbButtonColor, EbButtonSize, EbButtonType } from '../../../../ui-buttons/src/lib/button/button.component';

@Component({
    selector: 'eb-fan-alert',
    templateUrl: './fan-alert.component.html',
    styleUrls: ['./fan-alert.component.less'],
    host: { ngSkipHydration: 'true' },
})
export class FanAlertComponent implements OnInit {
    private readonly _cookiesService = inject(CookiesService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _fanAlertRestService = inject(FanAlertRestService);
    private readonly _platformId = inject(PLATFORM_ID);

    private _destroyRef = inject(DestroyRef);
    @Input({ required: true }) fanAlertParams!: IFanAlertParams;

    @Input() label = 'libs.fan-alert.notifyMe';

    @Input() labelVisible = false;
    @Input() isGhostButton = false;
    @Input() unobservedButtonType: EbButtonType = 'secondary';
    @Input() observedButtonType: EbButtonType = 'secondary';
    @Input() observedButtonColor: EbButtonColor = null;
    @Input() observedIcon = 'bell-yellow';

    @Input() size: EbButtonSize = 'default';
    @Input() iconVisible = true;

    isObserved = false;
    isValidated = false;
    isInProgress = false;
    isTooltipLoading = false;

    itemStorageKey = '';

    readonly loggedUserCookie = this._cookiesService.getCookieValue(CookieName.LoggedUser);

    emailControl = new FormControl<string>('', Validators.required);

    protected observe(): void {
        this.isValidated = true;
        this.isInProgress = true;

        if (this.emailControl.valid) {
            this.isInProgress = true;
            this._fanAlertRestService
                .subscribeFanAlert({
                    email: this.emailControl.value as string,
                    ...this.fanAlertParams,
                })
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(() => {
                    sessionStorage.setItem(this.itemStorageKey, `${this.fanAlertParams.objectId}`);
                    this.isInProgress = false;
                    this.isValidated = true;
                    this.isObserved = true;
                    this._changeDetectorRef.detectChanges();
                });
        }
    }

    public ngOnInit(): void {
        if (isPlatformBrowser(this._platformId)) {
            this.itemStorageKey = `fanAlert_${this.fanAlertParams.fanAlertType}_${this.fanAlertParams.objectId}`;
            this.isObserved = sessionStorage.getItem(this.itemStorageKey) === this.fanAlertParams.objectId;
            this.emailControl.reset(CookieHelper.getEmail(this.loggedUserCookie as string));
        }
    }

    protected onTooltipLoad(isLoading: boolean): void {
        this.isTooltipLoading = isLoading;
        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => this._changeDetectorRef.detectChanges());
        } else {
            this._changeDetectorRef.detectChanges();
        }
    }
}
