<div class="category-tree">
  <div>
    <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'wydarzenia']" eb-button ebType="secondary" [ebGhost]="true" (mouseenter)="activeCategory = null; activeSubcategory = null">
      {{'shared.category-mega-menu.allEvents' | transloco}}
    </a>
    <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug]" eb-button ebType="secondary" [ebGhost]="activeCategory !== category" *ngFor="let category of categories" (mouseenter)="activeCategory = category; activeSubcategory = null">
      <ng-container *ngTemplateOutlet="categoryTmpl; context:{ $implicit: category, hasIcon: true }" />
    </a>
  </div>
  <div>
    <div *ngFor="let category of categories" class="category-tree__subcategory" [ngClass]="{'category-tree__subcategory--active': category === activeCategory}">
      <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug]" eb-button ebType="secondary" [ebGhost]="activeSubcategory !== category" (mouseenter)="activeSubcategory = null">
        <span>{{'shared.category-mega-menu.allEvents' | transloco}}</span>
      </a>
      <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug]" *ngFor="let subcategory of category.subcategory" eb-button ebType="secondary" [ebGhost]="activeSubcategory !== subcategory" (mouseenter)="activeSubcategory = subcategory">
        <ng-container *ngTemplateOutlet="categoryTmpl; context:{ $implicit: subcategory, hasIcon: false }" />
      </a>
    </div>
  </div>
  <div>
    <div *ngIf="onTimeEvents.length > 0 && !activeCategory">
      <p class="h5">{{'shared.category-mega-menu.onTime' | transloco}}</p>
      <div class="category-tree__titles mt-3">
        <a *ngFor="let titleOnTime of onTimeEvents | slice:0:6; let i = index; let count = count"
          (click)="onClick($event, titleOnTime)"
          [href]="titleOnTime.linkTo ?? ([i18nService.isEnglishActive ? 'en' : '', titleOnTime.categoryName, titleOnTime.subcategoryName, titleOnTime.slug] | ebArrayToUrlString)"
          ebIntersection
          [ebOnlyFirst]="true"
          (intersecting)="onTimeEventIntersecting(titleOnTime, i, count)"
          >
          <img [src]="menuInited ? (titleOnTime.imageLandscape | media) : ''" [alt]="titleOnTime.slug"
            width="198" height="112">
        </a>
      </div>
    </div>
    <div *ngIf="activeCategory">
      <p *ngIf="activeCategory.titlesOnTime.length > 0" class="h5">{{'shared.category-mega-menu.onTime' | transloco}}</p>
      <div class="category-tree__titles mt-3">
        <img
          *ngFor="let titleOnTime of activeCategory.titlesOnTime; let i = index; let count = count"
          [src]="menuInited ? (titleOnTime.imageLandscape | media) : ''"
          [alt]="titleOnTime.slug"
          width="198"
          height="112"
          [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', activeCategory.slug, titleOnTime?.subcategorySlug, titleOnTime?.slug]"
          ebIntersection
          [ebOnlyFirst]="true"
          (intersecting)="categoryOnTimeEventIntersecting(titleOnTime, i, count)">
      </div>
    </div>
    @if ((!activeCategory && !activeSubcategory) || (activeCategory?.articles?.length || activeSubcategory?.articles?.length)) {
      <div class="eb-lg-visible mt-5">
        <p class="h5 mb-4"> {{ 'shared.articles.articlesHeader' | transloco }} </p>
        <eb-article-card-container [articles]="activeSubcategory?.articles || activeCategory?.articles || homePageArticles" [isMegaMenu]="true" [loading]="loadingCategories"/>
      </div>
    }
    <div class="mt-5">
      <div *ngIf="cities.length && !activeCategory && !activeSubcategory">
        <p class="h5">{{'shared.category-mega-menu.allEventsInCity' | transloco}}</p>
        <div class="category-tree__cities" *ngIf="cities.length > 0 && !activeCategory && !activeSubcategory">
          <span *ngFor="let city of cities | slice:0:visibleCitesLength">
            <a *ngIf="city" eb-button ebType="secondary" ebSize="small" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'miasto', city.slug]">{{city.name}}</a>
          </span>
        </div>
      </div>

      <div *ngFor="let category of categories">
        <div [ngClass]="(activeCategory === category) && !activeSubcategory ? '' : 'hidden'">
          <p class="h5" *ngIf="category.mostPopularCities?.length">{{'shared.category-mega-menu.allEventsInCity' | transloco}}</p>
          <div class="category-tree__cities">
          <span *ngFor="let mostPopularCity of category.mostPopularCities | slice:0:visibleCitesLength">
            <a *ngIf="mostPopularCity?.name" eb-button ebType="secondary" ebSize="small" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]">{{mostPopularCity.name}}</a>
          </span>
          </div>
        </div>
        <div *ngFor="let subcategory of category.subcategory" [ngClass]="activeSubcategory === subcategory ? '' : 'hidden'">
          <p class="h5" *ngIf="subcategory.mostPopularCities?.length">{{'shared.category-mega-menu.allEventsInCity' | transloco}}</p>
          <div class="category-tree__cities">
          <span *ngFor="let mostPopularCity of subcategory.mostPopularCities | slice:0:visibleCitesLength">
            <a *ngIf="mostPopularCity?.name" eb-button ebType="secondary" ebSize="small" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug, 'miasto', mostPopularCity.slug]">{{mostPopularCity.name}}</a>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #categoryTmpl let-category let-routerLink="routerLink" let-hasIcon="hasIcon">
  <svg-icon [key]="category?.icon | ebCategoryIcon" />
  {{category?.categoryName}}
  <svg-icon *ngIf="hasIcon" key="chevron-right" />
</ng-template>
