import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    DestroyRef,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    PLATFORM_ID,
    QueryList,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, startWith } from 'rxjs/operators';
import { DeviceService } from '../../../../device/src/lib/device.service';
import { EbCarouselItemDirective } from '../carousel-item.directive';

export enum CarouselNavigationDirections {
    RIGHT,
    LEFT,
}

@Component({
    selector: 'eb-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class EbCarouselComponent implements AfterViewInit, OnDestroy {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _deviceService = inject(DeviceService);

    @Input() fullWidthMobile = true;
    @Input() dark = false;
    @Input() isGallery = false;
    private _isLastItemInFrame = true;
    private _isFirstItemInFrame = true;
    private _lastItemIntersectionObserver: IntersectionObserver | undefined;
    private _firstItemIntersectionObserver: IntersectionObserver | undefined;
    private _numberOfVisibleItems = 0;
    private _destroyRef = inject(DestroyRef);
    private _itemWidth = 0;
    public isNavEnable = false;

    readonly desktopRemSize = 16;
    readonly CarouselNavigationDirections = CarouselNavigationDirections;

    @HostListener('window:resize', ['$event'])
    protected onResize(): void {
        if (!this._itemWidth) {
            this._countItemWidth();
        }
        this._countVisibleItems();
    }

    public set isLastItemInFrame(is: boolean) {
        if (is !== this._isLastItemInFrame) {
            this._isLastItemInFrame = is;
            this._changeDetectorRef.markForCheck();
        }
    }

    public get isLastItemInFrame(): boolean {
        return this._isLastItemInFrame;
    }

    public set isFirstItemInFrame(is: boolean) {
        if (is !== this._isFirstItemInFrame) {
            this._isFirstItemInFrame = is;
            this._changeDetectorRef.markForCheck();
        }
    }

    public get isFirstItemInFrame(): boolean {
        return this._isFirstItemInFrame;
    }

    protected get prevEnabled(): boolean {
        return this.isNavEnable && !this.isFirstItemInFrame;
    }

    protected get nextEnabled(): boolean {
        return this.isNavEnable && !this.isLastItemInFrame;
    }

    protected get numberOfItemsToScrollBy(): number {
        return this._numberOfVisibleItems - 1;
    }

    private get _gap(): number {
        return this.desktopRemSize;
    }

    @ViewChild('carouselFrameRef') readonly carouselFrameRef!: ElementRef;

    @ContentChildren(EbCarouselItemDirective) private readonly _carouselItems =
        new QueryList<EbCarouselItemDirective>();

    constructor() {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed()).subscribe((isMobile: boolean) => {
            this.isNavEnable = !isMobile;
        });
    }

    public ngAfterViewInit(): void {
        this._carouselItems.changes
            .pipe(
                startWith(null),
                filter(() => !!this._carouselItems.length),
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe(() => {
                this._createIntersectionObservers();
            });

        if (!this.isGallery) {
            this._countItemWidth();
            this._countVisibleItems();
        }
    }

    public ngOnDestroy(): void {
        this._destroyIntersectionObservers();
    }

    public goTo(id: string): void {
        const item = this._carouselItems.find((i) => i.ebCarouselItemId === id);
        if (item) {
            (item.elementRef.nativeElement as HTMLDivElement).scrollIntoView();
        }
    }

    private _getItem(idx: number): HTMLDivElement | null {
        return this._carouselItems.get(idx)?.elementRef.nativeElement || null;
    }

    private _getWidth(item: Element | null): number {
        if (item && isPlatformBrowser(this._platformId)) {
            return item.clientWidth + parseInt(window.getComputedStyle(item).marginRight, 10);
        } else {
            return 0;
        }
    }

    public reset(): void {
        this.goTo(this._carouselItems.first.ebCarouselItemId);
    }

    public scrollBy(itemsToScrollBy: number, direction: CarouselNavigationDirections): void {
        if (this.isGallery && !this._itemWidth) {
            this._countItemWidth();
            this._numberOfVisibleItems = 1;
        }
        const frameDiv = this.carouselFrameRef.nativeElement as HTMLDivElement;

        let delta = itemsToScrollBy * (this._itemWidth + this._gap);
        if (direction === CarouselNavigationDirections.LEFT) {
            delta = -delta;
        }

        if (!this.nextEnabled && direction === CarouselNavigationDirections.RIGHT) {
            frameDiv.scrollBy({ left: -frameDiv.scrollLeft, behavior: 'instant' });
        } else if (!this.prevEnabled && direction === CarouselNavigationDirections.LEFT) {
            const maxScrollLeft = frameDiv.scrollWidth - frameDiv.clientWidth;
            frameDiv.scrollBy({ left: maxScrollLeft - frameDiv.scrollLeft, behavior: 'instant' });
        } else {
            frameDiv.scrollBy({ left: delta, behavior: 'smooth' });
        }
    }

    private _destroyIntersectionObservers(): void {
        if (this._lastItemIntersectionObserver) {
            this._lastItemIntersectionObserver.disconnect();
        }

        if (this._firstItemIntersectionObserver) {
            this._firstItemIntersectionObserver.disconnect();
        }
    }

    private _createIntersectionObservers(): void {
        if (isPlatformBrowser(this._platformId)) {
            const options = {
                root: this.carouselFrameRef.nativeElement,
                rootMargin: '0px',
                threshold: 0.5,
            };

            this._lastItemIntersectionObserver = new IntersectionObserver((entries) => {
                this.isLastItemInFrame = entries.some((e) => e.isIntersecting);
            }, options);

            this._firstItemIntersectionObserver = new IntersectionObserver((entries) => {
                this.isFirstItemInFrame = entries.some((e) => e.isIntersecting);
            }, options);

            if (this._carouselItems.last) {
                this._lastItemIntersectionObserver.observe(this._carouselItems.last.elementRef.nativeElement);
            }

            if (this._carouselItems.first) {
                this._firstItemIntersectionObserver.observe(this._carouselItems.first.elementRef.nativeElement);
            }
        }
    }

    private _countItemWidth(): void {
        this._itemWidth = this._getWidth(this._getItem(0));
    }

    private _countVisibleItems(): void {
        this._numberOfVisibleItems = Math.round(
            this.carouselFrameRef.nativeElement.offsetWidth / (this._itemWidth + this._gap),
        );
    }
}
