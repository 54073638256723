<button [attr.aria-label]="'shared.share-button.share' | transloco" eb-button ebType="secondary" [ebGhost]="ebGhost"  [ebSize]="ebSize" [ebOnlyIcon]="true" (click)="share()" class="width-100" [ngClass]="{'eb-share-btn--active': isActive}">
  <svg-icon class="icon--lg-root-relative" size="lgr" key="share" />
</button>
<div class="eb-share-option eb-share-option--facebook">
  <button eb-button  ebSize="small" [ebOnlyIcon]="true" (click)="shareFb()" >
    <svg-icon class="icon--md-root-relative" size="mdr" key="brand-facebook" />
  </button>
</div>
<div  class="eb-share-option eb-share-option--link" [ngClass]="{'eb-share-option--active': hasCopied}">
  <button eb-button  ebSize="small" (click)="shareLink()">
    <span class="eb-share-option--link__msg" >{{'shared.share-button.linkCopied' | transloco}}</span> <svg-icon class="icon--md-root-relative" size="mdr" key="link" />
  </button>
</div>
<div class="eb-share-option eb-share-option--email">
  <button eb-button  ebSize="small" [ebOnlyIcon]="true" (click)="shareEmail()">
    <svg-icon class="icon--md-root-relative" size="mdr" key="envelope" />
  </button>
</div>
