import { CalendarData } from './calendar.component';
import { CalendarHelper } from './calendar.helper';

export class CalendarMapper {
    static map<T>(items: T[], dateGetter: (i: T) => Date): CalendarData<T> {
        const result = new Map<string, T[]>();

        items.forEach((titleEvent) => {
            const key = CalendarHelper.createKey(dateGetter(titleEvent));

            if (!result.has(key)) {
                result.set(key, []);
            }
            result.get(key)?.push(titleEvent);
        });

        return result;
    }
}
