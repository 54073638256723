<ng-container *ngIf="isLoading; then skeletonView; else contentView" />

<ng-template #skeletonView>
  <div class="eb-page-header">
    <div>
      <h1 class="eb-page-title eb-page-title--huge eb-skeleton mb-3"></h1>
      <div class="eb-page-header__subtitle">
        <span class="mr-3 eb-skeleton eb-skeleton--inline w-150" style="width: 150px"></span>
        <span class="mr-3 eb-skeleton eb-skeleton--inline w-150" style="width: 150px"></span>
      </div>
    </div>
    <div class="eb-page-header__buttons" [ngClass]="{ 'eb-page-header__buttons--row': buttonsRow }">
      <div class="eb-page-header__actions" *ngIf="shareButtonVisible || heartButtonVisible || bellButtonVisible">
        <span class="eb-skeleton eb-skeleton--btn" [ngClass]="{ 'eb-btn-lg': isLargeScreen }"></span>
        <span class="eb-skeleton eb-skeleton--btn" [ngClass]="{ 'eb-btn-lg': isLargeScreen }"></span>
        <span class="eb-skeleton eb-skeleton--btn" [ngClass]="{ 'eb-btn-lg': isLargeScreen }"></span>
      </div>
      <div class="eb-page-header__shortcut">
        <span class="eb-skeleton eb-skeleton--btn w-150" [ngClass]="{ 'eb-btn-lg': isLargeScreen }"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentView>
  <div class="eb-page-header">
    <div>
      <h1 class="eb-page-title eb-page-title--huge mb-3"><ng-content /></h1>
      <div class="eb-page-header__subtitle" [ngClass]="{ 'eb-page-header__subtitle--line-spacing': isTitlePagePromotion }">
        <ng-content select="[eb-page-header-subtitle]" />
      </div>
    </div>
    <div class="eb-page-header__buttons" [ngClass]="{ 'eb-page-header__buttons--row': buttonsRow }">
      <div class="eb-page-header__actions" *ngIf="shareButtonVisible || heartButtonVisible || bellButtonVisible">
        <eb-share-button *ngIf="shareButtonVisible" [ebSize]="buttonSize" [ebGhost]="buttonisGhost" [shareData]="shareData" />
        <eb-heart-button
          *ngIf="heartButtonVisible"
          [ebSize]="buttonSize"
          [ebGhost]="buttonisGhost"
          [id]="heartId"
          [heartType]="heartType"
          [likes]="heartTotal"
          [strokeWidth]="2"
          (likeClicked)="onLikeClick()"
        />
        <eb-fan-alert *ngIf="bellButtonVisible" [isGhostButton]="buttonisGhost" [fanAlertParams]="fanAlertParams" [size]="buttonSize" />
      </div>
      <div class="eb-page-header__shortcut">
        <eb-affix [ebDisabled]="isLargeScreen">
          <ng-content select="[eb-page-header-shortcut]" />
        </eb-affix>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #subtitleSkeleton>
  <span class="mr-3 eb-skeleton eb-skeleton--inline w-150"></span>
  <span class="mr-3 eb-skeleton eb-skeleton--inline w-150"></span>
</ng-template>
<ng-template #subtitleContent />

<ng-template #actionsSkeleton>
  <span class="eb-skeleton eb-skeleton--btn"></span>
  <span class="eb-skeleton eb-skeleton--btn"></span>
  <span class="eb-skeleton eb-skeleton--btn"></span>
</ng-template>
<ng-template #actionsContent />

<ng-template #shortcutSkeleton>
  <span class="eb-skeleton eb-skeleton--btn w-150"></span>
</ng-template>
<ng-template #shortcutContent />
