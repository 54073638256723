import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { auditTime, distinctUntilChanged } from 'rxjs/operators';
import { ITreeOption } from '../tree-select/tree-option.interface';
@Component({
    selector: 'eb-tree-option',
    templateUrl: './tree-option.component.html',
    styleUrls: ['./tree-option.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EbTreeOptionComponent<T> implements OnInit {
    private readonly _elementRef = inject(ElementRef);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private _destroyRef = inject(DestroyRef);

    private readonly _mouseState$ = new BehaviorSubject<boolean>(false);
    isOpen = false;

    readonly overlayPositions = [
        new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'top' }),
    ];

    origin: CdkOverlayOrigin;

    protected get hasAnySuboptions(): boolean {
        return !!this.option && !!this.option.items && this.option.items.length > 0;
    }

    @Input({ required: true }) option!: ITreeOption<T>;
    @Input() template: TemplateRef<any> | null = null;
    @Input() flat = false;
    @Input() allFromCategory = '';

    @Output() readonly selected = new EventEmitter<ITreeOption<T>>();

    @HostBinding('class.eb-tree-option') protected get isTreeOption(): boolean {
        return true;
    }

    @HostBinding('class.eb-tree-option--active') protected get isActive(): boolean {
        return this.isOpen;
    }

    @ViewChild('container', { static: false }) readonly inputContainer!: ElementRef<HTMLInputElement>;

    @HostListener('click') protected click(): void {
        this.open();
    }

    @HostListener('mouseenter') protected mouseenter(): void {
        if (this.hasAnySuboptions && !this.flat) {
            this.open();
        }
    }

    @HostListener('mouseleave') protected mouseleave(): void {
        this.close();
    }

    constructor() {
        this.origin = new CdkOverlayOrigin(this._elementRef);
    }

    public ngOnInit(): void {
        this._mouseState$
            .pipe(auditTime(50), distinctUntilChanged(), takeUntilDestroyed(this._destroyRef))
            .subscribe((isMouseOver) => {
                this.isOpen = isMouseOver;
                this._changeDetectorRef.markForCheck();
            });
    }

    protected onOverlayOutsideClick(event: MouseEvent): void {
        if (!(this.inputContainer.nativeElement as any).contains(event.target)) {
            this.close();
        }
    }

    protected select(item: ITreeOption<T>): void {
        if (this.selected.observed) {
            this.selected.emit(item);
        }
    }

    protected open(): void {
        this._mouseState$.next(true);
    }

    protected close(): void {
        this._mouseState$.next(false);
    }

    protected onMouseenter(): void {
        this.open();
    }

    protected onMouseleave(): void {
        this.close();
    }
}
