import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { EbTopbarComponent } from './topbar/topbar.component';

@NgModule({
    imports: [CommonModule, UiButtonsModule, RouterModule],
    declarations: [EbTopbarComponent],
    exports: [EbTopbarComponent],
})
export class UiTopbarsModule {}
