<main>
  @if(titlePage) {
    <img *ngIf="englishDescription" src="https://registration-taylorswift.ebilet.pl/.webrock/img/empty.gif" width="0" height="0" />
    <eb-breadcrumbs *ngIf="!titlePage?.branding" [breadcrumbs]="breadcrumbs" />
    <div class="eb-title-page__tags mb-3" *ngIf="!titlePage?.branding">
      <ng-container *ngIf="isLoading; then tagsSkeleton; else tagContent" />
      <ng-template #tagsSkeleton>
        <span *ebRepeat="3" class="eb-tag mr-2 eb-skeleton eb-skeleton--inline" style="width: 100px"></span>
      </ng-template>
      <ng-template #tagContent>
        <span class="eb-tag mr-2" *ngFor="let tag of titlePage?.tags">{{ tag }}</span>
      </ng-template>
    </div>

    <eb-page-header
      [isLoading]="isLoading"
      [fanAlertParams]="fanAlertParams"
      [heartId]="titlePage?.id"
      [heartType]="'title'"
      [heartTotal]="titlePage?.hearts"
      [shareData]="shareData"
      [shareButtonVisible]="!titlePage?.branding"
      [bellButtonVisible]="!titlePage?.branding"
      [heartButtonVisible]="!titlePage?.branding"
      [isTitlePagePromotion]="titlePage?.isPromotion"
      (likeClicked)="addToWishlist()"
    >
      {{ titlePage?.title }}
      <ng-container eb-page-header-subtitle>
        <span *ngIf="titlePage?.isPromotion" class="eb-promotion-label eb-title-page__promotion-label text-base mr-4">{{
          'promotion.promotion' | transloco
        }}</span>
        <span *ngIf="titlePage?.eventsDateFrom && !titlePage.isEventDateHidden" class="mr-3">
          <svg-icon class="icon-md-relative" size="mde" key="calendar-month" class="mr-2" />
          {{ titlePage?.eventsDateFrom | ebDate: titlePage?.eventsDateTo }}
        </span>
        <span *ngIf="titlePage?.places?.length">
          <svg-icon class="icon-md-relative" size="mde" key="location-pin" class="mr-2"></svg-icon>
          <span>{{ citiesText }} {{ citiesOther ? ('titlePage.andOther' | transloco) : '' }}</span>
        </span>
        <p class="mt-2" [ngClass]="{ 'eb-size--tiny': isMobile }">
          <span class="fw-bold"
            >{{ ('titlePage.organizer' | transloco) !== 'titlePage.organizer' ? ('titlePage.organizer' | transloco) : 'Organizator' }}: </span
          >{{ titlePage?.organizer?.name }}
        </p>
      </ng-container>

      <ng-container eb-page-header-shortcut *ngIf="showButtonPrice">
        <span class="eb-skeleton eb-skeleton--btn" *ngIf="isEventsLoading" eb-button [ebSize]="buttonSize" style="width: 100%"></span>
        <span
          *ngIf="hasEvents"
          eb-button
          class="justify-content-center"
          ebType="primary"
          [ebSize]="buttonSize"
          ebNavigateToHtmlElement
          [elementId]="'bilety'"
          [ngClass]="{ 'eb-promotion-button': titlePage?.isPromotion }"
        >
          <svg-icon key="ticket" /> {{ 'titlePage.buyTicketsBtn' | transloco }}
          <span *ngIf="titlePage?.isPromotion" class="eb-on-promotion-text eb-md-hidden">{{ 'promotion.onPromotion' | transloco }}</span>
        </span>
      </ng-container>
    </eb-page-header>

    <div class="eb-title-page__body md:eb-grid md:gap-6 md:eb-align-items-start">
      <div class="eb-title-page__nav md:sticky md:top-0 md:py-5">
        <ng-container *ngIf="isLoading; then mainImageSkeletonView; else mainImageContentView"> </ng-container>
        <eb-sidenav *ngIf="!titlePage?.branding" class="eb-md-visible">
          <eb-sidenav-item *ngIf="hasEvents" ebFragment="bilety">{{ 'titlePage.ticketsHeader' | transloco }}</eb-sidenav-item>
          <eb-sidenav-item *ngIf="hasDetails" ebFragment="szczegoly-wydarzenia">{{ 'titlePage.detailsHeader' | transloco }}</eb-sidenav-item>
          <eb-sidenav-item *ngIf="titlePage?.description" ebFragment="opis">{{ 'titlePage.descriptionHeader' | transloco }}</eb-sidenav-item>
          <eb-sidenav-item *ngIf="hasArtists" ebFragment="artysci">{{ 'titlePage.artistsHeader' | transloco }}</eb-sidenav-item>
          <eb-sidenav-item *ngIf="hasPlaces" ebFragment="miejsce">{{ 'titlePage.placesHeader' | transloco }}</eb-sidenav-item>
        </eb-sidenav>
      </div>
      <div class="eb-title-page__content md:py-5">
        <eb-gallery
          *ngIf="isLoading || hasGallery"
          [isLoading]="isLoading"
          [titleGallery]="titlePage?.gallery"
          [parentPageTitle]="titlePage?.title"
        />

        @if (titlePage.isPromotion) {
          <section id="okazje" class="eb-page-section">
            <eb-double-cards [type]="doubleCardsTypes.DEAL_ZONE" />
          </section>
        }

        <section
          *ngIf="isEventsLoading || hasEvents"
          id="bilety"
          class="eb-page-section"
          [ngClass]="listViewType === listViewTypes.DAY ? 'eb-page-section--no-border eb-page-section--no-bottom-padding' : ''"
          ebSectionIntersect
        >
          <eb-title-event-list
            [listViewType]="listViewType"
            [groupEvents]="true"
            [isLoading]="isEventsLoading"
            [titleEventsGroups]="titleEventsGroup"
            [isThumbnailHidden]="true"
            [partnerId]="titlePage?.branding?.partnerId"
            [tokenCheckSum]="tokenCheckSum"
            [virtualPageView]="virtualPageView"
            [artists]="artists"
            [microDescription]="titlePage?.seo?.microDescription"
            [titleUrl]="titleUrl"
            [isTitlePage]="true"
            [showDelayedPayments]="titlePage?.loanInformationModuleEnabled"
            [showFanAlert]="showFanAlert"
            [isEventDateHidden]="titlePage.isEventDateHidden"
          />
        </section>
        <section *ngIf="hasDetails" id="szczegoly-wydarzenia" class="eb-page-section">
          <h2>{{ 'titlePage.detailsHeader' | transloco }}</h2>
        </section>
        <section *ngIf="titlePage?.description" id="opis" class="eb-page-section">
          <eb-page-description
            [isLoading]="isLoading"
            [title]="'titlePage.descriptionHeader' | transloco"
            [description]="
              englishDescription ? (i18nService.isEnglishActive ? englishDescription : polishDescription) : titlePage!.description
            "
            [extendedDescription]="englishDescription ? undefined : titlePage!.extendedDescription"
          />
        </section>

        <!-- polecane artykuły dla tytułu -->
        <section *ngIf="articles.length" class="eb-page-section" [ngClass]="{ 'eb-page-section--no-border': articles.length >= 2 }">
          <eb-article-card-container [articles]="articles" [showCarousel]="articles.length > 2" [loading]="isArticlesLoading" />
        </section>

        <ng-container *ngIf="!titlePage?.branding">
          <section
            *ngIf="isLoading || hasArtists"
            id="artysci"
            class="eb-page-section eb-page-section--no-border"
            ebIntersection
            [ebOnlyFirst]="true"
            (intersecting)="artistSkeletonVisible = true"
          >
            <ng-container *ngIf="isLoading; then artistCarouselSkeletonView; else artistCarouselContentView" />
          </section>
          <section
            *ngIf="isLoading || hasPlaces"
            id="miejsce"
            class="eb-page-section eb-page-section--no-border"
            ebIntersection
            [ebOnlyFirst]="true"
            (intersecting)="placeSkeletonVisible = true"
          >
            <ng-container *ngIf="isLoading; then titleCardCarouselSkeletonView; else titleCardCarouselContentView" />
          </section>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="!titlePage?.branding">
      <section class="eb-page-section eb-page-section--no-border" *ngIf="proposedTitles.length">
        <h2>{{ 'titlePage.familiarTitlesHeader' | transloco }}</h2>
        <eb-carousel>
          <eb-title-card
            *ngFor="let titleModel of proposedTitles; let i = index"
            ebCarouselItem
            ebIntersection
            [ebOnlyFirst]="true"
            (intersecting)="
              aggregatedGa4EventsService.titleCardIntersecting(ga4ListNames.ProposedTitles, titleModel, i, ga4ListNames.ProposedTitles, true)
            "
            [title]="titleModel"
            [withOverlay]="true"
            [listName]="ga4ListNames.ProposedTitles"
            [promotionName]="ga4ListNames.ProposedTitles"
            [index]="i"
          />
        </eb-carousel>
      </section>

      <section class="eb-page-section eb-page-section--no-border" *ngIf="otherTitlesByOrganizer.length">
        <div class="flex">
          <h2>{{ 'titlePage.otherTitlesByOrganizer' | transloco }}</h2>
          <ng-container
            *ngIf="!isMobile"
            [ngTemplateOutlet]="organizerButton"
            [ngTemplateOutletContext]="{ organizer: titlePage?.organizer?.slug }"
          />
        </div>
        <eb-carousel>
          <eb-title-card
            *ngFor="let titleModel of otherTitlesByOrganizer; let i = index"
            ebCarouselItem
            ebIntersection
            [ebOnlyFirst]="true"
            (intersecting)="
              aggregatedGa4EventsService.titleCardIntersecting(
                ga4ListNames.OrganizerTitles,
                titleModel,
                i,
                ga4ListNames.OrganizerTitles,
                true
              )
            "
            [title]="titleModel"
            [withOverlay]="true"
            [listName]="ga4ListNames.OrganizerTitles"
            [promotionName]="ga4ListNames.OrganizerTitles"
            [index]="i"
          />
        </eb-carousel>
        <ng-container
          *ngIf="isMobile"
          [ngTemplateOutlet]="organizerButton"
          [ngTemplateOutletContext]="{ organizer: titlePage?.organizer?.slug }"
        />
      </section>

      <section class="eb-page-section eb-page-section--no-border" *ngIf="dontMissTiles.length === 5">
        <eb-dont-miss [tiles]="dontMissTiles" />
      </section>

      <section *ngIf="titlePage?.seoBoxLinks?.length" class="eb-page-section">
        <eb-seo-section-link [seoBoxLinks]="titlePage?.seoBoxLinks" />
      </section>
    </ng-container>
    <eb-title-dont-miss *ngIf="showDontMissSection" />
  }
</main>

<ng-template #organizerButton let-organizer="organizer">
  <a
    eb-button
    ebType="secondary"
    [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'organizatorzy', organizer]"
    [ebGhost]="true"
    class="ml-auto"
    >{{ 'shared.seeAll' | transloco }} <svg-icon key="chevron-right" />
  </a>
</ng-template>

<ng-template #mainImageContentView>
  <picture>
    <source media="(min-width: 768px)" [srcset]="titlePage?.imagePortrait | media" />
    <img
      (click)="showGallery()"
      class="eb-page-main-img eb-page-main-img-md--portrait md:mb-4 eager-load"
      [ngClass]="{ 'eb-page-main-img--gallery-link': galleryElement }"
      [src]="titlePage?.imageLandscape | media"
      [alt]="titlePage?.title"
    />
  </picture>
</ng-template>

<ng-template #mainImageSkeletonView>
  <div class="eb-page-main-img eb-page-main-img-md--portrait eb-skeleton"></div>
</ng-template>

<ng-template #artistCarouselContentView>
  <h2>{{ 'titlePage.artistsHeader' | transloco }}</h2>
  <eb-carousel>
    <eb-artist-card *ngFor="let artist of titlePage?.artists" ebCarouselItem [artist]="artist" />
  </eb-carousel>
</ng-template>

<ng-template #artistCarouselSkeletonView>
  <h2 *ngIf="artistSkeletonVisible" class="eb-skeleton" style="width: 120px"></h2>
  <eb-carousel *ngIf="artistSkeletonVisible">
    <eb-artist-card *ebRepeat="4" ebCarouselItem [viewSkeleton]="true" />
  </eb-carousel>
</ng-template>

<ng-template #titleCardCarouselContentView>
  <ng-container *ngIf="titlePage?.places">
    <h2>{{ 'titlePage.placesHeader' | transloco }}</h2>
    <eb-carousel>
      <eb-place-card
        *ngFor="let place of titlePage?.places"
        ebCarouselItem
        [place]="place"
        [showMapLink]="isCookiesConsentAccepted"
        (showMap)="showMap(place)"
      />
    </eb-carousel>
    <div *ngIf="lat && lng && isCookiesConsentAccepted" class="pt-5">
      <h2>{{ (isMobile ? 'titlePage.bookYourStayMobile' : 'titlePage.bookYourStayDesktop') | transloco }}</h2>
      <eb-map
        [zoom]="mapZoom"
        [lat]="lat"
        [lng]="lng"
        [poi]="pois"
        [disableHotels]="false"
        [hideFilters]="false"
        [hideGuestPicker]="false"
        [hideCheckInOut]="false"
        [campaign]="campaignTypes.TITLE"
        [markerImage]="titlePage?.seo?.syneriseMeta?.ogImage"
      />
    </div>
  </ng-container>
</ng-template>

<ng-template #titleCardCarouselSkeletonView>
  <h2 *ngIf="placeSkeletonVisible" class="eb-skeleton" style="width: 120px"></h2>
  <eb-carousel *ngIf="placeSkeletonVisible">
    <eb-place-card *ebRepeat="4" [viewSkeleton]="true" ebCarouselItem />
  </eb-carousel>
</ng-template>
