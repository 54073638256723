export interface JsonLd {
    '@context': 'https://schema.org';
}

export interface JsonListItem extends JsonLd {
    '@type': 'ItemList';
    itemListElement: JsonItemElement[];
}

export interface JsonItemElement {
    '@type': 'ListItem';
    position: number;
    item: JsonItemData;
}

export interface JsonItemData {
    '@type': 'Event';
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode';
    eventStatus: 'https://schema.org/EventScheduled';
    name?: string | null;
    startDate: string;
    endDate: string;
    description?: string | null;
    url?: string;
    location: JsonLocation;
    image: string[];
    // offers: Offers; do odkomentowania jak ceny wrócą na portal
    performer?: JsonPerson[] | JsonPerson;
    organizer?: JsonOrganizer;
}

export interface JsonLocation {
    '@type': 'Place';
    name: string | null;
    address: JsonAddress;
}

export interface JsonAddress {
    '@type': 'PostalAddress';
    streetAddress?: string;
    addressLocality?: string;
    postalCode?: string;
    addressRegion?: string | null;
    addressCountry?: string;
}

export interface JsonOffers {
    '@type': 'Offer';
    validFrom: string;
    availability: string;
    url: string;
}

export interface JsonPerson {
    '@type': 'Person';
    name: string;
}

export interface JsonOrganizer {
    '@type': 'Organization';
    name: string;
    url: string;
}

export interface JsonEvent extends JsonLd, JsonItemData {}

export const JsonTypes = {
    ORGANIZATION: 'Organization',
    PERSON: 'Person',
    OFFER: 'Offer',
    POSTAL_ADDRESS: 'PostalAddress',
    PLACE: 'Place',
    EVENT: 'Event',
    ITEM_LIST: 'ItemList',
    LIST_ITEM: 'ListItem',
} as const;
