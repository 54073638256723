export class CookieHelper {
    static getCustomerId(cookieValue: string): string {
        if (cookieValue) {
            return Object.keys(JSON.parse(cookieValue).Values)[0];
        }

        return '';
    }

    static getEmail(cookieValue: string): string | null {
        if (cookieValue) {
            return (JSON.parse(cookieValue) as ICookieLoggedUser).e;
        }

        return null;
    }
}

interface ICookieLoggedUser {
    n: string;
    e: string;
}
