import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewEncapsulation,
    inject,
} from '@angular/core';

@Component({
    selector: 'eb-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'block' },
})
export class EbTopbarComponent {
    public elementRef = inject(ElementRef);

    @Input() backgroundColor: string | undefined;
    @HostBinding('class.eb-topbar-menu--active') @Input() isActive = false;
}
