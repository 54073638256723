import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
    inject,
    PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceService } from 'libs/device/src/lib/device.service';
import { EbDoubleCardsDialogComponent } from './double-cards-dialog/double-cards-dialog.component';
import { DoubleCardsTypes, DoubleCardsWindowTypes } from './double-cards.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'eb-double-cards',
    templateUrl: './double-cards.component.html',
    styleUrls: ['./double-cards.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbDoubleCardsComponent implements OnInit {
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _deviceService = inject(DeviceService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _renderer = inject(Renderer2);
    private _destroyRef = inject(DestroyRef);

    @Input() type!: DoubleCardsTypes;

    isDialogOpen = false;
    isDoubleCardsDrawerVisible = false;
    isMobile = false;
    openDetailsType: DoubleCardsTypes | null = null;
    readonly doubleCardsTypes = DoubleCardsTypes;
    readonly doubleCardsWindowTypes = DoubleCardsWindowTypes;
    @ViewChild(EbDoubleCardsDialogComponent) readonly dialogComponent: EbDoubleCardsDialogComponent | undefined;

    public ngOnInit(): void {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile) => {
            this.isMobile = isMobile;
            this._changeDetectorRef.markForCheck();
        });
    }

    protected openDialog(type: DoubleCardsTypes | null): void {
        this.isDialogOpen = true;
        this.openDetailsType = type;
    }

    protected closeDialog(): void {
        this._renderer.addClass(this.dialogComponent?.dialog?.nativeElement, 'fadeout-animation');

        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => {
                this.isDialogOpen = false;
                this.openDetailsType = null;
                this._changeDetectorRef.markForCheck();
            }, 500);
        } else {
            this.isDialogOpen = false;
            this.openDetailsType = null;
            this._changeDetectorRef.markForCheck();
        }
    }

    protected openDrawer(type: DoubleCardsTypes | null): void {
        if (this.isMobile) {
            this.openDetailsType = type;
            this.isDoubleCardsDrawerVisible = true;
        }
    }
}
