<div #container class="eb-tree-option__label" (click)="select(option)">
  <ng-container *ngTemplateOutlet="template ? template : labelTmpl; context:{ $implicit: option, flat: flat }" />
</div>
<svg-icon *ngIf="!flat && hasAnySuboptions" class="ml-auto" key="chevron-right" />
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayMinWidth]="container.clientWidth"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="close()"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
>
  <div class="eb-overlay-panel hm-60 p-0" (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()">
    <div class="eb-tree-option" (click)="select(option)">
      {{'libs.tree-option.allFrom' | transloco}} {{option.label}}{{allFromCategory}}
    </div>
    <div class="eb-tree-option__divider"></div>
    <div class="eb-tree-option" *ngFor="let item of option.items" (click)="select(item)">
      <ng-container *ngTemplateOutlet="template ? template : labelTmpl; context:{ $implicit: item, flat: flat }" />
    </div>
  </div>
</ng-template>

<ng-template #labelTmpl let-option>
  <ng-container *ngIf="flat && option.parent">{{option.parent.label}} / </ng-container>
  {{option.label}}
</ng-template>
