import { ILandingLocationPageEvents, ILandingLocationPageTitles } from '../rest-api/models/landing-page.model';
import { TitleEvent } from '../rest-api/models/title-page.model';
import { Title } from '../rest-api/models/title.model';

export class TypeHelper {
    static isTitleEvent(obj: unknown): obj is TitleEvent {
        return typeof obj === 'object' && obj !== null && 'webShopMaxDelay' in obj;
    }

    static isTitle(obj: unknown): obj is Title {
        return typeof obj === 'object' && obj !== null && 'manualOrder' in obj;
    }

    static isILandingLocationPageTitles(obj: any): obj is ILandingLocationPageTitles {
        return (
            ('titles' in obj && obj.titles && obj.titles.length > 0 && TypeHelper.isTitle(obj.titles[0])) ||
            ('titlesInRange' in obj &&
                obj.titlesInRange &&
                obj.titlesInRange.length > 0 &&
                TypeHelper.isTitle(obj.titlesInRange[0])) ||
            ('titlesFeatured' in obj &&
                obj.titlesFeatured &&
                obj.titlesFeatured.length > 0 &&
                TypeHelper.isTitle(obj.titlesFeatured[0]))
        );
    }

    static isILandingLocationPageEvents(obj: any): obj is ILandingLocationPageEvents {
        return (
            ('events' in obj && obj.events && obj.events.length > 0 && TypeHelper.isTitleEvent(obj.events[0])) ||
            ('eventsInRange' in obj &&
                obj.eventsInRange &&
                obj.eventsInRange.length > 0 &&
                TypeHelper.isTitle(obj.eventsInRange[0])) ||
            ('eventsFeatured' in obj &&
                obj.eventsFeatured &&
                obj.eventsFeatured.length > 0 &&
                TypeHelper.isTitle(obj.eventsFeatured[0]))
        );
    }
}
