import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnInit,
    Output,
} from '@angular/core';
import { finalize, interval, Subscription, take, takeWhile } from 'rxjs';
import { differenceInSeconds } from 'date-fns';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'eb-minutes-timer',
    templateUrl: './minutes-timer.component.html',
    styleUrls: ['./minutes-timer.component.less'],
})
export class MinutesTimerComponent implements OnInit {
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _ngZone = inject(NgZone);
    private readonly _cdr = inject(ChangeDetectorRef);
    private _countdownStart!: number;
    private _timerSubscription!: Subscription;
    private _timeLeft!: number;
    public remainingMinutes!: string;
    public remainingSeconds!: string;
    @Input() public endDate!: Date;

    @Output() public timerEnded: EventEmitter<boolean> = new EventEmitter<boolean>();

    public ngOnInit(): void {
        this._countdownStart = differenceInSeconds(this.endDate, new Date());
        this._timeLeft = this._countdownStart;
        this._startTimer();
    }

    private _startTimer(): void {
        this._ngZone.runOutsideAngular(() => {
            this._timerSubscription = interval(1000)
                .pipe(
                    take(this._countdownStart),
                    takeWhile(() => this._timeLeft > 0),
                    takeUntilDestroyed(this._destroyRef),
                    finalize(() => this.timerEnded.emit(true)),
                )
                .subscribe(() => {
                    this._timeLeft -= 1;
                    this.remainingMinutes = Math.floor(this._timeLeft / 60).toString();
                    this.remainingSeconds =
                        this._timeLeft % 60 >= 10
                            ? (this._timeLeft % 60).toString()
                            : '0' + (this._timeLeft % 60).toString();

                    this._cdr.detectChanges();
                });
        });
    }
}
