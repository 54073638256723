import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiCarouselModule } from '@e-bilet/ui-carousel';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { UiScrollbarModule } from '@e-bilet/ui-scrollbar';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { EbArticleCardContainerComponent } from '../lib/article-card-container/article-card-container.component';
import { EbArticleCardComponent } from './article-card/article-card.component';

@NgModule({
    imports: [
        CommonModule,
        UiButtonsModule,
        UiPipeModule,
        RouterModule,
        UiCarouselModule,
        UiScrollbarModule,
        SvgIconComponent,
    ],
    declarations: [EbArticleCardComponent, EbArticleCardContainerComponent],
    exports: [EbArticleCardComponent, EbArticleCardContainerComponent],
})
export class UiArticleCardModule {}
