import { isPlatformBrowser } from '@angular/common';
import { DestroyRef, Directive, Input, OnDestroy, OnInit, PLATFORM_ID, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EbDrawerComponent } from 'libs/ui-drawer/src/lib/drawer/drawer.component';
import {
    CarouselNavigationDirections,
    EbCarouselComponent,
} from '../../../../../../../libs/ui-carousel/src/lib/carousel/carousel.component';

@Directive({
    selector: '[ebGalleryKeyboardNavigation]',
})
export class EbGalleryKeyboardNavigationDirective implements OnInit, OnDestroy {
    private readonly _hostElement = inject(EbCarouselComponent);
    private readonly _renderer = inject(Renderer2);
    private readonly _platformId = inject(PLATFORM_ID);
    private _destroyRef = inject(DestroyRef);

    private _keydownEventListener: (() => void) | null = null;
    private _currentSpaceNavigationDirection: CarouselNavigationDirections = CarouselNavigationDirections.RIGHT;

    @Input() drawer: EbDrawerComponent | undefined;

    public ngOnInit(): void {
        this.drawer?.visibilityChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isGalleryVisible) => {
            isGalleryVisible ? this._initKeydownListener() : this._destroyKeydownListener();
        });
    }

    private _initKeydownListener(): void {
        if (isPlatformBrowser(this._platformId)) {
            this._keydownEventListener = this._renderer.listen(document, 'keydown', (event) => {
                this._handleKeydown(event);
            });
        }
    }

    private _handleKeydown(event: KeyboardEvent): void {
        switch (event.key) {
            case 'ArrowRight':
                this._hostElement.scrollBy(1, CarouselNavigationDirections.RIGHT);
                break;
            case 'ArrowLeft':
                this._hostElement.scrollBy(1, CarouselNavigationDirections.LEFT);
                break;
            case ' ':
                event.preventDefault();
                this._checkBorderCases();
                this._hostElement.scrollBy(1, this._currentSpaceNavigationDirection);
                break;
        }
    }

    private _checkBorderCases(): void {
        if (this._hostElement.isLastItemInFrame) {
            this._currentSpaceNavigationDirection = CarouselNavigationDirections.LEFT;
        } else if (this._hostElement.isFirstItemInFrame) {
            this._currentSpaceNavigationDirection = CarouselNavigationDirections.RIGHT;
        }
    }

    private _destroyKeydownListener(): void {
        if (this._keydownEventListener) {
            this._keydownEventListener();
        }

        this._keydownEventListener = null;
    }

    public ngOnDestroy(): void {
        this._destroyKeydownListener();
    }
}
