import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Input, OnDestroy, PLATFORM_ID, Renderer2, inject } from '@angular/core';

@Component({
    selector: 'eb-json-ld',
    template: '',
    host: { class: 'hidden' },
})
export class EbJsonLdComponent implements OnDestroy {
    private readonly _document = inject(DOCUMENT);
    private readonly _renderer2 = inject(Renderer2);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _partialCarouselJsonLdId = 'json-ld-carousel';
    private readonly _properNumberOfCarouselJsonLd = 1;
    private readonly _jsonIndentation = 2;

    @Input() public set json(json: any) {
        this._json = json;
        this._createScript();
    }

    public get json(): any {
        return this._json;
    }

    private _json: any;
    @Input() public type!: 'search' | 'event-data' | 'title' | 'faq' | 'carousel';
    @Input() public id?: string;

    private idSuffix = '';
    private elementId = '';

    private _createScript(): void {
        if (this.elementId !== '') {
            this._deleteScript();
        }
        this.idSuffix = this.id ?? Math.random().toString().split('.')[1];
        this.elementId = this.type === 'search' ? `json-ld-${this.type}` : `json-ld-${this.type}-${this.idSuffix}`;

        const script = this._document.getElementById(this.elementId);

        if (!script) {
            const newScript = this._document.createElement('script');
            newScript.text = JSON.stringify(this.json, null, this._jsonIndentation);
            newScript.setAttribute('type', 'application/ld+json');
            newScript.setAttribute('id', this.elementId);
            this._renderer2.appendChild(this._document.head, newScript);
        } else {
            script.innerHTML = JSON.stringify(this.json, null, this._jsonIndentation);
        }

        if (this.type === 'carousel' && isPlatformBrowser(this._platformId)) {
            this._deleteUnnecessaryCarouselScripts();
        }
    }

    public ngOnDestroy(): void {
        this._deleteScript();
    }

    private _deleteScript(): void {
        const existingScript = this._document.getElementById(this.elementId);
        if (existingScript) {
            this._renderer2.removeChild(this._document.head, existingScript);
        }
    }

    private _deleteUnnecessaryCarouselScripts(): void {
        const existingCarousels = this._document.head.querySelectorAll(`[id*="${this._partialCarouselJsonLdId}"]`);
        if (existingCarousels && existingCarousels.length > this._properNumberOfCarouselJsonLd) {
            this._renderer2.removeChild(this._document.head, existingCarousels[0]);
        }
    }
}
