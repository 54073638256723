import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    PLATFORM_ID,
    Signal,
    TemplateRef,
    computed,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    isMonday,
    isSunday,
    nextSunday,
    parse,
    previousMonday,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import { pl } from 'date-fns/locale';
import { DeviceService } from '../../../../../../../libs/device/src/lib/device.service';
import { InternationalizationService } from '../../../services/internationalization.service';
import { ShopQueueService } from '../../../services/shop-queue.service';
import { ICalendarItem } from './calendar-item.interface';
import { CalendarHelper } from './calendar.helper';
import { MonthsSwitchDirection } from './months-switch-direction.enum';

export type CalendarData<T> = Map<string, T[]>;

@Component({
    selector: 'eb-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbCalendarComponent<T> implements OnInit {
    private readonly _platformId = inject(PLATFORM_ID);
    public readonly i18nService = inject(InternationalizationService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _deviceService = inject(DeviceService);
    protected readonly shopQueueService = inject(ShopQueueService);
    private _destroyRef = inject(DestroyRef);

    private _data: CalendarData<T> = new Map<string, T[]>();
    private _availableMonths: Date[] = [];
    isMobile = false;

    protected MonthsSwitchDirection = MonthsSwitchDirection;

    @Input({ required: true }) public set data(value: CalendarData<T>) {
        if (value) {
            this._data = value;
            if (!this.dateMode) {
                this._availableMonths = this._getDistinctMonthDates(this._data);

                if (this.selectedDate) {
                    this.activeMonth.set(startOfMonth(this.selectedDate));
                } else {
                    this.activeMonth.set(this._availableMonths[0] || new Date());
                }
            }
        } else {
            this._data = new Map<string, T[]>();
            this.activeMonth.set(new Date());
            this._availableMonths = [this.activeMonth()];
        }

        this._emitCalendarViewChanged();
    }

    public get data(): CalendarData<T> {
        return this._data;
    }

    @Input({ required: true }) calendarItemTmpl: TemplateRef<any> | null = null;
    @Input({ required: true }) calendarItemActionTmpl: TemplateRef<any> | null = null;
    @Input() checkHeaderFormat = true;
    @Input() headerFormat: 'EEEE' | 'EEE' = 'EEEE';
    @Input() equalDays = false;
    @Input() selectedDate: Date | undefined;
    @Input() dateMode = false;
    @Input() fromDayView = false;
    private _dateFrom: string | undefined;

    @Input() set dateFrom(dateFrom: string | undefined) {
        this._dateFrom = dateFrom;
        if (dateFrom) {
            this.dateFromDate = new Date(dateFrom);
        }
    }

    public get dateFrom(): string | undefined {
        return this._dateFrom;
    }

    @Output() readonly calendarViewChanged = new EventEmitter<ICalendarItem<T>[]>();
    @Output() readonly selectedCalendarItemChanged = new EventEmitter<ICalendarItem<T>>();
    @Output() readonly clickDate = new EventEmitter<T[]>();
    @Output() readonly dateChanged = new EventEmitter<ICalendarItem<T>[]>();

    readonly today = new Date();
    activeMonth = signal(this.today);
    activeCalendarView: Signal<ICalendarItem<T>[]> = computed(() => this._generateCalendarView(this.activeMonth()));
    headerDates: Date[] = [];
    dateFromDate: Date | undefined;

    public ngOnInit(): void {
        if (this.dateFrom) {
            this.activeMonth.set(new Date(this.dateFrom));
        }
        this.dateChanged.emit(this.activeCalendarView());
        this.headerDates = this._generateCalendarHeaderDates(this.today);
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
            if (this.checkHeaderFormat) {
                this.headerFormat = !isMobile ? 'EEEE' : 'EEE';
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    protected get hasPrev(): boolean {
        if (this.dateMode) {
            if (this.dateFromDate) {
                return this.activeMonth().getTime() > this.dateFromDate.getTime();
            } else {
                return this.activeMonth().getTime() > this.today.getTime();
            }
        }

        const idx = this._getIndex();
        return idx > 0;
    }

    protected get hasNext(): boolean {
        if (this.dateMode) {
            return true;
        }

        const idx = this._getIndex();
        return idx + 1 < this._availableMonths.length;
    }

    protected onClickDate(item: ICalendarItem<T>): void {
        if (this.hasData(item) && this.selectedCalendarItemChanged.observed) {
            this.selectedCalendarItemChanged.emit(item);
        }
        if (this.isMobile && this.clickDate.observed) {
            this.clickDate.emit(this.data.get(item.key));
        }
    }

    protected isDateToday(date: Date): boolean {
        return date.toDateString() === this.today.toDateString();
    }

    protected switchMonths(direction: MonthsSwitchDirection): void {
        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => {
                if (!this.fromDayView) {
                    this.shopQueueService.clearQueue();
                }

                if (this.dateMode) {
                    this.activeMonth.set(
                        new Date(this.activeMonth().setMonth(this.activeMonth().getMonth() + direction)),
                    );
                    this.dateChanged.emit(this.activeCalendarView());
                } else {
                    const idx = this._getIndex();
                    this.activeMonth.set(this._availableMonths[idx + direction]);
                    this._emitCalendarViewChanged();
                }
            });
        } else {
            if (this.dateMode) {
                this.activeMonth.set(new Date(this.activeMonth().setMonth(this.activeMonth().getMonth() + direction)));
                this.dateChanged.emit(this.activeCalendarView());
            } else {
                const idx = this._getIndex();
                this.activeMonth.set(this._availableMonths[idx + direction]);
                this._emitCalendarViewChanged();
            }
        }
    }

    private _getIndex(): number {
        if (!this.activeMonth()) {
            return -1;
        }

        return this._availableMonths.findIndex((m) => this.activeMonth().getTime() === m.getTime());
    }

    protected hasData(viewItem: ICalendarItem<T>): boolean {
        return this.data.has(viewItem.key);
    }

    private _generateCalendarHeaderDates(date: Date): Date[] {
        const firstDayOfWeek = startOfWeek(date, { locale: pl });
        const lastDayOfWeek = endOfWeek(date, { locale: pl });
        return eachDayOfInterval({ start: firstDayOfWeek, end: lastDayOfWeek });
    }

    private _emitCalendarViewChanged(): void {
        if (this.calendarViewChanged.observed) {
            this.calendarViewChanged.emit(this.activeCalendarView());
        }
    }

    private _generateCalendarView(date: Date): ICalendarItem<T>[] {
        const firstDayOfMonth = startOfMonth(date);
        const firstDayOfView = isMonday(firstDayOfMonth) ? firstDayOfMonth : previousMonday(firstDayOfMonth);
        const lastDayOfMonth = endOfMonth(date);
        const lastDayOfView = isSunday(lastDayOfMonth) ? lastDayOfMonth : nextSunday(lastDayOfMonth);
        return eachDayOfInterval({ start: firstDayOfView, end: lastDayOfView }).map((d) =>
            this._mapToCalendarViewItem(d),
        );
    }

    private _mapToCalendarViewItem(d: Date): ICalendarItem<T> {
        const key = CalendarHelper.createKey(d);
        return {
            key,
            label: CalendarHelper.createLabel(d),
            date: d,
            data: this._data.get(key),
        };
    }

    private _getDistinctMonthDates(clendarData: CalendarData<T>): Date[] {
        const distinctMonthKeys = new Set(Array.from(clendarData.keys()).map((key) => key.substring(0, 8) + '01'));
        const sortedKeys = Array.from(distinctMonthKeys).sort();
        return sortedKeys.map((key) => parse(key, 'yyyy-MM-dd', new Date()));
    }
}
