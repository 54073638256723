export const UTM_SHARE_LINK_URL_QUERY_PARAMS = {
    utm_source: 'ebilet.pl',
    utm_medium: 'social',
    utm_campaign: 'Share',
};

export enum UtmContentType {
    EVENT = 'wydarzenie',
    ARTIST = 'artysta',
    PLACE = 'obiekt',
}
