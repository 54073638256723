import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbSpinnerComponent } from './spinner/spinner.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EbSpinnerComponent],
    exports: [EbSpinnerComponent],
})
export class UiSpinnerModule {}
