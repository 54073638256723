import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { Ticket, TicketCategory } from '../../../rest-api/models/ticket-categories-response.model';

@Component({
    selector: 'eb-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbCounterComponent {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    private _total = 0;

    @Input({ required: true }) public set total(value: number) {
        this._total = value || 0;
    }

    public get total(): number {
        return this._total;
    }

    @Input() ticketCategory!: TicketCategory;
    @Input() ticket!: Ticket;

    @Output() readonly adding = new EventEmitter<number>();
    @Output() readonly subtracting = new EventEmitter<number>();

    protected get canRemove(): boolean {
        return this.total > 1;
    }

    protected get canAdd(): boolean {
        return true;
    }

    protected add(): void {
        this._total++;
        this.adding.next(this._total);
        this._changeDetectorRef.detectChanges();
    }

    protected remove(): void {
        this._total--;
        this.subtracting.next(this._total);
        this._changeDetectorRef.detectChanges();
    }
}
