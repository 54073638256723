import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EbDrawerComponent } from 'libs/ui-drawer/src/lib/drawer/drawer.component';
import { EbOverlayPanelComponent } from 'libs/ui-overlay/src/lib/overlay-panel/overlay-panel.component';
import { BaseVirtualPageView } from '../../../../../../../libs/gtm/src/lib/virtual-page-view';
import { ArrayHelper } from '../../../helpers/array.helper';
import { FanAlertTypes } from '../../../rest-api/models/fan-alert-request.model';
import {
    TitleEventAvailabilities,
    TitleEventAvailabilityData,
} from '../../../rest-api/models/title-event-availability.models';
import { EventDateStatus, TitleEvent } from '../../../rest-api/models/title-page.model';
import { ShopQueueService } from '../../../services/shop-queue.service';
import { TitleEventAvailabilityStoreService } from '../../../stores/title-event-availability-store.service';
import { ICalendarItem } from '../calendar/calendar-item.interface';
import { CalendarHelper } from '../calendar/calendar.helper';
import { CalendarMapper } from '../calendar/calendar.mapper';

export type CalendarData<T> = Map<string, T[]>;

@Component({
    selector: 'eb-title-event-calendar',
    templateUrl: './title-event-calendar.component.html',
    styleUrls: ['./title-event-calendar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbTitleEventCalendarComponent implements OnInit, AfterViewInit {
    private _platformId = inject(PLATFORM_ID);
    private readonly _titleEventAvailabilityStoreService = inject(TitleEventAvailabilityStoreService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    protected readonly shopQueueService = inject(ShopQueueService);
    private _destroyRef = inject(DestroyRef);

    private _calendarData: CalendarData<TitleEvent> = new Map<string, TitleEvent[]>();
    private _titleEvents: TitleEvent[] = [];

    readonly titleEventAvailabilities = TitleEventAvailabilities;
    readonly fanAlertTypes = FanAlertTypes;

    currentCalendarItem: TitleEvent[] = [];
    titleEventAvailabilityMap = new Map<string, TitleEventAvailabilityData>();
    filteredEvents: TitleEvent[] = [];
    isAfterSale = new Map<string, boolean>();

    @Input({ required: true }) public set titleEvents(titleEvents: TitleEvent[]) {
        this._titleEvents = titleEvents || [];
        this._calendarData = CalendarMapper.map(this._titleEvents, (titleEvent) => new Date(titleEvent.date));
        this._calendarData.forEach((value, key) => {
            this.isAfterSale.set(
                CalendarHelper.createKey(new Date(key)),
                value.every((event) => event.eventDateStatus === EventDateStatus.AfterSale),
            );
        });
    }

    public get titleEvents(): TitleEvent[] {
        return this._titleEvents;
    }

    @Input({ required: true }) partnerId?: string;

    @Input() calendarFilterHeaderFormat: 'EEEE' | 'EEE' = 'EEEE';
    @Input() checkHeaderFormat = true;
    @Input() isCalendarFilter = false;
    @Input() virtualPageView: BaseVirtualPageView | undefined;
    @Input() hideNumberOfEventsPerDay = false;
    @Input() tokenCheckSum: string | undefined;
    @Input() equalDays = false;
    @Input() selectedDate: Date | undefined;
    @Input() dateMode = false;
    @Input() dateFrom: string | undefined;
    @Input() isOverlay = false;
    @Input() fromDayView = false;

    @Input() set calendarData(calendarData: CalendarData<TitleEvent> | undefined) {
        if (calendarData) {
            this._calendarData = calendarData;
        }
    }

    get calendarData(): CalendarData<TitleEvent> {
        return this._calendarData;
    }

    @ViewChild(EbDrawerComponent) readonly ebDrawerComponent!: EbDrawerComponent;
    @ViewChild(EbOverlayPanelComponent) readonly ebOverlayPanelComponent!: EbOverlayPanelComponent<any>;

    @Output() readonly selectedCalendarItemChanged = new EventEmitter<ICalendarItem<TitleEvent>>();
    @Output() readonly calendarViewChanged = new EventEmitter<ICalendarItem<TitleEvent>[]>();

    showSkeleton = true;

    public ngOnInit(): void {
        this._titleEventAvailabilityStoreService
            .get()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((titleEventAvailabilityMap) => {
                this.titleEventAvailabilityMap = titleEventAvailabilityMap;
                this._changeDetectorRef.detectChanges();
            });
    }

    public ngAfterViewInit(): void {
        this.showSkeleton = false;
        if (isPlatformBrowser(this._platformId)) {
            setTimeout(() => this._changeDetectorRef.markForCheck());
        }
    }

    protected showInDrawer(items: TitleEvent[]): void {
        if (!this.isCalendarFilter) {
            this.currentCalendarItem = items;
            this.checkFreeSeats(items);

            setTimeout(() => {
                this.ebDrawerComponent.show();
                this._changeDetectorRef.markForCheck();
            });
        }
    }

    protected onSelectedCalendarItemChanged(item: ICalendarItem<TitleEvent>): void {
        if (this.selectedCalendarItemChanged.observed) {
            this.selectedCalendarItemChanged.emit(item);
        }
    }

    protected calendarViewChange(items: ICalendarItem<TitleEvent>[]): void {
        this.calendarViewChanged.emit(items);
    }

    protected checkAvailability(items: ICalendarItem<TitleEvent>[]): void {
        const events = ArrayHelper.flatMap(
            items.filter((item: ICalendarItem<TitleEvent>) => item.data?.length === 1),
            (item) => item.data as TitleEvent[],
        );

        this.checkFreeSeats(events);
    }

    protected checkFreeSeats(events: TitleEvent[]): void {
        this._titleEventAvailabilityStoreService
            .load({
                partnerId: this.partnerId,
                events,
                cacheLong: events.some((e) => e.webShopMaxDelay),
            })
            .pipe(takeUntilDestroyed(this._destroyRef))
            ?.subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    protected onCloseOverlay(): void {
        this.ebOverlayPanelComponent.close();
    }

    protected onOpenOverlay(data: TitleEvent[]): void {
        this.checkFreeSeats(data);
        document.getElementById('overlay-dots-' + data[0].date)?.click();
    }
}
