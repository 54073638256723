import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '../rest-api/models/title.model';
import { TitleSearch } from '../rest-api/models/search-response.model';
import { TypeHelper } from '../helpers/type.helper';
import { InternationalizationService } from './internationalization.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private readonly _i18nService = inject(InternationalizationService);
    private readonly _router = inject(Router);
    private readonly _platformId = inject(PLATFORM_ID);

    public navigateToTitle(titleData: Title | TitleSearch): void {
        if (titleData.linkTo) {
            if (
                isPlatformBrowser(this._platformId) &&
                (titleData.linkTo.indexOf(window.location.origin) < 0 || titleData.linkTo.includes('ebilet.pl/now/'))
            ) {
                window.location.href = titleData.linkTo;
            } else {
                const url = new URL(titleData.linkTo);
                void this._router.navigateByUrl(url.pathname);
            }
        } else {
            void this._router.navigate([
                this._i18nService.isEnglishActive ? 'en' : '',
                TypeHelper.isTitle(titleData) ? titleData.categoryName : titleData.category,
                TypeHelper.isTitle(titleData) ? titleData.subcategoryName : titleData.subategory,
                titleData.slug,
            ]);
        }
    }
}
