/* eslint-disable */
import { add, format } from 'date-fns';
import { environment } from '../../../../environments/environment';
import { EventDateStatus, TitleEvent } from '../../../rest-api/models/title-page.model';
import { Title } from '../../../rest-api/models/title.model';
import { JsonEvent, JsonItemElement, JsonPerson, JsonListItem, JsonTypes } from '../../../rest-api/models/title-event-microdata.model';
import { ILandingLocationPageEvents, ILandingLocationPageTitles } from '../../../rest-api/models/landing-page.model';
import { TypeHelper } from '../../../helpers/type.helper';

const basicJsonEvent = {
  '@context': 'https://schema.org' as const,
  '@type': JsonTypes.EVENT,
  eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode' as const,
  eventStatus: 'https://schema.org/EventScheduled' as const,
};

const basicJsonItemList = {
  '@context': 'https://schema.org' as const,
  '@type': JsonTypes.ITEM_LIST,
};

export class TitleEventMicrodataMapper {
    static mapToJsonLd(
        data: TitleEvent | Title[] | ILandingLocationPageTitles | ILandingLocationPageEvents | null | undefined,
        microDescription?: string,
        artists?: string[],
        titleUrl?: string,
    ): JsonEvent  | JsonListItem | null {
        if (!data) {
            return null;
        }
        if (Array.isArray(data) || (TypeHelper.isILandingLocationPageTitles(data))) {
          return this.mapToItemList(data);
        } else {
          return this.mapToJsonEvent(data, microDescription, artists, titleUrl);
        }
    }

    static concateEventsFromDiffrentArrays(data: TitleEvent | Title[] | ILandingLocationPageTitles | ILandingLocationPageEvents): Title[] | TitleEvent[] | TitleEvent {
      if(TypeHelper.isILandingLocationPageEvents(data) ) {
        return [
          ...(data.events ?? []),
          ...(data.eventsInRange ?? []),
          ...(data.eventsFeatured ?? [])].filter( (event) => event !== null);
      } else if (TypeHelper.isILandingLocationPageTitles(data)) {
        return [
          ...(data.titles ?? []),
          ...(data.titlesInRange ?? []),
          ...(data.titlesFeatured ?? [])].filter( (event) => event !== null);
      } else {
        return data;
      }
    }

    static mapToJsonEvent(
      titleEvent: TitleEvent | ILandingLocationPageEvents,
      microDescription?: string,
      artists?: string[],
      titleUrl?: string
    ) {

        if(TypeHelper.isILandingLocationPageEvents(titleEvent)) {
          titleEvent = titleEvent.events[0];
        }

        const jsonEvent: JsonEvent = {
          ...basicJsonEvent,
          name: titleEvent.titleTitle,
          startDate: titleEvent.date,
          endDate: this._getEndDate(titleEvent.date),
          description: titleEvent.description,
          url: titleUrl,
          location: {
              '@type': JsonTypes.PLACE,
              name: titleEvent.placeName,
              address: {
                  '@type': JsonTypes.POSTAL_ADDRESS,
                  streetAddress: titleEvent.street,
                  addressLocality: titleEvent.city,
                  postalCode: titleEvent.postalCode,
                  addressRegion: titleEvent.province,
                  addressCountry: 'PL',
              },
          },
          image: [titleEvent.imageLandscape, titleEvent.imagePortrait],
          // offers: this._getOffersMicroData(titleEvent, titleUrl), do odkomentowania jak ceny wrócą na portal
      };

      if (microDescription) {
        jsonEvent.description = microDescription;
      }

      const performer = this._getPerformersMicroData(artists);

      if (performer) {
        jsonEvent.performer = performer;
      }

      if (titleEvent?.organizerName && titleEvent.organizerSlug) {
        jsonEvent.organizer = {
            '@type': JsonTypes.ORGANIZATION,
            name: titleEvent.organizerName,
            url: `${environment.siteUrl}/organizatorzy/${titleEvent.organizerSlug}`,
        };
      }

      return jsonEvent;
    }

    static mapToItemList(
      titles: Title[] | ILandingLocationPageTitles
  ) {
      let preparedTitles;
      if(TypeHelper.isILandingLocationPageTitles(titles)) {
        preparedTitles = this.concateEventsFromDiffrentArrays(titles) as Title[];
      } else {
        preparedTitles = titles;
      }

      const jsonListItem: JsonListItem = {
        ...basicJsonItemList,
        itemListElement: []
      };

      const uniqueTitles = this._getUniqueTitles(preparedTitles ?? []);
      const titlesWithStartDate = this._getTitlesWithStartDate(uniqueTitles);
      for(const [index, title] of titlesWithStartDate.entries()) {
          const itemListElement: JsonItemElement = {
            '@type': JsonTypes.LIST_ITEM,
                  position: index + 1,
                  item: {
                    '@type': JsonTypes.EVENT,
                    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
                    eventStatus: 'https://schema.org/EventScheduled',
                    name: title.title,
                    startDate: title.dateFrom,
                    endDate: title.dateTo,
                    description: title.metaDescription,
                    url: this.getTitleUrl(title.categoryName, title.subcategoryName, title.slug),
                    location: {
                      '@type': JsonTypes.PLACE,
                      name: title.place,
                      address: {
                        '@type': JsonTypes.POSTAL_ADDRESS,
                        streetAddress: title.nextEventPlace?.street,
                        addressLocality: title.nextEventPlace?.city,
                        postalCode: title.nextEventPlace?.postalCode,
                        addressRegion: title.nextEventPlace?.province,
                        addressCountry: 'PL',
                      },
                    },
                    image: [title.imageLandscape, title.imagePortrait],
                    performer: undefined,
                    organizer: undefined
                  }
        };

        const performer = this._getPerformersMicroData(title.artists);
        if (performer) {
          itemListElement.item.performer = performer;
        }

        if (title?.organizer?.name && title.organizer?.slug) {
          itemListElement.item.organizer = {
                '@type': JsonTypes.ORGANIZATION,
                name: title.organizer?.name,
                url: `${environment.siteUrl}/organizatorzy/${title.organizer?.slug}`,
            };
          }
          jsonListItem.itemListElement.push(itemListElement);

        }

      return jsonListItem;
  }

    private static _getOffersMicroData(titleEvent: TitleEvent, titleUrl?: string) {
        const offers = {
            '@type': JsonTypes.OFFER,
            validFrom: titleEvent.sellDateFrom,
        } as any;

        if (titleEvent.soldOut || titleEvent.currentlyUnavailable) {
            offers.availability = 'https://schema.org/SoldOut';
        } else if (titleEvent.eventDateStatus === EventDateStatus.SaleAvailable) {
            offers.availability = 'https://schema.org/InStock';
        }

        if (titleUrl) {
            offers.url = titleEvent.city ? `${titleUrl}?city=${titleEvent.city}` : titleUrl;
        }

        return offers;
    }

    private static _getPerformersMicroData(artists: string[] | undefined) {
        if (!artists || !artists.length) {
            return null;
        }

        if (artists.length === 1) {
            return {
                '@type': JsonTypes.PERSON,
                name: artists[0],
            } as JsonPerson;
        }

        return artists.map((artist) => {
            return {
                '@type': JsonTypes.PERSON,
                name: artist,
            } as JsonPerson;
        });
    }

    static mapFromTitle(title: Title | null | undefined) {
        if (!title || !title.dateFrom) {
            return null;
        }

        const titleJson = {
            ...basicJsonEvent,
            name: title.title,
            startDate: title.dateFrom,
            endDate: title.dateTo,
            location: {
                '@type': JsonTypes.PLACE,
                name: title.nextEventPlace?.customName,
                address: {
                    '@type': JsonTypes.POSTAL_ADDRESS,
                    streetAddress: title.nextEventPlace?.street,
                    addressLocality: title.nextEventPlace?.city,
                    postalCode: title.nextEventPlace?.postalCode,
                    addressRegion: title.nextEventPlace?.province,
                    addressCountry: 'PL',
                },
            },
            image: [title.imageLandscape, title.imagePortrait],
            description: title.metaDescription,
            offers: this._getOffersMicroDataFromTitle(title),
        } as any;

        const performer = this._getPerformersMicroDataFromTitle(title);

        if (performer) {
            titleJson.performer = performer;
        }

        if (title.organizer) {
            titleJson.organizer = {
                '@type': JsonTypes.ORGANIZATION,
                name: title.organizer.name,
                url: `${environment.siteUrl}/organizatorzy/${title.organizer.slug}`,
            };
        }

        return titleJson;
    }

    private static _getOffersMicroDataFromTitle(title: Title) {
        const offers = {
            '@type': JsonTypes.OFFER,
            url: this.getTitleUrl(title.categoryName, title.subcategoryName, title.slug),
            validFrom: title.sellDateFrom,
        } as any;

        if (title.soldOut) {
            offers.availability = 'https://schema.org/SoldOut';
        } else if (title.sellDateFrom && new Date() > new Date(title.sellDateFrom)) {
            offers.availability = 'https://schema.org/InStock';
        }

        return offers;
    }

    private static _getPerformersMicroDataFromTitle(title: Title) {
        if (!title?.artists || !title?.artists.length) {
            return null;
        }

        if (title.artists.length === 1) {
            return {
                '@type': JsonTypes.PERSON,
                name: title.artists[0],
            };
        }

        return title.artists.map((artist) => {
            return {
                '@type': JsonTypes.PERSON,
                name: artist,
            };
        });
    }

    private static _getEndDate(date: string): string {
        if (!date) {
            return '';
        }

        const endDate = add(new Date(date), { hours: 5 });
        return format(endDate, "yyyy-MM-dd'T'HH:mm:ss");
    }

    private static _getUniqueTitles(titles: Title[]): Title[] {
      return titles.reduce((acc, curr) => {
        const isDuplicated = acc.some( ({ id }) => id === curr.id)
            return [...acc, ...(isDuplicated  ? [] :  [curr])]
        }, [] as Title[]);
    }

    private static _getTitlesWithStartDate(titles: Title[]): Title[] {
      return titles.filter(title => title.dateFrom);
    }

    static getTitleUrl(category: string, subcategory: string, slug: string): string {
        return `${environment.siteUrl}/${category}/${subcategory}/${slug}`;
    }
}
