import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FaqJson, FaqJsonHelper } from '../../../helpers/faq-json.helper';
import { IFAQItem } from '../../../rest-api/models/faq.model';

@Component({
    selector: 'eb-faq-section',
    templateUrl: './faq-section.component.html',
    styleUrls: ['./faq-section.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbFaqSectionComponent {
    @Input({ required: true }) faqItems: IFAQItem[] = [];
    @Input() generateJson = true;
    @Input() isLoading = false;
    @Input() public set id(id: string | undefined) {
        this._generateFaqJsonScript(id ?? '');
        this._id = id ?? '';
    }

    public get id(): string {
        return this._id;
    }

    private _id = '';
    json: FaqJson | undefined;

    private _generateFaqJsonScript(id: string): void {
        if (!this.faqItems.length) {
            return;
        }

        this.json = FaqJsonHelper.getJson(this.faqItems);
    }
}
